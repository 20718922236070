import { Box, Button, Flex, FormControl, FormLabel, Heading, Icon, Input, InputGroup, InputLeftElement, Link, ListItem, NumberInput, NumberInputField, OrderedList, Stack, Text, VStack } from "@chakra-ui/react";
import { Field, Form, Formik } from 'formik';
import { RefObject, useRef } from 'react';
import { FaPhoneAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { formatTimestamp } from '../../helpers/helpers';

const StaticDogAgreement = ({ reservationNumber, agreementData }) => {

	const printRefDog: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);

	const handleSubmitPlaceholder = () => { console.log("Submit") }

	const handlePrintDog = () => {
		const printFrameDog = document.getElementById("printFrameDog") as HTMLIFrameElement;
		if (printRefDog.current && printFrameDog && printFrameDog.contentWindow) {
			const printStyles = `
				<style>
					@media print {
						.house-rules-container {
							max-height: 100%;
							overflow: visible;
						}
						.chakra-input {
							opacity: 1 !important;
							border: solid 1px #CDCDCD !important;
						}
						.chakra-input:disabled {
							border: solid 1px #CDCDCD !important;
						}
						.print-agreement {
							display: none;
						}
						body, h1, h2, h3, div { 
							color: #000 !important; /* Ensures text is black */
						}
						input[type=radio] {
							accent-color: #333 !important;
							color: black !important;
						}
					}
				</style>
			`;

			const printContent = printRefDog.current.innerHTML;

			// Copy existing styles from the document's head, excluding script tags
			const docHead = document.head.innerHTML.replace(/<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi, "");
			const headContent = docHead + printStyles; // Combine the existing head content with the new print styles

			// Open the document of the iframe
			printFrameDog.contentWindow.document.open();
			printFrameDog.contentWindow.document.write(`
				<html>
					<head>${headContent}</head>
					<body>${printContent}</body>
				</html>
			`);
			printFrameDog.contentWindow.document.close();

			// Set the onload function to ensure the iframe is fully loaded before printing
			printFrameDog.onload = () => {
				printFrameDog.contentWindow.focus();
				printFrameDog.contentWindow.print();
				printFrameDog.onload = null; // Cleanup after printing
			};
		} else {
			console.error('Unable to access the print dog agreement content or frame.');
		}
	};

	return (
		<Flex
			position={"relative"}
			width={"full"}
			data-id={"listing"}
			p={{ base: 0, md: 5 }}
			justifyContent={'center'}
			flexDirection={'column'}
			alignItems={'center'}
			ref={printRefDog}
		>
			<Flex maxWidth='1440px' width={'100%'} p={2} textAlign={'left'} alignItems={'start'} flexDirection={'column'}>

				<Button onClick={handlePrintDog} className='print-agreement' colorScheme={'dmOrange'} size="md" mb="4" alignSelf={'end'} display={'none'}>
					Print Agreement
				</Button>

				{/* Hidden iframe for printing */}
				<iframe id="printFrameDog" style={{ display: 'none' }} title="Print Frame Dog"></iframe>

				<Text pb={3} w={'100%'} textAlign={'center'} fontSize={'1.4em'} fontWeight={'700'}>
					{agreementData.firstName} {agreementData.lastName}: Dog Guest Terms
				</Text>

				<Formik
					initialValues={{
						bringDog: agreementData.metadata.dogGuests || '',
						numberOfDogs: agreementData.numDogs || 0,
						firstName: agreementData.firstName || '',
						lastName: agreementData.lastName || '',
						email: agreementData.email || '',
						phone: agreementData.phone || '',
						street: agreementData.street || '',
						city: agreementData.city || '',
						state: agreementData.state || '',
						zip: agreementData.zip || '',
						serviceAnimalTrained: agreementData.serviceAnimalTrained || '',
						serviceAnimalRequired: agreementData.serviceAnimalRequired || '',
					}}
					onSubmit={handleSubmitPlaceholder}
				>
					{({ values, setFieldValue, errors, touched, isSubmitting, setFieldTouched }) => {

						const totalDogFee = agreementData.metadata && agreementData.metadata.totalDogFee;
						const dogBoolean = agreementData.metadata && agreementData.metadata.dogGuests === "Yes";

						return (

							<Form>
								{/* Dog Form Section */}
								<Flex fontSize={'1.4em'} py={3} fontWeight={'bold'}>
									Dog Guests
								</Flex>
								<Box width={'100%'}>
									<FormControl>
										<FormLabel fontSize={'0.9em'} mb={1}>Will you or one of your guests be bringing a dog?</FormLabel>
										<Input fontSize={'0.9em'} isDisabled value={agreementData.metadata.dogGuests || ''} />
									</FormControl>
									<Text pt={2} pb={4}><i>All other animals are prohibited and will result in a fine of at least $1,000.</i></Text>

									{agreementData.metadata.dogGuests === 'No' && (
										<>
											<Text fontWeight={'bold'}>All dogs must be paid for. Failure to report and pay for all dogs at the property prior to arrival will result in a $1,000 fine per animal, no exceptions. Please be an honest traveler and contact us if your pet sitter or kennel arrangements change last minute to pay for your dog-guest.</Text>
											<Flex direction="row" py="4" alignItems={'center'} gap={3}>
												<Button h={"50px"} mt={6} mb={6} colorScheme="gray" type="submit" isDisabled>
													Agree and Continue
												</Button>
												<Text color={'dmOrange.500'}>Agreed and submitted at {formatTimestamp(agreementData.signedAt)}</Text>
											</Flex>
										</>
									)}

									{agreementData.metadata.dogGuests === 'Service Animal' && (
										<Flex flexDirection={'column'} gap={3}>

											<Text>For certified Service Animals only, the fee is waived. Please complete the questions below.</Text>
											<Text fontWeight={'bold'} mb={3}>Emotional Support Animals do not apply, and you must select YES above.</Text>

											<Field name="serviceAnimalRequired">
												{({ field, form }) => (
													<FormControl>
														<FormLabel fontSize={'0.9em'} mb={0}>Is the animal a service animal required because of a disability?</FormLabel>
														<Input {...field} fontSize={'0.9em'} value={agreementData.serviceAnimalRequired || ''} isDisabled />
													</FormControl>
												)}
											</Field>
											<Field name="serviceAnimalTrained">
												{({ field, form }) => (
													<FormControl>
														<FormLabel fontSize={'0.9em'} mb={0}>What task or service is the animal trained to perform?</FormLabel>
														<Input {...field} fontSize={'0.9em'} value={agreementData.serviceAnimalTrained || ''} isDisabled />
													</FormControl>
												)}
											</Field>
											<Text>For more information about service animals in the Commonwealth of Massachusetts, please refer to <Link href="https://www.mass.gov/info-details/massachusetts-law-about-service-animals" target="_blank" rel="noreferrer" textDecoration="underline">mass.gov</Link>.</Text>
											<Button h={"50px"} mt={6} mb={3} type="submit" colorScheme="dmOrange" isDisabled>
												Agree and Continue
											</Button>
											<Text color={'dmOrange.500'}>Agreed and submitted at {formatTimestamp(agreementData.signedAt)}</Text>
										</Flex>
									)}

									{agreementData.metadata.dogGuests === 'Yes' && (
										<>

											<FormControl isInvalid={!!(errors.numberOfDogs && touched.numberOfDogs)}>

												<Flex w={'100%'} flexDirection={'row'} alignItems={'center'}>
													<FormLabel fontSize={'1em'} mb={1}>How many dogs?</FormLabel>

													<Field name="numberOfDogs">
														{({ field }) => (
															<NumberInput
																{...field}
																value={agreementData.metadata.numDogs}
																maxW={"100px"}
															>
																<NumberInputField fontSize={'0.9em'} placeholder="#" disabled />
															</NumberInput>
														)}
													</Field>
												</Flex>

											</FormControl>

											<Text fontSize={'1em'} py={4} fontWeight={'bold'}>Total Dog Fee Paid: ${agreementData.metadata.totalDogFee}</Text>

										</>
									)}

								</Box>

								{/* Stripe Payment Section */}
								{dogBoolean && totalDogFee > 0 &&
									<Box>
										<Stack spacing="3">
											<Box>
												<FormLabel fontSize={'0.9em'} mb={1}>Credit Card</FormLabel>
												<Flex w={'100%'} justifyContent={'space-between'} gap={2} color={'dmOrange.500'}>
													Paid via Credit Card at {formatTimestamp(agreementData.signedAt)}
												</Flex>
											</Box>

											{/* Name fields */}
											<Stack direction={{ base: 'column', md: 'row' }}>
												<Field name="firstName">
													{({ field, form }) => (
														<FormControl isInvalid={form.errors.firstName && form.touched.firstName}>
															<FormLabel fontSize={'0.9em'} mb={0}>First Name</FormLabel>
															<Input {...field} fontSize={'0.9em'} placeholder="First Name" disabled />
														</FormControl>
													)}
												</Field>
												<Field name="lastName">
													{({ field, form }) => (
														<FormControl isInvalid={form.errors.lastName && form.touched.lastName}>
															<FormLabel fontSize={'0.9em'} mb={0}>Last Name</FormLabel>
															<Input {...field} fontSize={'0.9em'} placeholder="Last Name" disabled />
														</FormControl>
													)}
												</Field>
											</Stack>

											{/* Email and Phone fields */}
											<Stack direction={{ base: 'column', md: 'row' }}>
												<Field name="email">
													{({ field, form }) => (
														<FormControl isInvalid={form.errors.email && form.touched.email}>
															<FormLabel fontSize={'0.9em'} mb={0}>Email</FormLabel>
															<InputGroup>
																<InputLeftElement pointerEvents='none'>
																	<Icon as={MdEmail} color='gray.300' />
																</InputLeftElement>
																<Input {...field} fontSize={'0.9em'} placeholder="Email" disabled />
															</InputGroup>
														</FormControl>
													)}
												</Field>
												<Field name="phone">
													{({ field, form }) => (
														<FormControl isInvalid={form.errors.phone && form.touched.phone}>
															<FormLabel fontSize={'0.9em'} mb={0}>Cell Phone</FormLabel>
															<InputGroup>
																<InputLeftElement pointerEvents='none'>
																	<Icon as={FaPhoneAlt} color='gray.300' />
																</InputLeftElement>
																<Input {...field} fontSize={'0.9em'} placeholder="Phone" readOnly disabled />
															</InputGroup>
														</FormControl>
													)}
												</Field>
											</Stack>

											{/* Address fields */}
											<Stack spacing="3" direction={{ base: 'column', md: 'row' }}>
												<Field name="street">
													{({ field, form }) => (
														<FormControl isInvalid={form.errors.street && form.touched.street}>
															<FormLabel fontSize={'0.9em'} mb={0}>Street</FormLabel>
															<Input {...field} fontSize={'0.9em'} placeholder="Street" disabled />
														</FormControl>
													)}
												</Field>
												<Field name="city">
													{({ field, form }) => (
														<FormControl isInvalid={form.errors.city && form.touched.city}>
															<FormLabel fontSize={'0.9em'} mb={0}>City</FormLabel>
															<Input {...field} fontSize={'0.9em'} placeholder="City" disabled />
														</FormControl>
													)}
												</Field>
												<Field name="state">
													{({ field, form }) => (
														<FormControl isInvalid={form.errors.state && form.touched.state}>
															<FormLabel fontSize={'0.9em'} mb={0}>State</FormLabel>
															<Input {...field} fontSize={'0.9em'} placeholder="State / Province" disabled />
														</FormControl>
													)}
												</Field>
												<Field name="zip">
													{({ field, form }) => (
														<FormControl isInvalid={form.errors.zip && form.touched.zip}>
															<FormLabel fontSize={'0.9em'} mb={0}>ZIP</FormLabel>
															<Input {...field} fontSize={'0.9em'} placeholder="ZIP / Postal Code" disabled />
														</FormControl>
													)}
												</Field>
											</Stack>
										</Stack>

										{/* Disclaimer text */}
										<Text mt={5} fontSize={'0.9em'}>
											<i>This Dog Agreement once paid and eSigned is an amendment to your Guest agreement. By clicking the Agree and Pay button below, you authorize the above charges and understand that you and the dog owner are responsible for the registered dog at all times and accept full legal liability for any damage or harm the animal may do to the property or persons on and near the property. If you do not adhere to the dog rules or you violate this provision with respect to other animals, your Security Authorization Hold will be retained and your dog may be removed from the property.</i>
										</Text>

										<Flex direction="row" py="4" alignItems={'center'} gap={3}>
											<Button colorScheme="gray" type="submit" isDisabled>
												Agree and Pay ${totalDogFee}
											</Button>
											<Text color={'dmOrange.500'}>Signed and submitted at {formatTimestamp(agreementData.signedAt)}</Text>
										</Flex>

										<Box mt={5} p={5} maxHeight={'200px'} overflowY={'scroll'} fontSize={'0.8em'} border={'solid 1px rgb(226, 232, 240)'} borderRadius={'0.375rem'}>
											<VStack spacing={4} align="stretch">
												<Heading as="h2" size="md">Dog Rules</Heading>
												<OrderedList>
													<ListItem>Dogs may not bark so as to cause noise complaints.</ListItem>
													<ListItem>Dogs are not allowed on the beds or furniture.</ListItem>
													<ListItem>Dogs may not cause damage and their presence cannot result in extra cleaning being required.</ListItem>
													<ListItem>You are responsible to remove any and all pet waste from the exterior of the property.</ListItem>
													<ListItem>Dog owners are required to crate their dogs when left home alone, for the safety of the dog and service staff.</ListItem>
												</OrderedList>
											</VStack>
										</Box>
									</Box>
								}
							</Form>
						)
					}}
				</Formik>
			</Flex>
		</Flex>
	)
}

export { StaticDogAgreement };

