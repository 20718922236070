import { Box, Button, Flex, Input, Text } from "@chakra-ui/react";
import {
    MagicLinkErrorCode,
    isMagicLinkError,
    useClerk,
    useSignIn
} from "@clerk/clerk-react";
import React from "react";
import { useNavigate } from "react-router-dom";
import config from "../../config";

const REDIRECT_URL = config.homePage;

// Render the sign in form.
// Collect user's email address and send a magic link with which
// they can sign in.
function SignInMagicLink({ email, reservationId }) {
    const [placeholderText, setplaceholderText] = React.useState('Email');
    const [emailAddress, setEmailAddress] = React.useState(email ? email : "");
    const [linkSent, setLinkSent] = React.useState(false)
    const [expired, setExpired] = React.useState(false);
    const [verified, setVerified] = React.useState(false);
    const navigate = useNavigate();
    const { signIn, isLoaded, setActive } = useSignIn();
    const [errorMessage, setErrorMessage] = React.useState("");

    if (!isLoaded) return null;

    const { startMagicLinkFlow } = signIn.createMagicLinkFlow();

    async function submit(e) {
        e.preventDefault();
        setErrorMessage(""); // Reset error message on new submission

        //console.log("emailAddress", emailAddress)

        try {
            if (!emailAddress) {
                setplaceholderText("Please input email address")
                return
            }

            setLinkSent(true)
            setExpired(false);
            setVerified(false);

            // Start the sign in flow, by collecting 
            // the user's email address.
            const si = await signIn.create({ identifier: emailAddress });
            //console.log('si',si);
            const { emailAddressId } = si.supportedFirstFactors.find(
                ff => ff.strategy === "email_link" && ff.safeIdentifier === emailAddress
            ) as any;
            //console.log('emailAddressId',emailAddressId)

            // Start the magic link flow.
            // Pass your app URL that users will be navigated
            // res will hold the updated sign in object.
            const res = await startMagicLinkFlow({
                emailAddressId: emailAddressId,
                redirectUrl: REDIRECT_URL + reservationId,
            });

            // Check the verification result.
            const verification = res.firstFactorVerification;
            if (verification.verifiedFromTheSameClient()) {
                setVerified(true);
                // If you're handling the verification result from 
                // another route/component, you should return here.
                // See the <MagicLinkVerification/> component as an 
                // example below.
                // If you want to complete the flow on this tab, 
                // don't return. Simply check the sign in status.
                return;
            } else if (verification.status === "expired") {
                setExpired(true);
            }

            if (res.status === "complete") {
                // Sign in is complete, we have a session.
                // Navigate to the after sign in URL.
                setActive({
                    session: res.createdSessionId,
                    beforeEmit: () => navigate("/"),
                });
                return;
            }
        } catch (err) {
            console.error("SignInMagicLink error:", err);
            setErrorMessage("Email address is invalid. Please try again or sign in with different method.");
        }
    }

    if (linkSent && (errorMessage === '' || !(errorMessage))) {
        return (
            <div>Sending Secure Link sent to your email. Please check your inbox to login.</div>
        );
    }

    if (expired) {
        return (
            <div>Magic link has expired.</div>
        );
    }

    if (verified) {
        return (
            <div>Signed in on other tab.</div>
        );
    }

    return (
        <Box as="form" onSubmit={submit} w={'100%'} maxW={'350px'}>
            <Flex w={'100%'} alignItems={'center'} justifyContent={'center'} flexDirection={'column'} gap={3}>
                <Text fontSize={'0.9em'}>We'll send you a one-time link to instantly sign in without your password.</Text>
                {errorMessage && <Text textAlign="center" fontWeight={'500'} color="red.500" mb={2}>{errorMessage}</Text>}
                <Input
                    type="email"
                    value={emailAddress}
                    onChange={e => setEmailAddress(e.target.value)}
                    placeholder={placeholderText}
                />
                <Button autoFocus type="submit" h={'40px'} w={'100%'} colorScheme="dmOrange">
                    Send me a Secure Link
                </Button>
            </Flex>
        </Box>
    );
}



// Handle magic link verification results. This is
// the final step in the magic link flow.
function MagicLinkVerification(reservationId) {
    const [
        verificationStatus,
        setVerificationStatus,
    ] = React.useState("loading");

    const { handleMagicLinkVerification } = useClerk();

    React.useEffect(() => {
        async function verify() {
            try {
                await handleMagicLinkVerification({
                    redirectUrl: REDIRECT_URL + reservationId,
                    redirectUrlComplete: REDIRECT_URL + reservationId,
                });
                // If we're not redirected at this point, it means
                // that the flow has completed on another device. 
                setVerificationStatus("verified");
            } catch (err) {
                // Verification has failed.
                let status = "failed";
                if (isMagicLinkError(err) && err.code === MagicLinkErrorCode.Expired) {
                    status = "expired";
                }
                setVerificationStatus(status);
            }
        }
        verify();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (verificationStatus === "loading") {
        return <div>Loading...</div>
    }

    if (verificationStatus === "failed") {
        return (
            <div>Magic link verification failed.</div>
        );
    }

    if (verificationStatus === "expired") {
        return (
            <div>Magic link expired.</div>
        );
    }

    return (
        <div>
            Successfully signed in. Return to the original tab to continue.
        </div>
    );
}





export { MagicLinkVerification, SignInMagicLink };

