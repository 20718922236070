import {
  Box, Button, Flex, Link,
  Modal,
  ModalBody, ModalCloseButton, ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure
} from "@chakra-ui/react";
import { IconPaw, IconPawOff } from '@tabler/icons-react';
import { differenceInHours, differenceInMonths } from "date-fns";
import { zonedTimeToUtc } from 'date-fns-tz';
import * as React from "react";
import { useNavigate } from "react-router";
import { formatTime } from "../../helpers/helpers";
import { CheckInInfo } from "./CheckInInfo";
import { DogAgreementPortal } from "./DogAgreementPortal";
import { Gallery } from "./Gallery";
import { GuestAgreements } from "./GuestAgreements";
import { HowThisHomeWorks } from "./HowThisHomeWorks";
import { RebookCalendar } from "./RebookCalendar";
import { ReviewModal } from "./ReviewModal";
import { ShareWidget } from "./ShareWidget";

type Props = {
  reservationData: any;
  reservationIndex: number;
};

const ReservationWidget: React.FC<Props> = ({
  reservationData, reservationIndex
}) => {

  const navigate = useNavigate();

  // Set Time Zone To Eastern Time
  const ET = 'America/New_York';
  let today = new Date();

  // Calculation of Time To Check In for Display of Door Code / Lockbox Directions
  const checkinAt = zonedTimeToUtc(new Date(`${reservationData.checkinDate} ${reservationData.checkinTime}`), ET);

  // Calculation of Time for Days to Checkin Text
  const [reservationStatus, setReservationStatus] = React.useState('');
  React.useEffect(() => {
    const daysToCheckIn = Math.round(differenceInHours(checkinAt, new Date()) / 24);

    if (today >= new Date(reservationData.checkinDate) && today <= new Date(reservationData.checkoutDate)) {
      setReservationStatus('Current Stay');
    } else {
      if (daysToCheckIn >= 30) {
        const monthsUntilCheckin = differenceInMonths(new Date(reservationData.checkinDate), today);
        setReservationStatus(`In ${monthsUntilCheckin} Month${monthsUntilCheckin > 1 ? 's' : ''}`);
      } else {
        setReservationStatus(`In ${daysToCheckIn} Day${daysToCheckIn > 1 ? 's' : ''}`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reservationData]);

  // Calculation of Next Year's Checkin Dates for Rebook Form
  // Approximately 1 Year in the Future, but Adjusted to be Saturday - Saturday
  let daysUntilNextSaturday = (6 - today.getDay() + 7) % 7;
  let nextYearCheckIn = new Date();
  nextYearCheckIn.setDate(today.getDate() + daysUntilNextSaturday + 366);
  let nextYearCheckOut = new Date();
  nextYearCheckOut.setDate(today.getDate() + daysUntilNextSaturday + 373);

  // Formatting of Checkin and Checkout Times for Display on Reservation Widget
  let checkInTimeFormatted = formatTime(reservationData.checkinTime);
  let checkOutTimeFormatted = formatTime(reservationData.checkoutTime);

  const formattedCheckinDateNoTimeZone = reformatDateString(reservationData.checkinDate);
  const formattedCheckoutDateNoTimeZone = reformatDateString(reservationData.checkoutDate);


  function reformatDateString(date) {
    const [year, month, day] = date.split('-');
    return `${Number(month)}/${Number(day)}/${year}`;
  }

  const { isOpen: isRebookOpen, onOpen: onRebookOpen, onClose: onRebookClose } = useDisclosure(); // Rebook Modal
  const { isOpen: isShareOpen, onOpen: onShareOpen, onClose: onShareClose } = useDisclosure(); // Share Trip Modal
  const { isOpen: isHomeGuideOpen, onOpen: onHomeGuideOpen, onClose: onHomeGuideClose } = useDisclosure(); // Home Guide Modal
  const { isOpen: isReviewOpen, onClose: onReviewClose } = useDisclosure(); // Review Modal
  const { isOpen: isAgreementsOpen, onOpen: onAgreementsOpen, onClose: onAgreementsClose } = useDisclosure(); // Agreements Modal
  const { isOpen: isAccessInfoOpen, onClose: onAccessInfoClose } = useDisclosure(); // Access Info Modal
  const { isOpen: isDogsOpen, onOpen: onDogsOpen, onClose: onDogsClose } = useDisclosure(); // Add Dogs Modal

  const handleManualOpen = () => {
    onRebookOpen();
  };

  const handleTripDetails = () => {
    navigate(`/trips/${reservationData.reservationNumber}`)
  }

  const handleCloseAfterPayment = () => {
    onDogsClose();
    //console.log("Success")
  };

  return (
    <>
      <Flex
        direction={'column'}
        justifyContent={"stretch"}
        maxW={"688px"} mx={'16px'} w={'calc(100% - 32px)'}
        mb={{ base: 8, md: 12 }}
        boxShadow={"0px 5.1px 10.2px rgba(0,0,0,0.1)"}
        position={'relative'}
      >
        <Flex direction={{ base: 'column', lg: 'row' }} borderRadius={'6px'}>
          <Flex
            width={{ base: '100%', lg: '30%' }} h={'auto'} maxH={{ base: '180px', md: 'unset' }}
            bg="bg-accent"
            borderRadius={{ base: '6px 6px 0px 0px', md: '6px 0px 0px 6px' }}
            className='gallery-container' overflow={'hidden'}
          >
            <Box position={'absolute'} top={'15px'} left={'15px'} zIndex={'5'}
              bgColor={'white'} boxShadow={'dmShadow'} px={4} py={2} borderRadius={'12px'}
              fontWeight={'bold'} fontSize={'0.95em'} display={{ base: 'block', md: 'none' }}
            >
              {reservationStatus}
            </Box>
            {/* Property Photo Gallery */}
            <Box width="100%" mx="auto" borderRadius={'6px'}>
              <Gallery
                images={reservationData.house.images}
              />
            </Box>
          </Flex>

          <Flex
            width={{ base: '100%', lg: '75%' }}
            bg={"gray.50"}
            borderRadius={'0px 6px 0px 0px'}
            justifyContent={"space-between"}
            flexDirection={'column'} gap={1} px={5} pb={{ base: 3, md: 4 }} pt={{ base: 4, md: 8 }}
            fontSize={{ base: '0.95em', md: '1em' }}
            borderBottom={{ base: 'none', md: 'solid 1px #DDD' }}
          >
            {/* Basic Reservation Info */}
            <Text><b>Res #: </b>{reservationData.reservationNumber}</Text>
            <Text><b>Check-in: </b>{formattedCheckinDateNoTimeZone} {checkInTimeFormatted}</Text>
            <Text><b>Check-out: </b>{formattedCheckoutDateNoTimeZone} {checkOutTimeFormatted}</Text>
            <Text><b>Address: </b>{reservationData.house.address}</Text>
          </Flex>
        </Flex>
        <Flex className='dog-container' px={4} pt={4} pb={{ base: 4, md: 1 }} fontSize={{ base: '0.95em', md: '1em' }}>
          {reservationData.house.tags.includes('Dog Friendly') ?
            (reservationData.dogFeePaid && reservationData.dogFeePaid > 0 ?
              <Flex gap={2} alignItems={'center'}>
                <IconPaw stroke={1} />
                <Text>Dog fee payment of ${reservationData.dogFeePaid} received. </Text>
                <Button size={'sm'} whiteSpace={'wrap'} h={'100%'} ml={2}
                  onClick={onDogsOpen}
                >Add more dogs?</Button>
              </Flex>
              : reservationData.dogOptions === 'Service Animal' ?
                <Flex gap={2} alignItems={'center'}>
                  <IconPaw stroke={1} />
                  <Text>You have added a Service Animal to your reservation.</Text>
                  <Button size={'sm'} whiteSpace={'wrap'} h={'100%'} ml={2}
                    onClick={onDogsOpen}
                  >Add more dogs?</Button>
                </Flex>
                :
                <Flex gap={2} alignItems={'center'}>
                  <IconPawOff stroke={1} />
                  <Text>No dogs will be joining during your reservation.</Text>
                  <Button size={'sm'} whiteSpace={'wrap'} h={'100%'} ml={2}
                    onClick={onDogsOpen}
                  >Add dogs</Button>
                </Flex>
            )
            :
            (
              <Flex gap={2} alignItems={'center'}>
                <IconPawOff stroke={1} />
                <Text>This home is not pet friendly. Please refer to your <Link onClick={onAgreementsOpen}>Guest Agreement</Link> for details.</Text>
              </Flex>
            )
          }
        </Flex>

        <Flex className='button-container' flexDirection={{ base: 'column', md: 'row' }}
          gap={{ base: 1.5, md: 2 }} justifyContent={'space-between'}
          px={{ base: 4, md: 4 }} pb={{ base: 1.5, md: 2 }} pt={{ base: 0, md: 4 }}
        >
          <Button
            className="dm-button-orange-solid-small" boxShadow="base" size={'sm'} whiteSpace={'wrap'} flex={1}
            onClick={() => navigate(`${reservationData.reservationNumber}?open=accessinformation`)} py={{ base: 4, md: 8 }} minW={'90px'} fontSize={{ base: '0.85em', md: '1em' }}
          >
            Home Access Information
          </Button>
          <Button
            className="dm-button-orange-solid-small" boxShadow="base" size={'sm'} whiteSpace={'wrap'} flex={1}
            onClick={() => handleTripDetails()} py={{ base: 4, md: 8 }} minW={'90px'} fontSize={{ base: '0.85em', md: '1em' }}
          >
            View Trip Details
          </Button>
        </Flex>

        <Flex className='button-container' flexWrap={'wrap'}
          gap={{ base: 1.5, md: 2 }} justifyContent={'space-between'}
          px={{ base: 4, md: 4 }} pb={4} pt={{ base: 0, md: 0 }}
        >
          <Button
            className="dm-button-navy-pill-small share-reservation-widget" boxShadow="base" size={'sm'} whiteSpace={'wrap'} flex={1}
            onClick={onShareOpen} py={{ base: 6, md: 8 }} minW={'90px'} fontSize={{ base: '0.85em', md: '1em' }}
          >
            Share
          </Button>

          {/* Allow Rebook if House Still Active */}
          {reservationData.house.activeListing &&
            <Button
              className="dm-button-navy-pill-small rebook-reservation-widget" boxShadow="base" size={'sm'} whiteSpace={'wrap'} flex={1}
              onClick={onRebookOpen} py={{ base: 6, md: 8 }} minW={'90px'} fontSize={{ base: '0.85em', md: '1em' }}
            >
              Rebook
            </Button>
          }
          <Button
            className="dm-button-navy-pill-small home-guide-reservation-widget" boxShadow="base" size={'sm'} whiteSpace={'wrap'} flex={1}
            onClick={onHomeGuideOpen} py={{ base: 6, md: 8 }} minW={'90px'} fontSize={{ base: '0.85em', md: '1em' }}
          >
            Home Guide
          </Button>
        </Flex>
      </Flex>

      <RebookCalendar
        modalInitiateOpen={isRebookOpen}
        reservationData={reservationData}
        onModalClose={onRebookClose}
        onManualOpen={handleManualOpen}
      />

      <ShareWidget
        modalInitiateOpen={isShareOpen}
        reservationData={reservationData}
        onModalClose={onShareClose}
      />

      <ReviewModal
        modalInitiateOpen={isReviewOpen}
        reservationData={reservationData}
        onReviewClose={onReviewClose}
      />

      <Modal isOpen={isHomeGuideOpen} onClose={onHomeGuideClose} size={{ base: 'full', md: '6xl' }}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>How This Home Works</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <HowThisHomeWorks
              reservationData={reservationData}
            />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="dmNavy" onClick={onHomeGuideClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isAgreementsOpen} onClose={onAgreementsClose} size={{ base: 'full', md: '6xl' }}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Guest Agreement</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <GuestAgreements reservationNumber={reservationData.reservationNumber} rebook={reservationData.rebook} />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="dmNavy" onClick={onAgreementsClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isDogsOpen} onClose={onDogsClose} size={{ base: 'full', md: '6xl' }}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Dog Guests</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <DogAgreementPortal reservationData={reservationData} onSuccessfulPayment={handleCloseAfterPayment} />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="dmNavy" onClick={onDogsClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isAccessInfoOpen} onClose={onAccessInfoClose} size={{ base: 'full', md: '6xl' }}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Home Access Information</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <CheckInInfo
              reservationData={reservationData}
            />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="dmNavy" onClick={onAccessInfoClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

    </>
  );
};

export { ReservationWidget };

