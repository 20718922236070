import {
  Box
} from "@chakra-ui/react";
import { SearchResultsGrid } from "../components/SearchResultsGrid";

const Favorites = () => {

  return (
    <Box textAlign="center" fontSize="xl" bg={"white"}>
      <SearchResultsGrid
        params='placeholder'
        favoritesPage={true}
      />
    </Box>
  );
};
export { Favorites };

