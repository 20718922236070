import { ChevronLeftIcon } from "@chakra-ui/icons";
import { Button, Flex, Text, useToast } from "@chakra-ui/react";
import { useUser } from "@clerk/clerk-react";
import * as React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import PlacesMap from "../components/PlacesMap";
import { MobileMenu } from "../components/guestportal/MobileMenu";
import { PlacesGridWidget } from "../components/guestportal/PlacesGridWidget";
import { WelcomePopup } from "../components/guestportal/WelcomePopup";
import { useTrips } from "../contexts/TripContext";
import { fetchReservationData } from "../helpers/fetch";

const Places = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const toast = useToast();
  const { paramReservationNumber } = useParams();
  const { user } = useUser();

  // Helper function to extract the query parameter
  const getQueryParam = (param) => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get(param);
  };
  const ownerPortal = getQueryParam("ownerportal");

  const {
    allTripsData,
    updateAllTrips,
    selectedTrip,
    setSelectedTrip,
    currentFutureTrips,
    isTripsContextLoaded
  } = useTrips();

  const [validTripParam, setValidTripParam] = React.useState(false);
  const [places, setPlaces] = React.useState([]);
  const [placesLoading, setPlacesLoading] = React.useState(true);
  const [placesType, setPlacesType] = React.useState("Discounts/Partners");

  const handlePlacesChange = (newPlaces) => {
    setPlaces(newPlaces);
  };

  React.useEffect(() => {
    // Update all trips only if user is logged in
    if (user) {
      updateAllTrips();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, paramReservationNumber]);

  React.useEffect(() => {
    // Select the first trip or the current trip
    if (currentFutureTrips && currentFutureTrips.length > 0) {
      setSelectedTrip(selectedTrip || currentFutureTrips[0] || allTripsData[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFutureTrips]);

  React.useEffect(() => {
    // Adjust the places type based on the URL
    if (location.pathname.includes("/places")) {
      setPlacesType("Beaches/Ponds");
    } else {
      setPlacesType("Discounts/Partners");
    }
  }, [location.pathname]);

  React.useEffect(() => {
    // Handle reservation data for non-authenticated users
    if (!user && paramReservationNumber) {
      const fetchReservation = async () => {
        try {
          const response = await fetchReservationData(paramReservationNumber);
          const data = await response.json();

          if (response.ok && data) {
            setSelectedTrip(data);
            setValidTripParam(true);
          } else {
            toast({
              title: "Reservation Not Found",
              description: "We could not find the reservation. Please double-check your link.",
              status: "error",
              duration: 5000,
              isClosable: true,
            });
            setValidTripParam(false);
          }
        } catch (error) {
          console.error("Error fetching reservation data:", error);
          setValidTripParam(false);
        }
      };

      fetchReservation();
    } else if (paramReservationNumber && isTripsContextLoaded) {
      // Handle logged-in user
      if (selectedTrip?.reservationNumber !== paramReservationNumber) {
        const matchingTrip = allTripsData.find(
          (trip) => trip.reservationNumber === paramReservationNumber
        );
        if (matchingTrip) {
          setSelectedTrip(matchingTrip);
          setValidTripParam(true);
        } else {
          setValidTripParam(false);
        }
      } else {
        setValidTripParam(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramReservationNumber, isTripsContextLoaded, user]);

  return (
    <>
      <Flex className="tripcontainer" w="100vw">
        {validTripParam && paramReservationNumber && selectedTrip ? (
          <Flex
            pt={{ base: 2, lg: 8 }}
            justifyContent="start"
            alignItems="center"
            direction="column"
            w="100vw"
            maxW="720px"
            className="places-container"
          >
            <Flex
              direction={{ base: "column" }}
              textAlign="left"
              justifyContent="center"
              maxW="680px"
              mx="20px"
              w="calc(100% - 40px)"
              pb={3}
            >
              <Flex
                alignItems="center"
                fontSize={{ base: 16, md: 20 }}
                mb={{ base: 2, md: 4 }}
                justifyContent="space-between"
              >
                {user &&
                  <Flex my={3}>
                    <ChevronLeftIcon
                      onClick={() =>
                        window.open(`/trips/${paramReservationNumber}`, "_self")
                      }
                    />

                    <Text
                      as="span"
                      cursor="pointer"
                      ml={2}
                      lineHeight="16px"
                      onClick={() => navigate(`/trips/${paramReservationNumber}`)}
                    >
                      Back to Trip Details
                    </Text>

                  </Flex>
                }
              </Flex>
              <Flex gap={{ base: 1, md: 2 }} mb={3}>
                <Button
                  variant="outline"
                  colorScheme="dmNavy"
                  fontSize={{ base: "sm", md: "md" }}
                  isDisabled={placesType === "Beaches/Ponds"}
                  onClick={() => {
                    setPlacesType("Beaches/Ponds");
                  }}
                >
                  Beaches
                </Button>
                <Button
                  variant="outline"
                  colorScheme="dmNavy"
                  fontSize={{ base: "sm", md: "md" }}
                  isDisabled={placesType === "Restaurants"}
                  onClick={() => {
                    setPlacesType("Restaurants");
                  }}
                >
                  Restaurants
                </Button>
                <Button
                  variant="outline"
                  colorScheme="dmNavy"
                  fontSize={{ base: "sm", md: "md" }}
                  isDisabled={placesType === "Shopping/Retail"}
                  onClick={() => {
                    setPlacesType("Shopping/Retail");
                  }}
                >
                  Shopping
                </Button>
                <Button
                  variant="outline"
                  colorScheme="dmNavy"
                  fontSize={{ base: "sm", md: "md" }}
                  isDisabled={placesType === "Discounts/Partners"}
                  onClick={() => {
                    setPlacesType("Discounts/Partners");
                  }}
                >
                  Discounts
                </Button>
              </Flex>
              <PlacesGridWidget
                title={placesType}
                subtitle="From our local partner businesses in Cape Cod."
                subtitleColor="#777"
                subType={placesType}
                houseData={selectedTrip.house}
                onPlacesChange={handlePlacesChange}
                onPlacesLoadingChange={setPlacesLoading}
              />
            </Flex>
          </Flex>
        ) : (
          <Flex
            pt={{ base: 2, lg: 16 }}
            justifyContent="start"
            alignItems="center"
            direction="column"
            w={{ base: "100vw", md: "50vw" }}
            maxW="720px"
            className="places-container"
          >
            <Flex
              direction={{ base: "column" }}
              textAlign="left"
              justifyContent="center"
              maxW="680px"
              mx="20px"
              w="calc(100% - 40px)"
              pb={3}
            ></Flex>
            <Flex
              alignItems="center"
              fontSize={{ base: 16, md: 20 }}
              mb={{ base: 2, md: 4 }}
              justifyContent="space-between"
            >
              {!ownerPortal ?
                <Flex my={3}>
                  <ChevronLeftIcon onClick={() => navigate("/trips")} />
                  <Text
                    as="span"
                    cursor="pointer"
                    ml={2}
                    lineHeight="16px"
                    onClick={() => navigate("/trips")}
                  >
                    Back to My Trips
                  </Text>
                </Flex>
                :
                <Flex my={3}>
                  <ChevronLeftIcon onClick={() => window.open(`https://mydelmar.com/trip-details?reservation_id=${ownerPortal}`, '_self')} />
                  <Text
                    as="span"
                    cursor="pointer"
                    ml={2}
                    lineHeight="16px"
                    onClick={() => window.open(`https://mydelmar.com/trip-details?reservation_id=${ownerPortal}`, '_self')}
                  >
                    Back to My Trips
                  </Text>
                </Flex>
              }
            </Flex>
          </Flex>
        )}

        {!placesLoading && (
          <Flex
            className="places-map-container"
            h={{ base: "calc(100vh - 150px)", md: "calc(100vh - 90px)" }}
            w={{ base: "100%" }}
            position="sticky"
            right="0px"
            top="80px"
            display={{ base: "none", md: "flex" }}
          >
            <PlacesMap
              navigate={navigate}
              places={places}
              houseData={selectedTrip.house}
            />
          </Flex>
        )}
      </Flex>

      {user && isTripsContextLoaded &&
        <WelcomePopup modalInitiateOpen={!user?.unsafeMetadata?.loggedIn} />
      }

      {user && isTripsContextLoaded && allTripsData.length > 0 && (
        <MobileMenu
          activeReservationData={allTripsData[allTripsData.length - 1]}
          contactOwner={{
            name: "Amanda Perry",
            title: "Guest Services Associate",
            avatar: "./media/Amanda.png",
          }}
        />
      )}
    </>
  );
};

export { Places };

