import {
  Alert,
  AspectRatio,
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Link,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Skeleton,
  Text,
  UnorderedList,
  useDisclosure,
} from "@chakra-ui/react";
import { useUser } from "@clerk/clerk-react";
import * as React from "react";
import { sendHubspotEvent } from "../../helpers/hubspot";

type Props = {
  reservationData: any;
  modalInitiateOpen: boolean;
  onModalClose: Function;
  onManualOpen?: Function;
};

const RebookCalendar: React.FC<Props> = ({
  reservationData,
  modalInitiateOpen,
  onModalClose,
  onManualOpen
}) => {
  const [modalOpen, setModalState] = React.useState(modalInitiateOpen);
  const [rebookCoupon, setRebookCoupon] = React.useState(null);
  const [rebookCouponPercentage, setRebookCouponPercentage] = React.useState(null)
  const [daysSinceCheckOut, setDaysSinceCheckout] = React.useState(null);
  const { isOpen: isTermsModalOpen, onOpen: onOpenTermsModal, onClose: onCloseTermsModal } = useDisclosure();

  const { user } = useUser();
  const userEmail = user?.primaryEmailAddress?.emailAddress;

  function closeModal() {
    setModalState(false);
  }
  function modalClose() {
    closeModal();
    onModalClose();
  }

  const houseNumber = reservationData["house"]["houseNumber"];
  const houseName = reservationData["house"]["name"]
  //console.log(reservationData)

  React.useEffect(() => {
    if (reservationData.checkoutDate) {
      const rebookCheckOutDate = reservationData.checkoutDate; // Date String
      const timeDifference = new Date().getTime() - new Date(rebookCheckOutDate).getTime();
      const daysDifference = timeDifference / (1000 * 3600 * 24);
      setDaysSinceCheckout(daysDifference);
      //console.log('daysDifference', daysDifference, 'timeDifference', timeDifference);

      if (daysDifference < 0.5) {
        setRebookCoupon('FJCP97YWVP9J');
        setRebookCouponPercentage(12);
      } else if (daysDifference <= 14) {
        setRebookCoupon('DXDJJQDVJJID');
        setRebookCouponPercentage(10);
      } else {
        setRebookCoupon('1KRYHEMOUFVN');
        setRebookCouponPercentage(5);
      }

    }
  }, [reservationData.checkoutDate]);

  React.useEffect(() => {
    const now = new Date().getTime();
    const lastOpened = localStorage.getItem('lastRebookModalOpened');
    const fifteenMinutes = 15 * 60 * 1000;

    if (lastOpened && now - parseInt(lastOpened, 10) < fifteenMinutes) {
      // Do not open the modal if it was opened less than 15 minutes ago
      return;
    }

    if (daysSinceCheckOut > 0 && daysSinceCheckOut < 120) {
      setModalState(true);
      localStorage.setItem('lastRebookModalOpened', now.toString());
    }
  }, [daysSinceCheckOut]);

  const handleManualOpen = () => {
    setModalState(true);
  };

  React.useEffect(() => {
    if (modalInitiateOpen) {
      handleManualOpen();
    }
  }, [modalInitiateOpen]);

  let nextYearCheckIn = new Date();
  nextYearCheckIn.setDate(nextYearCheckIn.getDate() + 366);

  let nextYearCheckOut = new Date();
  nextYearCheckOut.setDate(nextYearCheckOut.getDate() + 373);

  const handleRebookButtonClick = (rebookType) => {
    const eventName = "pe21053466_rebook_button_click";
    const properties = {
      house_number: houseNumber,
      reservation_number: reservationData.reservationNumber,
      rebook_click_type: rebookType,
      hs_page_url: window.location.href,
    }

    if (userEmail) {
      // Fire and forget
      (async () => {
        try {
          await sendHubspotEvent({ eventName, properties, email: userEmail });
        } catch (error) {
          console.error("Error sending HubSpot event:", error);
        }
      })();
    }

    localStorage.setItem('dm-rebook-reservation', reservationData.reservationNumber);

    if (rebookType === "thishome") {
      window.open(`/${houseNumber}/?source=guestportal&rebook=${reservationData.reservationNumber}`, '_blank');
    } else {
      window.open(`/?source=guestportal&rebook=${reservationData.reservationNumber}`, '_blank');
    }
  }

  return (
    <>
      {/* Rebook Modal */}
      {daysSinceCheckOut &&
        <Modal isOpen={modalOpen} onClose={() => void 0} size={{ base: 'full', md: '6xl' }}>
          <ModalOverlay
            bg="blackAlpha.700"
            backdropFilter="blur(10px) hue-rotate(90deg)"
          />
          <ModalContent borderRadius="none">
            <ModalCloseButton size="lg" onClick={modalClose} />
            <ModalBody padding="0">
              <Flex align="center">
                <AspectRatio
                  ratio={3 / 5}
                  width="50%"
                  maxW={{ lg: "md" }}
                  display={{ base: "none", lg: "flex" }}
                >
                  <Image
                    src={"https://21053466.fs1.hubspotusercontent-na1.net/hubfs/21053466/Boat%20Sunset%20(Facebook%20Post%20(Landscape)).png"}
                    alt="Sunset"
                    fallback={<Skeleton />}
                    objectFit={"cover"}
                    align={"left"}
                  />
                </AspectRatio>

                <Flex
                  direction="column"
                  justifyContent={'start'}
                  alignItems={'center'}
                  flex="1"
                  pt={12}
                  pb={{ base: 3, md: 12 }}
                  px={{ base: "2", md: "6" }}
                  maxW={'100%'}
                >
                  <Box
                    textAlign={{ base: "center", md: "center" }}
                    maxW={{ base: "full", sm: "xl" }}
                    mx={{ base: "3", md: "auto" }}
                    my={{ base: "2", md: "10" }}
                  >
                    <Heading fontWeight="extrabold" fontSize={{ base: "24px", md: "32px" }}>
                      Ready for your next visit?
                    </Heading>
                    <Box
                      fontSize={{ base: "md", md: "lg" }}
                      textAlign={{ base: "left", md: "left" }}
                      mt="6"
                      mb="6"
                    >
                      <Text textAlign={'center'} py={{ base: 2, md: 3 }}>
                        <b>Rebook #{houseNumber} {houseName}</b>, or a different home.
                      </Text>
                      {(!["manual", "direct", "be-api", "website", "standard"].includes((reservationData?.typename ?? '').toLowerCase())) && (
                        <Text textAlign={'center'} pb={3}>
                          Last time you booked with a middle man, and paid an extra 15% in fees. Rebook direct and save.
                        </Text>
                      )}

                      <Text textAlign={'center'} pb={3}><u>The link below is the <b>only</b> way to get</u>:</Text>

                      <Alert status='success' py={0} px={{ base: 2, md: 4 }}>
                        <UnorderedList pl={3} py={4}>
                          <ListItem>Reserve any home with only a $300 deposit.</ListItem>
                          {rebookCoupon && rebookCouponPercentage && (
                            <ListItem>{rebookCouponPercentage}% off your stay instantly.</ListItem>
                          )}
                          <ListItem>Reserve the best dates, at the best home.</ListItem>
                          <ListItem>Flexible cancellation.</ListItem>
                          <ListItem>Change dates or home at any time.</ListItem>
                        </UnorderedList>
                      </Alert>
                      <Text fontSize={'0.9em'} pt={3}>
                        <i>New reservation must be at least 7 months from today to qualify for the rebook payment terms and discount. <Link onClick={onOpenTermsModal}>View Payment Terms.</Link></i>
                      </Text>
                    </Box>
                    <Flex gap={4} justifyContent={'center'} alignContent={'center'}>
                      <Button
                        onClick={() => { handleRebookButtonClick('thishome') }}
                        className="dm-button-orange-pill rebook-redirect-booking-site"
                        w={{ base: "100%", lg: "100%" }}
                        boxShadow="base"
                      >
                        Rebook this Home
                      </Button>
                      <Button
                        onClick={() => { handleRebookButtonClick('otherhome') }}
                        className="dm-button-navy-outline"
                        w={{ base: "100%", lg: "100%" }}
                        boxShadow="base"
                      >
                        Explore other homes
                      </Button>
                    </Flex>
                  </Box>
                  {/* Payment Terms Modal */}
                  <Modal isOpen={isTermsModalOpen} onClose={onCloseTermsModal} size={{ base: 'full', md: "2xl" }}>
                    <ModalOverlay />

                    <ModalContent position="relative">
                      <ModalCloseButton />
                      <ModalBody mt={0} mb={4} textAlign={'start'}>
                        <Text fontWeight={'bold'} my={2} fontSize={'lg'}>Payment Terms</Text>
                        <Text>When reservation check in date is 7+ months ahead, and after previous check out date, the payment schedule is as follows:</Text>
                        <Text pt={3}>$300 due at booking (non-refundable).</Text>
                        <Text pt={3}>50% of remaining total due 180 days prior to check in.</Text>
                        <Text pt={3}>Remaining balance due 60 days prior to check in.</Text>
                        <Text fontWeight={'bold'} my={2} fontSize={'lg'} pt={4}>Cancellation</Text>
                        <Text>If a reservation is canceled with 180+ days prior to check in, the cancellation fee is $300. If a reservation is canceled between 179 and 30 days prior to check in, the cancellation fee is 25% of the total cost of the reservation. If the reservation is canceled with less than 29 days to check in, all monies paid are forfeited. Up to 30 days prior to check in, the reservation dates or home can be modified for no additional fee, only the difference in price between dates/homes will be charged/credited.</Text>
                        <Text pt={3}>Any guest booking more than 28 nights on any booking platform needs to adhere to the long term stay cancellation policy: Cancellations received 48 hours after booking and 30 days prior to arrival have a 50% cancellation fee. Cancellations that occur less than 30 days from the check-in date will result in forfeiture of the entire rental amount. No exceptions.</Text>
                        <Text pt={3}>If this home's availability changes for any reason, you will be offered a full refund, or relocated to another similar home of your choosing.</Text>
                      </ModalBody>
                    </ModalContent>
                  </Modal>
                  {/* END Payment Terms Modal */}
                </Flex>
              </Flex>
            </ModalBody>
          </ModalContent>
        </Modal>
      }

      {/* End Rebook Modal */}
    </>
  );
};

export { RebookCalendar };

