import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Center,
  Flex,
  Image,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent, ModalHeader,
  ModalOverlay,
  useDisclosure
} from "@chakra-ui/react";
import * as React from "react";

type HouseAccordionItemProps = {
  accordionTitle: string;
  accordionText: string;
};

const HouseAccordionItem: React.FC<HouseAccordionItemProps> = ({
  accordionTitle,
  accordionText,
}) => {

  return (
    <AccordionItem
      w={{ base: '90vw', md: '2xl', lg: '3xl', xl: '5xl' }}
    >
      <h2>
        <AccordionButton>
          <Box
            as="span"
            flex="1"
            textAlign="left"
            fontSize={18}
            fontWeight={"500"}
            my={2}
          >
            {accordionTitle}
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </h2>
      <AccordionPanel pt={1} pb={4} textAlign="left" fontSize={16} mb={4} lineHeight={8}>
        {accordionText}
      </AccordionPanel>
    </AccordionItem>
  )
};

const FAQ = ({ reservationNumber }) => {

  const [modalTitle] = React.useState("");

  const questions = {
    "Can I check-in early?": "We are unable to guarantee an early check in for any guest. We have cleaning, landscaping, maintenance, and trash teams visiting the home prior to your 4pm check in to perfectly prepare your home for you. We need to give our teams ample time to complete these services. Our guest services team will reach out to you when we have confirmed with all teams your home is ready for check in, if it is ready early.",
    "Can I check-out late?": "We are able to offer a limited number of late checkouts per day. Please note that a late checkout is not guaranteed and solely based on what our cleaning team can accommodate for the number of check outs per town. You can request a late checkout 48 hours prior to your check in. Late check out requests within 48 hours of your check out date cannot be accommodated as our cleaning schedule has already been set.",
    "Do I need a beach sticker?": "Beach stickers on Cape Cod are specifically registered to your vehicle. They are used to allow you to park your vehicle at the beach. If you are not planning on driving to the beach and parking there, you do not need a sticker. Most towns offer a seasonal or short term beach sticker which you can apply for via the town website. Most towns also offer daily parking passes at the beach gate which you can pay for per day in cash. If you are staying at a home that is waterfront, or walkable to water and plan to walk or be dropped off, you do not need a beach sticker.",
    "Is there additional parking?": "The number of cars listed as the maximum at your home is likely the most amount of cars that can physically be parked at the home. We cannot permit additional cars to be parked on the grass or in the street. Depending on the town, there may be additional paid or free public parking options. As extra parking is not guaranteed, we suggest planning to bring only the number of cars that you can park at the home.",
    "How do I recycle?": "We use single stream recycling, so all trash can go into the designated bins at your home and we will separate them at the dump.",
    "I booked on Airbnb, did I pay a deposit?": "Our Airbnb guests are not charged a security deposit. Airbnb handles all payments, so if you have a question regarding charges and/or charging schedules, please reach out to Airbnb directly.",
    "Does my home have beach equipment?": (
      <>
        While our homes don't always come equipped with beach gear, you can effortlessly upgrade your stay through our guest portal. Dive into the ultimate beach experience with our exclusive extras! Discover our top-quality beach equipment available for rent, ensuring you have everything you need for sun-soaked adventures. Don't leave your beach day to chance—secure your gear today and make every moment by the shore unforgettable. Check out the <Link color={'#e95037'} target={'_self'} href={`/store/${reservationNumber}`}>extras section</Link> in your guest portal now and let the beach fun begin!
        <br />
        <Link color={'#e95037'} target={'_self'} href={`/store/${reservationNumber}`}>Order Extras for Your Trip</Link>.
      </>
    ),
    "Does my home have baby equipment?": (
      <>
        Unless specifically listed in the listing, we do not have baby equipment at our homes.
        We always recommend renting any baby equipment you may need from{" "}
        <Link color={'#e95037'} target={'_blank'} href='https://www.kid-kaboodle.com/rentalgear'>Kid and Kaboodle - https://www.kid-kaboodle.com/rentalgear</Link>
        {" "}or{" "}
        <Link color={'#e95037'} target={'_blank'} href='https://www.babyquip.com'>Babyquip - https://www.babyquip.com</Link>.
        These services will deliver your order to your rental home, and pick it up at the end of your stay!
      </>
    ),
    "Can I have extra guests?": "You are permitted to have up to 4 day guests in addition to the occupancy. This means if your home sleeps a maximum occupancy of 6, you can have a maximum overnight occupancy of 6 guests, and 4 additional day guests. Your guests do need to abide by the parking instructions. Additional cars cannot be parked in the street or on the lawn.",
    "Does Del Mar have any area recommendations for things to do, restaurants, etc?": (
      <>
        "Yes! Please visit {" "}
        <Link color={'#e95037'} target={'_blank'} href='/stay'><b><i>Things To Do</i></b></Link>
        {" "}
        to see all our local recommendations and exclusive discounts we have for our guests!
      </>
    ),

  };

  const itemsToShow = Object.keys(questions);

  // Code for converting houseRulesPhotos and pathToWaterPhotos into arrays

  const accordionItems = Object.keys(questions).map((key) => {
    if (itemsToShow.includes(key)) {
      return (
        <HouseAccordionItem
          key={key}
          accordionTitle={key}
          accordionText={questions[key]}
        />
      );
    } else {
      return null;
    }
  });

  const { isOpen: isImageOpen, onClose: onImageClose } = useDisclosure();
  const [selectedImage] = React.useState("");

  return (
    <Box textAlign="center" fontSize="xl" bg={"white"}>

      <Center pb={{ base: 5 }}>
        <Flex bg={"white"} width={"100%"} justifyContent={"center"} py={{ base: 7, md: 10 }}>
          <Box maxW={'100%'}>
            <Accordion
              defaultIndex={[]}
              allowMultiple
              maxW={"1440px"}
              width={{ base: "100%" }}
            >
              {accordionItems}
            </Accordion>
          </Box>
        </Flex>
      </Center>

      <Modal isOpen={isImageOpen} onClose={onImageClose}>
        <ModalOverlay />
        <ModalContent pb={3} width={'auto'} maxW={'90vw'} maxH={'90vh'}>
          <ModalHeader>{modalTitle}</ModalHeader>
          <ModalCloseButton />
          <ModalBody maxW={'90vw'} maxH={'90vh'}>
            <Image src={selectedImage} maxW={'80vw'} maxH={'80vh'} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  )
};

export { FAQ };

