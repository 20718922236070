import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text, VStack
} from "@chakra-ui/react";
import * as React from "react";
import { convertStringtoDateAndFormat } from "../../helpers/helpers";

type Props = {
  paymentSchedule: any;
  modalInitiateOpens: boolean;
  onModalClose: Function;
};

const PaymentScheduleWidget: React.FC<Props> = ({
  paymentSchedule,
  modalInitiateOpens,
  onModalClose
}) => {
  const [modalOpen, setModalState] = React.useState(modalInitiateOpens);

  function closeModal() {
    setModalState(false);
  }

  function modalClose() {
    closeModal();
    onModalClose();
  }

  React.useEffect(() => {
    setModalState(modalInitiateOpens);
  }, [modalInitiateOpens]);

  if (!paymentSchedule || !paymentSchedule.lineItems?.length) {
    return null;
  }

  return (
    <Modal isOpen={modalOpen} onClose={() => void 0} size={{ base: 'full', md: 'xl' }}>
      <ModalOverlay
        bg="blackAlpha.700"
        backdropFilter="blur(10px) hue-rotate(90deg)"
      />
      <ModalContent borderRadius="none">
        <ModalCloseButton size="lg" onClick={modalClose} />
        <ModalBody padding="0">
          <Box bg="white" p={4} borderRadius="md" boxShadow="md" w="100%">
            <Text fontSize="lg" fontWeight="bold" mb={2}>
              Payment Schedule
            </Text>
            <VStack align="start" spacing={2}>
              {paymentSchedule.lineItems.map((item, index) => (
                <Box key={index} w="100%" p={2} borderBottom="1px solid #eee">
                  <Text fontWeight="medium">{item.note}</Text>
                  <Text>Due On {convertStringtoDateAndFormat(item.payAt)}: ${item.amount.toFixed(2)}</Text>
                </Box>
              ))}
            </VStack>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export { PaymentScheduleWidget };

