import {
  Box,
  Button,
  Flex,
  Image,
  Link,
  Spinner,
  Text,
} from "@chakra-ui/react";
import * as React from "react";
import { useParams } from "react-router-dom";
import { AvailabilityCalendar } from "../components/guestportal/calendar/AvailabilityCalendar";
import { MobileMenu } from "../components/guestportal/MobileMenu";
import { useTrips } from "../contexts/TripContext";

const Rebook = () => {
  const { paramReservationNumber } = useParams();

  const {
    allTripsData,
    updateAllTrips,
    selectedTrip,
    setSelectedTrip,
    isTripsContextLoaded,
  } = useTrips();
  const [reservationLoaded, setReservationLoaded] = React.useState(false);

  function modalClose() {
    return;
  }

  //console.log("selected trip", selectedTrip)
  //console.log("paramReservationNumber",paramReservationNumber)

  // Google is Default Review Redirect if Not Airbnb or VRBO Types
  React.useEffect(() => {
    updateAllTrips();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramReservationNumber]);

  React.useEffect(() => {
    if (paramReservationNumber && isTripsContextLoaded) {
      //console.log("paramReservationNumber",paramReservationNumber)
      // Check if the current selectedTrip is not the one specified by paramReservationNumber
      if (selectedTrip?.reservationNumber !== paramReservationNumber) {
        // Find the matching trip from allTripsData using the paramReservationNumber
        const matchingTrip = allTripsData.find(
          (trip) => trip.reservationNumber === paramReservationNumber
        );
        // If a matching trip is found, update selectedTrip with it
        if (matchingTrip) {
          setSelectedTrip(matchingTrip);
          setReservationLoaded(true);
        } else {
          // Optional: Handle the case where no matching trip is found (e.g., navigate to a "trip not found" page or show a message)
          //console.log('No matching trip found for reservation number:', paramReservationNumber);
          setReservationLoaded(true);
        }
      } else if (selectedTrip?.reservationNumber === paramReservationNumber) {
        setReservationLoaded(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramReservationNumber, isTripsContextLoaded]);

  return (
    <>
      <Flex
        textAlign="center"
        fontSize="xl"
        bg={"white"}
        minH={{ base: 0, md: "1100px" }}
        w={"100vw"}
      >
        {paramReservationNumber && selectedTrip ? (
          <Flex
            bgColor="gray.50"
            width={{ base: "100vw" }}
            flexDirection={"column"}
          >
            <Flex
              direction={"column"}
              alignItems={"start"}
              position="relative"
              textAlign={"start"}
              pt={{ base: 4, md: 16 }}
              px={{ base: 8, md: 20, lg: 12, xl: 20 }}
            >
              <Text
                as="span"
                flexShrink={0}
                pt={3}
                pb={1}
                display={{ base: "none", md: "unset" }}
              >
                Currently rebooking:
              </Text>
              <Flex
                direction={"column"}
                position="relative"
                w={"100%"}
                id="level4"
              >
                {selectedTrip && (
                  <Box
                    bg={"white"}
                    mt={3}
                    pr={{ base: 2, sm: 6 }}
                    w={{ base: "100%" }}
                    border={"solid 1px rgba(83, 180, 255, 1)"}
                  >
                    <Flex height={"100%"}>
                      <Image
                        objectFit="cover"
                        src={selectedTrip.house.images[0].link}
                        alt="House"
                        mr={3}
                        height={"100%"}
                        maxW={"100px"}
                      />
                      <Flex
                        direction={"column"}
                        justifyContent="center"
                        fontSize={{ base: 16, sm: 18, md: 20, lg: 16, xl: 18 }}
                        my={3}
                      >
                        <Text>
                          Reservation #{selectedTrip.reservationNumber}
                        </Text>
                        <Text mt={1}>
                          House {selectedTrip.house.houseNumber}{" "}
                          {selectedTrip.house.name}{" "}
                        </Text>
                      </Flex>
                    </Flex>
                  </Box>
                )}
              </Flex>
            </Flex>

            {selectedTrip ? (
              <Flex align="center" w={"100%"}>
                <Flex
                  direction="column"
                  justifyContent={"start"}
                  alignItems={"center"}
                  flex="1"
                  pt={{ base: 2, md: 6 }}
                  pb={{ base: 3, md: 12 }}
                  px={{ base: "2", md: "6" }}
                >
                  <Box
                    textAlign={{ base: "center", md: "center" }}
                    maxW={{ base: "full", sm: "xl" }}
                    mx={{ base: "3", md: "auto" }}
                    mt={{ base: "0", md: "10" }}
                  >
                    <Text
                      fontSize={{ base: "md", md: "lg" }}
                      textAlign={{ base: "left", md: "center" }}
                      mb={{ base: 3, md: 6 }}
                    >
                      Make things easy and rebook{" "}
                      {selectedTrip.house.houseNumber} -{" "}
                      {selectedTrip.house.name} again. Choose the best dates in
                      your favorite home and continue to booking.
                    </Text>
                    <Flex pb={{ base: 1, md: 4 }} zIndex={"100000"}>
                      <AvailabilityCalendar
                        listingId={selectedTrip.house.guestyHouseId}
                        modalClose={modalClose}
                        houseNumber={selectedTrip.house.houseNumber}
                      />
                    </Flex>
                  </Box>
                </Flex>
              </Flex>
            ) : (
              <>
                <Flex
                  justifyContent={"center"}
                  w={"100%"}
                  textAlign="center"
                  pt={{ base: 3, md: 12 }}
                  pb={2}
                >
                  You do not have any homes that can currently be rebooked.
                </Flex>
                <Link
                  href="https://book.thisisdelmar.com/"
                  target="_blank"
                  w={{ base: "100%", lg: "auto" }}
                  className="no-underline"
                >
                  <Button
                    className="dm-button-orange-pill"
                    w={{ base: "100%", lg: "300px" }}
                    maxW={"90vw"}
                    boxShadow="base"
                    my={12}
                  >
                    Explore more homes
                  </Button>
                </Link>
                <Link
                  href="/"
                  w={{ base: "100%", lg: "auto" }}
                  className="no-underline"
                >
                  <Button
                    className="dm-button-navy-outline"
                    w={{ base: "100%", lg: "300px" }}
                    maxW={"90vw"}
                    boxShadow="base"
                    mb={12}
                  >
                    Back to Home
                  </Button>
                </Link>
              </>
            )}
          </Flex>
        ) : reservationLoaded ? (
          <Flex
            w={"100%"}
            h={"80vh"}
            justifyContent={"center"}
            alignItems={"center"}
            flexDirection={"column"}
          >
            <Text mb={6}>No Matching Reservation Found</Text>
            <Flex
              gap={4}
              flexDirection={{ base: "column", md: "row" }}
              w={{ base: "100%", lg: "auto" }}
            >
              <Link
                href="/trips"
                w={{ base: "100%", lg: "auto" }}
                className="no-underline"
              >
                <Button
                  className="dm-button-orange-pill"
                  w={{ base: "100%", lg: "300px" }}
                  maxW={"90vw"}
                  boxShadow="base"
                >
                  Back to My Trips
                </Button>
              </Link>
              <Link
                href="/"
                target="_self"
                w={{ base: "100%", lg: "auto" }}
                className="no-underline"
              >
                <Button
                  className="dm-button-navy-outline"
                  w={{ base: "100%", lg: "300px" }}
                  maxW={"90vw"}
                  boxShadow="base"
                  onClick={() => window.open("/", "_self")}
                >
                  Start a New Search
                </Button>
              </Link>
            </Flex>
          </Flex>
        ) : (
          <Flex
            w={"100%"}
            h={"90vh"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Spinner />
          </Flex>
        )}
      </Flex>
      {isTripsContextLoaded && allTripsData.length > 0 && (
        <MobileMenu
          activeReservationData={allTripsData[allTripsData.length - 1]}
          contactOwner={{
            name: "Amanda Perry",
            title: "Guest Services Associate",
            avatar: "./media/Amanda.png",
          }}
        />
      )}
    </>
  );
};

export { Rebook };

