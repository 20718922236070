import {
  Box
} from "@chakra-ui/react";
import * as React from "react";
import { SearchResultsGrid } from "../components/SearchResultsGrid";
import { SearchResultsContext } from '../contexts/SearchResultsContext';

const Explore = () => {

  const { mapDisplayOnLoad } = React.useContext(SearchResultsContext);

  //console.log('mapDisplayOnLoad',mapDisplayOnLoad)

  return (
    <Box data-id="explore" id="explore" textAlign="center" fontSize="xl" bg={"white"} h={'auto'}>
      <SearchResultsGrid
        params='placeholder'
        favoritesPage={false}
        showMapOnLoad={mapDisplayOnLoad}
      />
    </Box>
  );
};
export { Explore };

