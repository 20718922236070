import {
  Button,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text
} from "@chakra-ui/react";
import * as React from "react";
import { BsArrowRight } from "react-icons/bs";

type Props = {
  modalInitiateOpen: boolean;
}
const WelcomePopup: React.FC<Props> = ({ modalInitiateOpen }) => {

  const [isOpen, setIsOpen] = React.useState(modalInitiateOpen);

  function onClose() {
    setIsOpen(false);
  }

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} autoFocus={false} isCentered>
        <ModalOverlay />
        <ModalContent top={{ base: '-10px' }} maxW="1000px" mx={2}>
          <ModalCloseButton />
          <ModalBody h={'100%'}>
            <Flex direction={'column'} textAlign={'center'} justifyContent={'space-between'} h={'100%'} my={{ base: 10, md: 20 }} mx={{ base: 5, md: 20 }}>
              <Heading fontWeight="extrabold">
                Welcome to <Text as="span" className={'nowrap'}>Del Mar Vacations!</Text>
              </Heading>
              <Text fontSize="lg" mt="6" mb="3">
                Welcome to the Del Mar Guest Portal. Here you can access everything you need to make your vacation easier; get
                help during your stay, rebook dates, find fun things to do
                around Cape Cod, share trip details, and more!
              </Text>
              <Text fontSize="lg" mt="6" mb="3">
                Enjoy your stay!
              </Text>

              <Button
                mt={{ base: 10, md: '40px' }} mx={'auto'}
                className="dm-button-orange-pill"
                w={{ base: "100%", lg: "300px" }}
                boxShadow="base"
                onClick={onClose}
                rightIcon={<BsArrowRight />}
              >
                Enter
              </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export { WelcomePopup };

