import {
  Box, Button, Flex,
  Image,
  Link, Spinner, Text
} from "@chakra-ui/react";
import * as React from "react";
import { useParams } from 'react-router-dom';
import { WelcomeDetails } from '../components/guestportal/WelcomeDetails';
import { useReservation } from '../contexts/ReservationContext';
import { reformatDateString } from '../helpers/helpers';

const PreArrivalGuide = () => {
  const { reservationData, updateReservationData } = useReservation();
  const { paramReservationNumber } = useParams();

  const [reservationLoaded, setReservationLoaded] = React.useState(false);

  React.useEffect(() => {
    setReservationLoaded(false);
    updateReservationData(paramReservationNumber);
    setReservationLoaded(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramReservationNumber]);

  return (
    <>
      <Flex textAlign="center" fontSize="xl" bg={"white"} minH={{ base: 0, md: '1100px' }} w={'100vw'}>
        {paramReservationNumber && reservationData ?
          <Flex
            bgColor="gray.50"
            width={{ base: "100vw" }}
            flexDirection={'column'} alignItems={'center'}
          >
            <Flex direction={"column"} alignItems={"start"} justifyContent={'center'} position="relative" textAlign={'start'} pt={{ base: 2, md: 8 }}
              width={'90vw'} pb={8}
            >
              <Flex direction={"column"} position="relative" w={'100%'}>
                {reservationData && (
                  <Box
                    mt={3} pr={{ base: 2, sm: 6 }}
                    w={{ base: '100%' }}
                  >
                    <Flex direction={'column'} justifyContent="center" my={2} gap={{ base: 1, md: 0.5 }}>
                      <Text fontSize={'1.2em'} fontWeight={'bold'}>Your Cape Cod Vacation <Text as="span" whiteSpace="nowrap">Pre-Arrival</Text> Guide</Text>
                      <Image
                        objectFit="cover"
                        my={2}
                        src={reservationData.house.thumbnail}
                        alt="House"
                        height={'100%'}
                        borderRadius={8}
                        maxW={'390px'}
                      />
                      <Text fontSize={'0.8em'} fontWeight={'bold'}>{reservationData.house.name}</Text>
                      <Text fontSize={'0.8em'}>Reservation Number: {reservationData.reservationNumber}</Text>
                      <Text fontSize={'0.8em'}>Stay Dates: {reformatDateString(reservationData.checkinDate)} - {reformatDateString(reservationData.checkoutDate)}</Text>
                    </Flex>
                  </Box>
                )}
              </Flex>
            </Flex>

            {reservationData ? (
              <WelcomeDetails
                town={reservationData.house.town}
                channel={reservationData.channel}
                standalone={true}
                laundry={reservationData.house.laundry}
              />
            ) : (
              <>
                <Flex justifyContent={'center'} w={'100%'} textAlign='center' pt={{ base: 3, md: 12 }} pb={2}>You do not have any active reservations.</Flex>
                <Link
                  href="https://book.thisisdelmar.com/"
                  target="_blank"
                  w={{ base: "100%", lg: "auto" }}
                  className="no-underline"
                >
                  <Button
                    className="dm-button-orange-pill"
                    w={{ base: "100%", lg: "300px" }}
                    maxW={'90vw'}
                    boxShadow="base"
                    my={12}
                  >
                    Explore more homes
                  </Button>
                </Link>
                <Link
                  href="/"
                  w={{ base: "100%", lg: "auto" }}
                  className="no-underline"
                >
                  <Button
                    className="dm-button-navy-outline"
                    w={{ base: "100%", lg: "300px" }}
                    maxW={'90vw'}
                    boxShadow="base"
                    mb={12}
                  >
                    Back to Home
                  </Button>
                </Link>
              </>
            )
            }

          </Flex>
          : reservationLoaded ?
            <Flex w={"100%"} h={'80vh'}
              justifyContent={"center"}
              alignItems={"center"}
              flexDirection={"column"}
            >
              <Text mb={6}>No Matching Reservation Found</Text>
              <Flex gap={4} flexDirection={{ base: 'column', md: 'row' }} w={{ base: "100%", lg: "auto" }}>
                <Link
                  href="/trips"
                  w={{ base: "100%", lg: "auto" }}
                  className="no-underline"
                >
                  <Button
                    className="dm-button-orange-pill"
                    w={{ base: "100%", lg: "300px" }}
                    maxW={'90vw'}
                    boxShadow="base"
                  >
                    Back to My Trips
                  </Button>
                </Link>
                <Link
                  href='/'
                  target="_self"
                  w={{ base: "100%", lg: "auto" }}
                  className="no-underline"
                >
                  <Button
                    className="dm-button-navy-outline"
                    w={{ base: "100%", lg: "300px" }}
                    maxW={'90vw'}
                    boxShadow="base"
                    onClick={() => window.open('/', '_self')}
                  >
                    Start a New Search
                  </Button>
                </Link>
              </Flex>
            </Flex>
            :
            <Flex w={'100%'} h={'90vh'} justifyContent={'center'} alignItems={'center'}>
              <Spinner />
            </Flex>
        }
      </Flex>


    </>
  );
};

export { PreArrivalGuide };

