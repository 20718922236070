import {
  Box, Button, Flex,
  Image,
  Link,
  Modal,
  ModalBody, ModalCloseButton, ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure
} from "@chakra-ui/react";
import { differenceInDays } from "date-fns";
import { utcToZonedTime } from 'date-fns-tz';
import * as React from "react";
import { formatTime } from "../../helpers/helpers";
import { DogAgreementPortal } from "./DogAgreementPortal";
import { GuestAgreements } from "./GuestAgreements";
import { HowThisHomeWorks } from "./HowThisHomeWorks";
import { RebookCalendar } from "./RebookCalendar";
import { ReviewModal } from "./ReviewModal";
import { ShareWidget } from "./ShareWidget";

import { FiExternalLink } from 'react-icons/fi';

type Props = {
  reservationData: any;
};

const PastReservationWidget: React.FC<Props> = ({
  reservationData
}) => {

  // Set Time Zone To Eastern Time
  const ET = 'America/New_York';

  // Calculation of Next Year's Checkin Dates for Rebook Form
  // Approximately 1 Year in the Future, but Adjusted to be Saturday - Saturday
  let today = new Date();
  let daysUntilNextSaturday = (6 - today.getDay() + 7) % 7;
  let nextYearCheckIn = new Date();
  nextYearCheckIn.setDate(today.getDate() + daysUntilNextSaturday + 366);
  let nextYearCheckOut = new Date();
  nextYearCheckOut.setDate(today.getDate() + daysUntilNextSaturday + 373);

  // Formatting of Checkin and Checkout Times for Display on Reservation Widget
  let checkInTimeFormatted = formatTime(reservationData.checkinTime);
  let checkOutTimeFormatted = formatTime(reservationData.checkoutTime)

  const formattedCheckinDateNoTimeZone = reformatDateString(reservationData.checkinDate)
  const formattedCheckoutDateNoTimeZone = reformatDateString(reservationData.checkoutDate)

  // Assuming reservationData.checkoutDate is in 'YYYY-MM-DD' format
  // and reservationData.checkoutTime is assumed to be at the end of the checkout day, consider it in calculation if necessary
  const checkoutAtET = utcToZonedTime(new Date(`${reservationData.checkoutDate}T00:00:00`), ET); // Assuming start of checkout day in ET
  const todayET = utcToZonedTime(new Date(), ET); // Today's date in ET

  const daysAfterCheckout = differenceInDays(todayET, checkoutAtET);

  let withinTwoWeeksAfterCheckout = daysAfterCheckout >= 0 && daysAfterCheckout <= 14;

  function reformatDateString(date) {
    const [year, month, day] = date.split('-');
    return `${Number(month)}/${Number(day)}/${year}`;
  }

  const { isOpen: isRebookOpen, onOpen: onRebookOpen, onClose: onRebookClose } = useDisclosure(); // Rebook Modal
  const { isOpen: isShareOpen, onClose: onShareClose } = useDisclosure(); // Share Trip Modal
  const { isOpen: isHomeGuideOpen, onClose: onHomeGuideClose } = useDisclosure(); // Home Guide Modal
  const { isOpen: isReviewOpen, onOpen: onReviewOpen, onClose: onReviewClose } = useDisclosure(); // Review Modal
  const { isOpen: isAgreementsOpen, onOpen: onAgreementsOpen, onClose: onAgreementsClose } = useDisclosure(); // Agreements Modal
  const { isOpen: isDogsOpen, onClose: onDogsClose } = useDisclosure(); // Add Dogs Modal

  const handleCloseAfterPayment = () => {
    onDogsClose();
    //console.log("Success")
  };

  return (
    <>
      <Flex
        direction={'column'}
        justifyContent={"stretch"}
        maxW={"688px"} mx={'16px'} w={'calc(100% - 32px)'}
        mb={{ base: 8, md: 12 }}
        boxShadow={"0px 5.1px 10.2px rgba(0,0,0,0.1)"}
        position={'relative'}
      >
        <Flex direction={{ base: 'column', lg: 'row' }} borderRadius={'6px'}>
          <Flex
            width={{ base: '100%', lg: '40%' }}
            bg="bg-accent"
            borderRadius={'6px'}
            className='gallery-container'
            display={{ base: 'flex', md: 'flex' }}
          >
            {/* Property Photo Gallery */}
            <Box width="100%" mx="auto" borderRadius={'6px'}>
              <Image src={reservationData.house.thumbnail || reservationData.house.images[0].link} objectFit="cover" h={'100%'} />
            </Box>
          </Flex>

          <Flex
            width={{ base: '100%', lg: '60%' }}
            bg={"gray.50"}
            borderRadius={'0px 6px 0px 0px'}
            justifyContent={"space-between"}
            flexDirection={'column'} gap={1} px={5} pb={4} pt={{ base: 8, md: 8 }}
            fontSize={{ base: '0.95em', md: '1em' }}
            borderBottom={'solid 1px #DDD'}
          >
            {/* Basic Reservation Info */}
            <Text><b>Your trip to {reservationData.house.town}, MA</b></Text>
            <Text><b>Res #: </b>{reservationData.reservationNumber}</Text>
            <Text><b>Check-in: </b>{formattedCheckinDateNoTimeZone} {checkInTimeFormatted}</Text>
            <Text><b>Check-out: </b>{formattedCheckoutDateNoTimeZone} {checkOutTimeFormatted}</Text>
            {/*<Link onClick={onAgreementsOpen} pt={3} fontWeight={'bold'} textAlign={'center'} w={'100%'}>View Guest Agreement</Link>*/}

            <Link
              href={reservationData.house.listingLink}
              target={"_blank"}
              w={{ base: "100%", lg: "auto" }} mt={3}
              className="view-property-listing-link"
            >
              <Text fontSize={'14'}>#{reservationData.house.houseNumber}{<Text as="span" mx={1} color={'dmOrange.500'}> \ </Text>}{reservationData.house.name}</Text>
              <Flex fontSize={'14'} fontWeight={"700"} mb={{ base: 4, md: 6 }} pt={1} alignItems={"center"}>
                <Box pr={1}>View Property Listing</Box>
                <FiExternalLink color="black" />
              </Flex>
            </Link>

          </Flex>
        </Flex>

        <Flex className='button-container' flexWrap={'wrap'}
          gap={{ base: 1, md: 2 }} justifyContent={'space-between'}
          px={{ base: 4, md: 4 }} pb={4} pt={{ base: 0, md: 4 }}
        >
          <Button
            className="dm-button-navy-pill-small guest-agreement-reservation-widget" boxShadow="base" size={'sm'} whiteSpace={'wrap'} flex={1}
            onClick={onAgreementsOpen} py={{ base: 6, md: 6 }} minW={'90px'} fontSize={{ base: '0.85em', md: '1em' }} opacity={'0.75'}
          >
            Guest Agreement
          </Button>

          {/* Allow Rebook if House Still Active */}
          {reservationData.house.activeListing &&
            <Button
              className="dm-button-navy-pill-small rebook-reservation-widget" boxShadow="base" size={'sm'} whiteSpace={'wrap'} flex={1}
              onClick={onRebookOpen} py={{ base: 6, md: 6 }} minW={'90px'} fontSize={{ base: '0.85em', md: '1em' }} opacity={'0.75'}
              display={{ base: 'flex', md: 'flex' }}
            >
              Rebook
            </Button>
          }

          {/* Allow Review if within 2 weeks of checkout */}
          {withinTwoWeeksAfterCheckout &&
            <Button
              className="dm-button-navy-pill-small review-reservation-widget" boxShadow="base" size={'sm'} whiteSpace={'wrap'} flex={1}
              onClick={onReviewOpen} py={{ base: 6, md: 6 }} minW={'90px'} fontSize={{ base: '0.85em', md: '1em' }} opacity={'0.75'}
              display={{ base: 'flex', md: 'flex' }}
            >
              Review
            </Button>
          }

          <Button
            className="dm-button-navy-pill-small explore-reservation-widget" boxShadow="base" size={'sm'} whiteSpace={'wrap'} flex={1}
            onClick={() => window.open('/')} py={{ base: 6, md: 6 }} minW={'90px'} fontSize={{ base: '0.85em', md: '1em' }} opacity={'0.75'}
            display={{ base: 'flex', md: 'flex' }}
          >
            Explore More Homes
          </Button>
        </Flex>
      </Flex>

      <RebookCalendar
        modalInitiateOpen={isRebookOpen}
        reservationData={reservationData}
        onModalClose={onRebookClose}
      />

      <ShareWidget
        modalInitiateOpen={isShareOpen}
        reservationData={reservationData}
        onModalClose={onShareClose}
      />

      <ReviewModal
        modalInitiateOpen={isReviewOpen}
        reservationData={reservationData}
        onReviewClose={onReviewClose}
      />

      <Modal isOpen={isHomeGuideOpen} onClose={onHomeGuideClose} size={{ base: 'full', md: '6xl' }}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>How This Home Works</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <HowThisHomeWorks
              reservationData={reservationData}
            />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="dmNavy" onClick={onHomeGuideClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isAgreementsOpen} onClose={onAgreementsClose} size={{ base: 'full', md: '6xl' }}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Guest Agreement</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <GuestAgreements reservationNumber={reservationData.reservationNumber} rebook={reservationData.rebook} pricingType={reservationData.pricingType}
              paymentSchedule={reservationData.paymentSchedule} />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="dmNavy" onClick={onAgreementsClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isDogsOpen} onClose={onDogsClose} size={{ base: 'full', md: '6xl' }}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Dog Guests</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <DogAgreementPortal reservationData={reservationData} onSuccessfulPayment={handleCloseAfterPayment} />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="dmNavy" onClick={onDogsClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

    </>
  );
};

export { PastReservationWidget };

