import React from 'react';

interface HouseData {
  address: string;
  amenities: [];
  bathrooms: number;
  bedrooms: number;
  description: string;
  favorite: boolean;
  guestyHouseId: string;
  houseNumber: string;
  latitude: string;
  longitude: string;
  name: string;
  neighborhood: string;
  occupancy: number;
  pictures: any[];
  price: number;
  rating: number;
  rawReviews: any[];
  reviews: number;
  tags: any[],
  thumbnail: any;
  title: string;
  town: string;
  activeListing: boolean;
  quoteNumber: string;
}

interface SearchResultsContextProps {
  searchResults: any[];
  setSearchResults: React.Dispatch<React.SetStateAction<any[]>>;
  flexSearchResults: any[];
  setFlexSearchResults: React.Dispatch<React.SetStateAction<any[]>>;
  totalResults: number | null;
  setTotalResults: React.Dispatch<React.SetStateAction<number | null>>;
  queryOffset: number | null;
  setQueryOffset: React.Dispatch<React.SetStateAction<number | null>>;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  bufferLoading: boolean;
  setBufferLoading: React.Dispatch<React.SetStateAction<boolean>>;
  contextDateRange: any[];
  setContextDateRange: React.Dispatch<React.SetStateAction<any[]>>;
  guests: number | null;
  setGuests: React.Dispatch<React.SetStateAction<number | null>>;
  dogs: boolean;
  setDogs: React.Dispatch<React.SetStateAction<boolean>>;
  houseData: HouseData | null;
  setHouseData: React.Dispatch<React.SetStateAction<HouseData | null>>;
  houseCalendar: null;
  setHouseCalendar: React.Dispatch<React.SetStateAction<null>>;
  priceSearch: boolean;
  setPriceSearch: React.Dispatch<React.SetStateAction<boolean>>;
  maxSearchPriceValue: number | null;
  setMaxSearchPriceValue: React.Dispatch<React.SetStateAction<number | null>>;
  minSearchPriceValue: number | null;
  setMinSearchPriceValue: React.Dispatch<React.SetStateAction<number | null>>;
  pathingFilterSettings: any;
  setPathingFilterSettings: React.Dispatch<React.SetStateAction<any>>;
  searchFilterOptions: any;
  setSearchFilterOptions: React.Dispatch<React.SetStateAction<any>>;
  userFavorites: any[];
  setUserFavorites: React.Dispatch<React.SetStateAction<any[]>>;
  favoritesLoading: boolean;
  setFavoritesLoading: React.Dispatch<React.SetStateAction<boolean>>;
  numFavorites: number | null;
  setNumFavorites: React.Dispatch<React.SetStateAction<number | null>>;
  userEmail: string | null;
  setUserEmail: React.Dispatch<React.SetStateAction<string | null>>;
  emailLoading: boolean;
  setEmailLoading: React.Dispatch<React.SetStateAction<boolean>>;
  globalCheckIns: any[];
  setGlobalCheckIns: React.Dispatch<React.SetStateAction<any[]>>;
  globalCalendarLoading: boolean;
  setGlobalCalendarLoading: React.Dispatch<React.SetStateAction<boolean>>;
  searchWithDates: boolean;
  setSearchWithDates: React.Dispatch<React.SetStateAction<boolean>>;
  boundingBox: Record<string, any> | null;
  setBoundingBox: React.Dispatch<React.SetStateAction<Record<string, any> | null>>;
  boundingBoxMoved: boolean;
  setBoundingBoxMoved: React.Dispatch<React.SetStateAction<boolean>>;
  triggerSearch: number | null;
  setTriggerSearch: React.Dispatch<React.SetStateAction<number | null>>;
  mapAreaSearch: boolean;
  setMapAreaSearch: React.Dispatch<React.SetStateAction<boolean>>;
  town: string | null;
  setTown: React.Dispatch<React.SetStateAction<string | null>>;
  mapDisplayOnLoad: boolean | null;
  setMapDisplayOnLoad: React.Dispatch<React.SetStateAction<boolean>>;
  randomResults: boolean | null;
  setRandomResults: React.Dispatch<React.SetStateAction<boolean>>;
  activeQuoteNumber: string | null;
  setActiveQuoteNumber: React.Dispatch<React.SetStateAction<string | null>>;
}

const defaultContext: SearchResultsContextProps = {
  searchResults: [],
  setSearchResults: () => { },
  flexSearchResults: [],
  setFlexSearchResults: () => { },
  totalResults: null,
  setTotalResults: () => { },
  queryOffset: 0,
  setQueryOffset: () => { },
  loading: true,
  setLoading: () => { },
  bufferLoading: true,
  setBufferLoading: () => { },
  contextDateRange: [],
  setContextDateRange: () => { },
  guests: null,
  setGuests: () => { },
  dogs: false,
  setDogs: () => { },
  houseData: null,
  setHouseData: () => { },
  houseCalendar: null,
  setHouseCalendar: () => { },
  priceSearch: null,
  setPriceSearch: () => { },
  maxSearchPriceValue: null,
  setMaxSearchPriceValue: () => { },
  minSearchPriceValue: null,
  setMinSearchPriceValue: () => { },
  pathingFilterSettings: {},
  setPathingFilterSettings: () => { },
  searchFilterOptions: {},
  setSearchFilterOptions: () => { },
  userFavorites: [],
  setUserFavorites: () => { },
  favoritesLoading: true,
  setFavoritesLoading: () => { },
  numFavorites: null,
  setNumFavorites: () => { },
  userEmail: null,
  setUserEmail: () => { },
  emailLoading: true,
  setEmailLoading: () => { },
  globalCheckIns: [],
  setGlobalCheckIns: () => { },
  globalCalendarLoading: true,
  setGlobalCalendarLoading: () => { },
  searchWithDates: false,
  setSearchWithDates: () => { },
  boundingBox: null,
  setBoundingBox: () => { },
  boundingBoxMoved: false,
  setBoundingBoxMoved: () => { },
  triggerSearch: null,
  setTriggerSearch: () => { },
  mapAreaSearch: false,
  setMapAreaSearch: () => { },
  town: null,
  setTown: () => { },
  mapDisplayOnLoad: true,
  setMapDisplayOnLoad: () => { },
  randomResults: false,
  setRandomResults: () => { },
  activeQuoteNumber: null,
  setActiveQuoteNumber: () => { },
};

export const SearchResultsContext = React.createContext<SearchResultsContextProps>(defaultContext);
