import {
  Box,
  Container,
  Flex,
  Icon, Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent, ModalOverlay,
  SimpleGrid,
  Stack,
  Text,
  useBreakpointValue, useDisclosure,
  useOutsideClick,
  useToast
} from "@chakra-ui/react";
import { SignedIn, SignedOut, UserProfile, useClerk, useUser } from "@clerk/clerk-react";
import { useEffect, useRef, useState } from 'react';
import { FiBriefcase, FiCalendar, FiHeart, FiHelpCircle, FiMoreHorizontal, FiUser } from 'react-icons/fi';
import { useLocation, useNavigate } from 'react-router-dom';
import { GuestServicesPopOver } from "../components/guestportal/GuestServicesPopOver";
import HelpModal from '../components/HelpModal';
import config from "../config";
import { useTrips } from "../contexts/TripContext";
import { fetchSupportManager } from "../helpers/guestportalfetch";
import footerBackground from '../media/footerbackground.png';
import { ReactComponent as DelMarLogoWhiteText } from "../media/logowhitetext.svg";

const baseUrl = config.baseUrl;

export default function LargeWithNewsletter() {

  const { selectedTrip, isTripsContextLoaded } = useTrips();

  const { openSignIn, signOut } = useClerk();
  const toast = useToast();
  const signOutToast = () => {
    toast({
      title: "Signed out",
      description: "You are now logged out.",
      status: "success",
      duration: 3000,
      isClosable: true,
      position: "top",
    });
  };

  let displayFooter = true;
  let displayBottomMenu = true;
  let guestPortal = false;

  const { pathname } = useLocation();

  if (pathname.includes("/agreements")) {
    displayFooter = false
  }

  if (pathname === "/" || pathname === "/favorites" || pathname === '/properties' || pathname === '/listings' || pathname.includes('/trips') || pathname === '/store') {
    displayBottomMenu = false
  }

  if (['/trips', '/store', '/places', '/discounts'].some(path => pathname.startsWith(path))) {
    guestPortal = true;
  }

  const breakpointValue = useBreakpointValue({ base: "mobile", md: "desktop" });
  const navigate = useNavigate();

  const { isOpen: isHelpModalOpen, onOpen: onOpenHelpModal, onClose: onCloseHelpModal } = useDisclosure();
  const { isOpen: isProfileModalOpen, onOpen: onProfileModalOpen, onClose: onProfileModalClose } = useDisclosure();
  const { isOpen: isGuestHelpModalOpen, onOpen: onOpenGuestHelpModal, onClose: onCloseGuestHelpModal } = useDisclosure();

  // Custom dropdown state and handlers
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef();

  useOutsideClick({
    ref: dropdownRef,
    handler: () => setIsDropdownOpen(false),
  });

  const handleMoreClick = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  // Support Manager Data
  const { isSignedIn, user } = useUser();

  interface Manager {
    name: string;
    title: string;
    avatar: string;
  }
  const [manager, setManager] = useState<Manager | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      if (user && isSignedIn) {
        let userEmail = user.primaryEmailAddress.emailAddress;
        try {
          const response = await fetchSupportManager({ tenantEmail: userEmail });
          const managerInfo = await response.json();
          setManager(managerInfo[0]);
        } catch (error) {
          console.error('Error:', error);
        }
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    displayBottomMenu || breakpointValue !== 'mobile' ?
      <Box
        bgImage={`url(${footerBackground})`}
        bgSize={"cover"}
        bgPosition={"center"}
        color={'gray.200'}
        display={{ base: 'none', md: displayFooter ? "block" : "none" }}
      >
        <Container as={Stack} maxW={"6xl"} pt={{ base: 4, md: 6 }} pb={{ base: 3, md: 4 }}>
          <SimpleGrid templateColumns={{ sm: "1fr", md: "1fr" }} spacing={8}>
            <Stack spacing={{ base: 1, md: 4 }}>
              <Box height={{ base: "20px", md: 31 }}>
                <DelMarLogoWhiteText color={'gray.700'} height={'100%'} width={'100%'} />
              </Box>
              <Text fontSize={{ base: 'xs', md: 'sm' }} textAlign={'center'}>
                <Link href={'https://thisisdelmar.com'} target='blank'>Luxury Cape Cod Vacation Rentals.</Link><br />
                © 2025 Del Mar Vacations. All rights reserved.
              </Text>
            </Stack>
          </SimpleGrid>
        </Container>
      </Box>
      :
      <Flex bgColor={"white"} width={'100vw'} height="70px"
        display={{ base: "flex", md: "none" }} position="fixed"
        bottom="0" left="0" zIndex="500" justifyContent={'space-between'}
        px={0} alignItems={'center'} boxShadow={'dmShadow'}
      >

        {/* Icon 1: My Trips */}
        <Box textAlign="center" flex={1}
          onClick={() => { window.open(`${baseUrl}/trips/`, '_blank') }}
        >
          <Icon as={FiBriefcase} boxSize={6} />
          <Text fontSize="xs">My Trips</Text>
        </Box>

        {/* Icon 2: Favorites */}
        <Box textAlign="center" flex={1} color={pathname === '/favorites' ? '#e95037' : '#0f2238'}
          onClick={() => navigate('/favorites')}
        >
          <Icon as={FiHeart} boxSize={6} />
          <Text fontSize="xs">Favorites</Text>
        </Box>

        {/* Icon 3: Help */}
        <Box textAlign="center" flex={1}
          onClick={guestPortal ? onOpenGuestHelpModal : onOpenHelpModal}
        >
          <Icon as={FiHelpCircle} boxSize={6} />
          <Text fontSize="xs">Help</Text>
        </Box>

        {/* Icon 4 - Not in Guest Portal: Profile */}
        {guestPortal ? (
          <>
            <Box textAlign="center" flex={1}
              onClick={() => { navigate(`/trips/${selectedTrip.reservationNumber}?open=rebook`) }}
            >
              <Icon as={FiCalendar} boxSize={6} />
              <Text fontSize="xs">Rebook</Text>
            </Box>
          </>
        ) : (
          <>
            <SignedIn>
              <Box textAlign="center" flex={1}
                onClick={onProfileModalOpen}
              >
                <Icon as={FiUser} boxSize={6} />
                <Text fontSize="xs">Profile</Text>
              </Box>
            </SignedIn>
            <SignedOut>
              <Box textAlign="center" flex={1}
                onClick={() => { openSignIn(); }}
              >
                <Icon as={FiUser} boxSize={6} />
                <Text fontSize="xs">Sign In</Text>
              </Box>
            </SignedOut>
          </>
        )
        }

        {/* Custom Dropdown Button: More */}
        <Box textAlign="center" flex={1} ref={dropdownRef} onClick={handleMoreClick}>
          <Icon as={FiMoreHorizontal} boxSize={6} />
          <Text fontSize="xs">More</Text>
          {isDropdownOpen && (
            <Flex
              direction={'column'}
              position="absolute"
              bottom="60px" // Adjust this value to position the dropdown correctly
              right="0"
              bg="white"
              boxShadow={'dmShadow'}
              p={4}
              gap={3}
              zIndex="dropdown"
              borderRadius="md"
            >
              <Flex as={'button'} direction={"column"} gap={0} alignItems={"start"}
                onClick={() => window.open(
                  "https://delmarvacations.myshopify.com/collections/products",
                  "_blank"
                )}
              >
                <Text fontWeight={'bold'}>Del Merch</Text>
                <Text fontSize={"12px"}>
                  Gifts and style for your wardrobe
                </Text>
              </Flex>
              {selectedTrip &&
                <Flex as={'button'} direction={"column"} gap={0} alignItems={"start"} w={'100%'} onClick={() => navigate(`/store/${selectedTrip.reservationNumber}`)}>
                  <Text fontWeight={'bold'}>Del Market</Text>
                  <Text fontSize={"12px"}>
                    Amenities for your vacation
                  </Text>
                </Flex>
              }
              <SignedIn>
                <Flex as={'button'} direction={"column"} gap={0} alignItems={"start"} w={'100%'}
                  onClick={() => {
                    signOut();
                    signOutToast();
                  }}
                >
                  <Text>Sign Out</Text>
                </Flex>
              </SignedIn>
            </Flex>
          )}
        </Box>

        <HelpModal
          isOpen={isHelpModalOpen}
          onClose={onCloseHelpModal}
        />

        <Modal
          isOpen={isGuestHelpModalOpen}
          onClose={() => void 0}
          isCentered
          size={"lg"}
        >
          <ModalOverlay
            bg="blackAlpha.700"
            backdropFilter="blur(10px) hue-rotate(90deg)"
          />
          <ModalContent borderRadius="none" mx="0">
            <ModalCloseButton size="lg" onClick={onCloseGuestHelpModal} />
            <ModalBody padding="0">
              {isTripsContextLoaded && selectedTrip && user?.primaryEmailAddress &&
                <GuestServicesPopOver
                  onPopoverClose={onCloseGuestHelpModal}
                  userEmail={user?.primaryEmailAddress?.emailAddress}
                  contactOwner={manager}
                  houseNumber={selectedTrip.house.houseNumber}
                  reservationNumber={selectedTrip.reservationNumber}
                />
              }
            </ModalBody>
          </ModalContent>
        </Modal>

        <Modal isOpen={isProfileModalOpen} onClose={onProfileModalClose}>
          <ModalOverlay />
          <ModalContent>
            <UserProfile />
          </ModalContent>
        </Modal>

      </Flex>
  );
}
