import { ChevronRightIcon } from "@chakra-ui/icons";
import {
  Alert,
  AspectRatio,
  Box,
  Button,
  Card,
  CardBody,
  Divider,
  Flex,
  FormControl,
  Grid,
  Icon,
  Image,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  Tooltip,
  useBreakpointValue,
  useDisclosure
} from "@chakra-ui/react";
import { useUser } from "@clerk/clerk-react";
import { format } from "date-fns";
import { EditIcon } from "lucide-react";
import * as React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { SearchResultsContext } from "../../../contexts/SearchResultsContext";
import { MAX_END_DATE } from "../../../helpers/constants";
import { fetchHouseCalendar, fetchHouseDetails } from "../../../helpers/fetch";
import {
  ScrollToTop,
  decrementGuestInput,
  formatFriendlyDate,
  formatFriendlyDateShorter,
  getChannelDisplayName,
  incrementGuestInput,
  parseDateToLocalTime
} from "../../../helpers/helpers";
import {
  addQuoteItem,
  checkDateRange,
  checkQuoteParams,
  getActiveQuoteVariable,
} from "../../../helpers/quoteService";
import {
  fetchRebookReservationQuote
} from "../../../helpers/reservationService";
import HouseMap from "../../HouseMap";
import InquiryModal from "../../InquiryModal";
import { Details } from "./Details";
import { NotAcceptedBlock } from "./NotAccepted";
import { PhotoGallery } from "./PhotoGallery";
import { SkeletonBlock } from "./Skeleton";
import { TopBar } from "./Topbar";

const ListingBlock = () => {
  const navigate = useNavigate();

  // Get House Number from URL (e.g., book.thisisdelmar.com/20001)
  let { houseNumber } = useParams();

  // All Contexts
  const {
    guests,
    setGuests,
    houseData,
    setHouseData,
    houseCalendar,
    setHouseCalendar,
    globalCheckIns,
    activeQuoteNumber,
    setActiveQuoteNumber,
  } = React.useContext(SearchResultsContext);

  // Admin Query Handling
  const user = useUser();
  const adminDomains = ["darrenhe.com", "cloudthoughts.com", "thisisdelmar.com"];
  const userIsAdmin =
    user?.isSignedIn &&
    adminDomains.some((domain) =>
      user.user?.primaryEmailAddress?.emailAddress?.includes(domain)
    );

  // Handling for Params in Listing Link
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  // https://booking-api-docs.guesty.com/docs/google#supported-variables
  const paramstartdate = queryParams.get("startdate");
  const paramenddate = queryParams.get("enddate");
  const linkedGuests = Number(queryParams.get("guests") || 2);

  React.useEffect(() => {
    const linkedContextDateRange = [
      parseDateToLocalTime(paramstartdate),
      parseDateToLocalTime(paramenddate),
    ].filter(Boolean);

    if (
      linkedContextDateRange &&
      checkDateRange(paramstartdate, paramenddate)
    ) {
      setListingStartDate(linkedContextDateRange[0]);
      setListingEndDate(linkedContextDateRange[1]);
    }

    if (linkedGuests) {
      setGuests(linkedGuests);
    } else {
      setGuests(2);
    }

    // Admin Quote Handling
    if (userIsAdmin && !activeQuoteNumber) {
      const localQuoteVariable = getActiveQuoteVariable();
      setActiveQuoteNumber(localQuoteVariable);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userIsAdmin, paramstartdate, paramenddate]);

  /// Rebook Mode Variables ///
  const [rebookMode, setRebookMode] = React.useState(false); // Based on Params and Local Storage Item
  const [rebookParameterReservation, setRebookParameterReservation] = React.useState(null); // From Param
  const [rebookDiscountEligible, setRebookDiscountEligible] = React.useState(false); // Based on Response from Backend

  React.useEffect(() => {
    // Extracts URL parameters and stores relevant data in localStorage
    const initializeAttributionFromUrl = () => {
      const urlParams = new URLSearchParams(window.location.search);
      const attribution = urlParams.get("source");
      const rebookMode = urlParams.get("rebook");

      if (attribution) localStorage.setItem("dm-attribution", attribution);
      if (rebookMode && rebookMode !== 'null') {
        localStorage.setItem("dm-rebook-mode", Date.now().toString());
        localStorage.setItem("dm-rebook-reservation", rebookMode);
      }
    };

    // Checks if rebook mode is active based on the time elapsed since last rebook
    const isRebookModeActive = () => {
      const rebookTimestamp = parseInt(localStorage.getItem("dm-rebook-mode"), 10);
      return (
        rebookTimestamp &&
        Date.now() - rebookTimestamp < 7 * 24 * 60 * 60 * 1000 // 1 week in milliseconds
      );
    };

    // Initialization and conditional logic
    initializeAttributionFromUrl();
    const rebookValue = localStorage.getItem("dm-rebook-reservation");
    if (rebookValue && rebookValue !== 'null') {
      setRebookParameterReservation(rebookValue);
    }

    if (rebookValue && isRebookModeActive()) {
      setRebookMode(true);
    } else {
      setRebookMode(false);
    }
  }, [location]);

  /// END Rebook Mode Variables ///

  //// Datepicker and Guests ////
  const [dateRange, setDateRange] = React.useState([null, null]);

  function clearDates() {
    setDateRange([null, null]);
    setListingStartDate(null);
    setListingEndDate(null);
    setDatePickerKey(datePickerKey + 2);
    setDatesToGrayOut(datesNotOkayToCheckIn);
    setDatesToGrayOutEpoch(datesNotOkayToCheckInEpoch);
  }

  // Determine if the date picker should be inline based on screen size
  const isInline = useBreakpointValue({ base: true, md: false });

  //// END Datepicker and Guests ////

  //// Mobile Booking Menu Scroll ////
  const bookingSectionRef = React.useRef(null);

  //// END Mobile Booking Menu Scroll ////

  //// Submit for Reservation Quote ////
  const [guestyData, setGuestyData] = React.useState(null);
  const [listingStartDate, setListingStartDate] = React.useState(null);
  const [listingEndDate, setListingEndDate] = React.useState(null);
  const [nightlyRates, setNightlyRates] = React.useState(null);
  const [totalPrice, setTotalPrice] = React.useState(null);
  const [nightlyRatesPlusCleaning, setNightlyRatesPlusCleaning] = React.useState(null);
  const [numberOfNights, setNumberOfNights] = React.useState(null);

  const [errorMessage, setErrorMessage] = React.useState(null);
  const [addedToQuote, setAddedToQuote] = React.useState(false);
  const [discount, setDiscount] = React.useState(null);
  const [isSubmittingForQuote, setIsSubmittingForQuote] = React.useState(false);

  const [pricingLoaded, setPricingLoaded] = React.useState(false);
  const [channelSubTotalPrice, setChannelSubTotalPrice] = React.useState(null);
  const [subTotalPrice, setSubTotalPrice] = React.useState(null);
  const [subTotalPriceNightly, setSubTotalPriceNightly] = React.useState(null);
  const [cleaningFee, setCleaningFee] = React.useState(null);

  // Reservation Quote Components from New Create Reservation Quote
  const [originalGuestyQuote, setOriginalGuestyQuote] = React.useState(null);
  const [pricingBreakdown, setPricingBreakdown] = React.useState(null);
  const [reservationQuote, setReservationQuote] = React.useState(null);

  const addToQuote = async () => {
    try {
      if (activeQuoteNumber && listingStartDate && listingEndDate && guests) {
        const formattedStartDate = format(listingStartDate, "yyyy-MM-dd");
        const formattedEndDate = format(listingEndDate, "yyyy-MM-dd");

        const quoteItemData = {
          houseNumber: houseNumber,
          checkinDate: formattedStartDate,
          checkoutDate: formattedEndDate,
          guests: guests,
        };
        await addQuoteItem(activeQuoteNumber, quoteItemData);
        setAddedToQuote(true);
      }
    } catch (error) {
      console.error("Error adding to quote:", error);
    }
  };

  const handleSubmit = async () => {
    setIsSubmittingForQuote(true);

    try {
      if (!houseData) throw new Error("House data is not available.");
      if (!guests) {
        setErrorMessage("Please select guests.");
        return;
      }
      if (!listingStartDate || !listingEndDate) {
        setErrorMessage("Please select dates.");
        return;
      }

      if (totalPrice && reservationQuote) {
        navigate(
          `/${houseNumber}/payment`,
          {
            state: { reservationQuote, pricingBreakdown, originalGuestyQuote }
          }
        );

      } else {
        throw new Error("Error with reservation quote. Please reload and try again.");
      }
    } catch (error) {
      console.error("Error:", error.message);
      setErrorMessage(error.message);
      clearDates();

      // Clear the URL query parameters
      const searchParams = new URLSearchParams(location.search);
      ["startdate", "enddate"].forEach((param) => searchParams.delete(param));
      navigate({ search: searchParams.toString() }, { replace: true });
    } finally {
      setIsSubmittingForQuote(false);
    }
  };

  // Custom Rebook Pricing Quote Retrieval
  React.useEffect(() => {
    async function getNewQuote() {
      try {
        setIsSubmittingForQuote(true);
        setPricingLoaded(false);

        setPricingBreakdown(null);
        setReservationQuote(null);

        const queryParams = {
          checkInDateLocalized: format(listingStartDate, "yyyy-MM-dd"),
          checkOutDateLocalized: format(listingEndDate, "yyyy-MM-dd"),
          listingId: houseData.guestyHouseId,
          guestsCount: guests,
          rebookCode: rebookParameterReservation,
        };

        //console.log('getting newQuote with params:', queryParams)

        if (!checkQuoteParams(queryParams)) {
          throw new Error("Invalid quote parameters");
        }

        await fetchRebookReservationQuote(
          queryParams,
          setErrorMessage,
          setPricingBreakdown,
          setReservationQuote,
          setOriginalGuestyQuote
        );
      } catch (error) {
        setErrorMessage(error.message);
      } finally {
        setIsSubmittingForQuote(false); // Ensure this always runs
      }
    }

    if (
      houseData &&
      guests &&
      listingStartDate &&
      listingEndDate
    ) {
      setIsSubmittingForQuote(true);
      getNewQuote();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    houseData,
    listingStartDate,
    listingEndDate,
    rebookMode,
    rebookParameterReservation
  ]);

  React.useEffect(() => {
    //console.log('pricingBreakdown', pricingBreakdown);
    //console.log('reservationQuote', reservationQuote);
    //console.log('originalGuestyQuote', originalGuestyQuote);

    if (reservationQuote) {
      setSubTotalPrice(reservationQuote.subTotalPrice);
      setSubTotalPriceNightly(reservationQuote.subTotalPriceNightly);
      setTotalPrice(reservationQuote.totalPrice);
      setChannelSubTotalPrice(reservationQuote.channelSubTotalPrice);
      setRebookDiscountEligible(reservationQuote.rebookDiscountEligible);
      setNightlyRates(reservationQuote.nightlyRatesFinal);
      setDiscount(reservationQuote.rebookDiscountAmount);
      setCleaningFee(reservationQuote.cleaningFee);
      setNightlyRatesPlusCleaning(reservationQuote.nightlyRatesPlusCleaning);
      setNumberOfNights(reservationQuote.numberOfNights);
      setPricingLoaded(true);
    }

  }, [pricingBreakdown, reservationQuote, originalGuestyQuote]);


  React.useEffect(() => {
    if (reservationQuote) {
      setReservationQuote(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listingStartDate, listingEndDate]);

  //// End Submit for Reservation Quote ////


  //// Disable Full Screen Image View on Mobile ////
  const [isMobile, setIsMobile] = React.useState(window.innerWidth < 768);

  React.useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  //// END Disable Full Screen Image View on Mobile ////


  //// Get House Data from Backend ////

  const [houseLoading, setHouseLoading] = React.useState(true);

  const fetchHouseData = async () => {
    setHouseLoading(true);
    try {
      const params = { houseNumber: houseNumber };
      const response = await fetchHouseDetails(params);

      if (!response.ok) {
        throw new Error(`fetchHouseDetails Error! Status: ${response.status}`);
      }

      const returnedHouseData = await response.json();
      setHouseData(returnedHouseData.results);
    } catch (error) {
      console.error("Error fetching house data from DB:", error);
    } finally {
      setHouseLoading(false);
    }
  };

  React.useEffect(() => {
    fetchHouseData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [houseNumber]);

  const [calendarLoading, setCalendarLoading] = React.useState(true);
  const [datePickerKey, setDatePickerKey] = React.useState(0);

  const fetchHouseAvailability = async () => {
    try {
      //console.log("fetchHouseAvailability");
      const startDate = format(new Date(), "yyyy-MM-dd"); // Today's date
      const endDate = MAX_END_DATE; // End of 2025

      setCalendarLoading(true);

      const response = await fetchHouseCalendar(
        houseData.guestyHouseId,
        startDate,
        endDate
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const availabilityData = await response.json();
      //console.log("availabilityData", availabilityData);
      // Set the state or handle the data as needed
      //console.log("availabilityData", availabilityData)
      setHouseCalendar(availabilityData);
    } catch (error) {
      console.error("Error fetching house availability:", error);
    } finally {
      setCalendarLoading(false);
    }
  };

  React.useEffect(() => {
    if (houseData && houseData.guestyHouseId) {
      fetchHouseAvailability();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [houseData]);

  //// END Get House Data from Backend ////


  //// Modal Handlers ////
  const {
    isOpen: isReviewModalOpen,
    onOpen: onOpenReviewModal,
    onClose: onCloseReviewModal,
  } = useDisclosure({
    id: "review-modal",
  });

  const {
    isOpen: isBookingModalOpen,
    onOpen: onOpenBookingModal,
    onClose: onCloseBookingModal,
  } = useDisclosure({
    id: "booking-modal",
  });
  const {
    isOpen: isInquiryModalOpen,
    onOpen: onOpenInquiryModal,
    onClose: onCloseInquiryModal,
  } = useDisclosure({
    id: "inquiry-modal",
  });
  //// END Modal Handlers ////

  //// Date Picker Resize Handler ////
  const [monthsShown, setMonthsShown] = React.useState(
    window.innerWidth <= 767 ? 1 : 2
  );

  React.useEffect(() => {
    function handleResize() {
      setMonthsShown(window.innerWidth <= 767 ? 1 : 2);
    }

    window.addEventListener("resize", handleResize);

    // Cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  //// END Date Picker Resize Handler ////

  //// Guesty Calendar Handling ////

  const [bookedDates, setBookedDates] = React.useState([]);
  const [datesNotOkayToCheckIn, setDatesNotOkayToCheckIn] = React.useState([]);
  const [datesNotOkayToCheckInEpoch, setDatesNotOkayToCheckInEpoch] = React.useState([]);
  const [datesToGrayOut, setDatesToGrayOut] = React.useState([]);
  const [datesToGrayOutEpoch, setDatesToGrayOutEpoch] = React.useState([]);
  const [bookedAndNotOkayToCheckOutDates, setBookedAndNotOkayToCheckOutDates] = React.useState([]);
  const [currentlyShowing, setCurrentlyShowing] = React.useState("checkIn");
  const [datepickerIsOpen, setDatepickerIsOpen] = React.useState(true)
  //const [firstAvailableCheckIn, setFirstAvailableCheckIn] = React.useState(null);

  // This is to adjust the Guesty date data if needed to make sure it blanks out the right dates i the datepicker
  const hoursOffset = new Date().getTimezoneOffset() / 60;

  React.useEffect(() => {
    //console.log("houseCalendar", houseCalendar);

    if (houseCalendar) {
      // Get the Guesty data on initial page load (should only do this once, all other changes to datepicker will be done via state changes)
      const guestyDataRaw = houseCalendar;

      // Transform the data to get rid of fields we don't need and alter dates if needed, saves a COPY to be used for Check Outs later
      const guestyDataTransformed = transformGuestyData(
        guestyDataRaw,
        hoursOffset
      );
      setGuestyData([...guestyDataTransformed]);

      //console.log('guestyDataTransformed', guestyDataTransformed)
      //const firstAvailableDate = guestyDataTransformed.find(item => item.status === "available").startDate;

      //console.log(firstAvailableDate);

      // Save the dates that are booked to make them a different style/color in the datepicker
      const getBookedDates = guestyDataTransformed
        .filter((date) => date.status === "booked")
        .map((date) => Date.parse(date.startDate));
      setBookedDates(getBookedDates);

      // Add checkInOkay = true/false field to each date
      const guestyDataCheckInsProcessed = processCheckIns(
        guestyDataTransformed
      );

      // Create an array of all dates that have checkInOkay = false, then grab the startDate from each of them so it's just an array of dates for the datepicker
      const badCheckInDates = guestyDataCheckInsProcessed
        .filter((date) => !date.checkInOkay && date.startDate)
        .map((date) => date.startDate);

      const badCheckInDatesEpoch = guestyDataCheckInsProcessed
        .filter((date) => !date.checkInOkay && date.startDate)
        .map((date) => Date.parse(date.startDate));

      // Set that array of dates to be grayed out
      setDatesToGrayOut(badCheckInDates);
      setDatesToGrayOutEpoch(badCheckInDatesEpoch);

      // (And save that array of dates so we can gray them again after user clicks an end date)
      setDatesNotOkayToCheckIn(badCheckInDates);
      setDatesNotOkayToCheckInEpoch(badCheckInDatesEpoch);
      //console.log("datesNotOkayToCheckInEpoch INTIIAL", datesNotOkayToCheckInEpoch)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [houseCalendar]);

  let showInfo = true;

  function renderDayContents(day, date) {
    let tooltipText = "";
    const dateEpoch = Date.parse(date);
    const today = new Date();
    const lastDateAvailable = new Date(
      globalCheckIns[globalCheckIns.length - 1]
    );

    if (showInfo) {
      //console.log("datesToGrayOutEpoch", datesToGrayOutEpoch);
      showInfo = false;
    }

    if (currentlyShowing === "checkIn") {
      if (bookedDates.includes(dateEpoch)) {
        tooltipText = "Unavailable";
      } else if (dateEpoch > lastDateAvailable.getTime()) {
        tooltipText = "Booking season not open yet";
      } else if (datesToGrayOutEpoch.includes(dateEpoch)) {
        tooltipText = "No check-in";
      } else if (dateEpoch <= today.getTime()) {
        tooltipText = "Invalid check-in";
      } else {
        tooltipText = "Available for check-in";
      }
    } else if (currentlyShowing === "checkOut") {
      if (bookedAndNotOkayToCheckOutDates.includes(dateEpoch)) {
        tooltipText = "Unavailable";
      } else if (dateEpoch > lastDateAvailable.getTime()) {
        tooltipText = "Booking season not open yet";
      } else if (datesToGrayOutEpoch.includes(dateEpoch)) {
        tooltipText = "No check-out";
      } else if (dateEpoch <= today.getTime()) {
        tooltipText = "Invalid check-out";
      } else {
        tooltipText = "Available for check-out";
      }
    }

    return (
      <Tooltip
        hasArrow
        placement="top"
        label={tooltipText}
        aria-label={tooltipText}
      >
        <span>{day}</span>
      </Tooltip>
    );
  }

  // Trims down the Guesty data to only the parts we care about within the next year
  function transformGuestyData(guestyDataRaw, hoursOffset) {
    const lastDateAvailable = new Date(
      globalCheckIns[globalCheckIns.length - 1]
    );

    return (
      guestyDataRaw.data.days
        // Filter out dates that are past a year from now
        .filter(
          (day) => new Date(day.date).getTime() <= lastDateAvailable.getTime()
        )
        .map((day) => {
          let guestyDate = new Date(day.date);
          guestyDate.setHours(0);
          guestyDate.setMinutes(0);
          guestyDate.setSeconds(0);
          // If hoursOffset is greater than 0, add 1 day to all dates
          if (hoursOffset > 0)
            guestyDate = new Date(guestyDate.setDate(guestyDate.getDate() + 1));

          return {
            startDate: guestyDate,
            status: day.status,
            booked: day.status === "available" ? false : true,
            minDays: day.minNights,
            price: day.price,
            cta: day.cta,
            ctd: day.ctd,
          };
        })
    );
  }

  // Can only check in on a day that's *not* already book AND where CTA is false
  // AND if that date will have a valid Check Out date too
  function processCheckIns(guestyDataTransformed) {
    for (const date of guestyDataTransformed) {
      if (
        date.booked === false &&
        date.cta !== true &&
        processCheckOuts(date.startDate, guestyDataTransformed).length > 0
      ) {
        date.checkInOkay = true;
      } else {
        date.checkInOkay = false;
      }
    }

    return guestyDataTransformed;
  }

  function processCheckOuts(pickedDate, allDates) {
    const pickedDateRange = allDates.find(
      (range) => range.startDate.getTime() === pickedDate.getTime()
    );
    if (!pickedDateRange)
      throw new Error(
        `Date range not found for the picked date: ${pickedDate}`
      );

    // Sort allDates in ascending order of startDate
    allDates.sort((a, b) => a.startDate.getTime() - b.startDate.getTime());

    // Find the first startDate after the pickedDate that is booked
    const firstBookedDateRange = allDates.find(
      (range) =>
        range.booked === true &&
        range.startDate.getTime() > pickedDate.getTime()
    );
    const availableDates = [];

    for (const dateRange of allDates) {
      const startDate = dateRange.startDate;
      const diffTime = startDate.getTime() - pickedDate.getTime();
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      // If the start date of the range is not booked and is at least minDays in the future or is in the past, add it to the available dates
      if (
        dateRange.booked === false &&
        startDate.getTime() >= pickedDate.getTime() &&
        diffDays >= pickedDateRange.minDays &&
        dateRange.ctd !== true
      ) {
        // Stop adding to availableDates if the date is later than the first booked date
        if (
          firstBookedDateRange &&
          startDate.getTime() >= firstBookedDateRange.startDate.getTime()
        )
          break;

        availableDates.push(startDate);
      }
    }

    // Add the first booked startDate after the pickedDate as checkout on this date should be allowed
    if (
      firstBookedDateRange &&
      Math.ceil(
        (firstBookedDateRange.startDate.getTime() - pickedDate.getTime()) /
        (1000 * 60 * 60 * 24)
      ) >= pickedDateRange.minDays
    ) {
      availableDates.push(firstBookedDateRange.startDate);
    }

    //console.log(availableDates);
    return availableDates;
  }

  //// END Guesty Calendar Handling ////

  React.useEffect(() => {
    // Check if totalPrice is known (not null)
    if (totalPrice !== null) {
      setTotalPrice(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listingStartDate, listingEndDate]);

  React.useEffect(() => {
    if (errorMessage && errorMessage.includes("rate plans")) {
      // Clear the URL query parameters
      const searchParams = new URLSearchParams(location.search);
      if (searchParams.has("startdate") || searchParams.has("enddate")) {
        searchParams.delete("startdate");
        searchParams.delete("enddate");
        navigate({ search: searchParams.toString() }, { replace: true });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorMessage]);

  //const clickAwaySimulation = React.useRef(null)

  const bookingContent = (
    <Flex w={{ base: "100%", md: "100%" }} ref={bookingSectionRef}>
      {houseData && (
        <>
          {/* Floating Card */}
          <Flex
            borderRadius={8}
            height={"fit-content"}
            w="100%"
            p={2}
            position="sticky"
            top="100px"
            boxShadow={{ base: "none", md: "dmShadow" }}
            flexDirection="column"
          >
            {/* Price and Reviews */}
            <Flex
              flexDirection={{ base: "column", xl: "row" }}
              justifyContent={"space-between"}
              w="100%"
              p={{ base: 2, md: 4 }}
              gap="5px"
            >
              <Flex fontSize={"20px"} alignItems={"top"}>
                {subTotalPriceNightly && subTotalPriceNightly > 0 && !isInline ? (
                  <Text>${subTotalPriceNightly.toFixed(0)} avg per night</Text>
                ) : (
                  <Text>Book your trip</Text>
                )}
              </Flex>
              {/* Rating and Review Count */}
              {houseData.reviews > 0 && (
                <>
                  <Flex
                    className="listing-reviews-parent"
                    alignItems={"center"}
                    display={{ base: "none", md: "flex" }}
                  >
                    <Flex className="listing-rating">
                      {!isNaN(houseData.rating) && houseData.rating !== null
                        ? houseData.rating.toFixed(1)
                        : "0"}
                    </Flex>
                    <Image
                      src={"../media/ratingstar.png"}
                      alt="Home Rating"
                      objectFit={"contain"}
                      align={"left"}
                      width={"18px"}
                      height={"18px"}
                      className="listing-rating-star"
                      mx={1.5}
                    />
                    <Flex
                      className="listing-reviews"
                      fontSize={"14px"}
                      fontWeight={500}
                      pt={0.5}
                      as="u"
                      onClick={onOpenReviewModal}
                      sx={{ "&:hover": { cursor: "pointer" } }}
                    >
                      {!isNaN(houseData.reviews)
                        ? (houseData.reviews as number)
                        : 0}{" "}
                      Reviews
                    </Flex>
                  </Flex>
                </>
              )}
            </Flex>
            {/* End Price and Reviews */}

            {/* Datepicker Form */}
            <Flex
              flexDirection="column"
              w="100"
              px={2}
              pt={{ base: 1, md: 2 }}
              pb={4}
            >
              {/* Dates Input */}
              <FormControl
                h={{ base: "unset", md: "50px" }}
                w="100%"
                fontSize={16}
                borderColor={"black"}
                inputMode="none"
                border={{ base: "none", md: "1px" }}
              >
                <Flex
                  color={"black"}
                  height={"40px"}
                  w={"100%"}
                  h={"100%"}
                  className="mobile-menu-date-picker"
                >

                  {globalCheckIns && globalCheckIns.length > 0 && datepickerIsOpen && guestyData && (
                    <DatePicker
                      key={datePickerKey} // Add key to force re-render
                      placeholderText="Select Dates"
                      h={{ base: "500px", md: "100%" }}
                      disabled={calendarLoading}
                      isClearable={true}
                      selectsRange={true}
                      startDate={listingStartDate}
                      endDate={listingEndDate}
                      inline={isInline}
                      monthsShown={isInline ? 1 : 1}
                      openToDate={listingStartDate || guestyData.find(item => item.status === "available").startDate}
                      maxDate={guestyData.slice().reverse().find(item => item.status === "available").startDate}
                      onChange={(update) => {
                        setListingStartDate(update[0]);
                        setListingEndDate(update[1]);

                        // Gray out bad check in dates either: (1) on first load (when both are null) *or* (2) when both are NOT null
                        // (So the user can't click on a bad check in date when a date range is already selected)
                        const grayOutBadCheckInDates =
                          (update[0] === null && update[1] === null) ||
                          (update[0] !== null && update[1] !== null);
                        // Gray out bad check out dates when a check out date hasn't been selected yet
                        const grayOutBadCheckOutDates =
                          update[0] !== null && update[1] === null;

                        if (grayOutBadCheckOutDates) {
                          //console.log("grayOutBadCheckOutDates");
                          setCurrentlyShowing("checkOut");
                          // Set the datepicker to gray out dates that can't be checked OUT
                          // First, find which dates ARE okay to check out for the user-selected check in date (update[0])

                          const datesOkayToCheckOut = processCheckOuts(
                            update[0],
                            guestyData
                          );
                          //console.log("datesOkayToCheckOut", datesOkayToCheckOut)
                          // Create an array of all the dates NOT in that array (ie: the ones that are NOT okay to check out)
                          const datesNotOkayToCheckOut = guestyData
                            .filter(
                              (date) =>
                                !datesOkayToCheckOut.includes(date.startDate)
                            )
                            .map((date) => date.startDate);
                          const datesNotOkayToCheckOutEpoch = guestyData
                            .filter(
                              (date) =>
                                !datesOkayToCheckOut.includes(date.startDate)
                            )
                            .map((date) => Date.parse(date.startDate));
                          //console.log("datesNotOkayToCheckOut", datesNotOkayToCheckOut)
                          // Set those dates (the ones NOT okay to check out) to be grayed out
                          setDatesToGrayOut(datesNotOkayToCheckOut);
                          setDatesToGrayOutEpoch(datesNotOkayToCheckOutEpoch);

                          // We have to create a new array to find the dates that are booked AND not okay to check out because datesNotOkayToCheckOut is dates only, do extra data
                          const datesNotOkayToCheckOutWithExtraData =
                            guestyData.filter(
                              (date) =>
                                !datesOkayToCheckOut.includes(date.startDate)
                            );
                          const getBookedAndNotOkayToCheckOutDates =
                            datesNotOkayToCheckOutWithExtraData
                              .filter((date) => date.status === "booked")
                              .map((date) => Date.parse(date.startDate));
                          setBookedAndNotOkayToCheckOutDates(
                            getBookedAndNotOkayToCheckOutDates
                          );
                        } else if (grayOutBadCheckInDates) {
                          //console.log("grayOutBadCheckInDates");
                          setCurrentlyShowing("checkIn");
                          // Set the datepicker to gray out dates that can't be checked IN (basically same thing as what it does onload)
                          setDatesToGrayOut(datesNotOkayToCheckIn);
                          setDatesToGrayOutEpoch(datesNotOkayToCheckInEpoch);
                        }
                      }}
                      dayClassName={(date) => {
                        // If it's a clickable day, and there's no check out date selected yet, make it blue
                        // (Only doing this when there's no check out date selected so that the start/end/between dates can keep native styles)
                        //if (!listingEndDate && !datesToGrayOut.includes(date)) {
                        //  return "dm-datepicker-date-available"
                        //}

                        // If it's the start/end date, keep native styles
                        if (formatFriendlyDate(date) === formatFriendlyDate(listingStartDate)) return undefined
                        if (formatFriendlyDate(date) === formatFriendlyDate(listingEndDate)) return undefined

                        // If it's booked AND the previous day is NOT booked, make it slant up
                        // Else if it's NOT booked AND the previous day is booked, make it slant down
                        // Else if it's booked, make it full gray
                        const previousDate = new Date(date)
                        previousDate.setDate(date.getDate() - 1)

                        // If a check in date has NOT been picked yet (so we're currently showing check in dates) use ALL booked dates
                        if (currentlyShowing === "checkIn") {
                          if (bookedDates.includes(Date.parse(date)) && !bookedDates.includes(Date.parse(previousDate.toString()))) {
                            return "dm-datepicker-date-booked-available-start"
                          } else if (!bookedDates.includes(Date.parse(date)) && bookedDates.includes(Date.parse(previousDate.toString()))) {
                            return "dm-datepicker-date-booked-available-end dm-datepicker-date-available"
                          } else if (bookedDates.includes(Date.parse(date))) {
                            return "dm-datepicker-date-booked-unavailable"
                          }
                          return "dm-datepicker-date-available"
                        }

                        // If a check in date HAS been picked (so we're currently showing check out dates),use booked dates that are *also* NOT okay to check out
                        if (currentlyShowing === "checkOut") {
                          if (bookedAndNotOkayToCheckOutDates.includes(Date.parse(date)) && !bookedAndNotOkayToCheckOutDates.includes(Date.parse(previousDate.toString()))) {
                            return "dm-datepicker-date-booked-available-start"
                          } else if (!bookedAndNotOkayToCheckOutDates.includes(Date.parse(date)) && bookedAndNotOkayToCheckOutDates.includes(Date.parse(previousDate.toString()))) {
                            return "dm-datepicker-date-booked-available-end dm-datepicker-date-available"
                          } else if (bookedAndNotOkayToCheckOutDates.includes(Date.parse(date))) {
                            return "dm-datepicker-date-booked-unavailable"
                          }
                          return "dm-datepicker-date-available"
                        }
                      }}
                      excludeDates={datesToGrayOut}
                      renderDayContents={renderDayContents}
                      calendarClassName={
                        isInline ? "custom-datepicker-scroll" : ""
                      }
                      includeDateIntervals={[
                        {
                          start: (guestyData.find(item => item.status === "available").startDate || new Date()),
                          end: (guestyData.slice().reverse().find(item => item.status === "available").startDate || MAX_END_DATE),
                        },
                      ]}
                      shouldCloseOnSelect={true}
                    >
                      <div className="dm-datepicker-clear-submit-container">
                        <Button
                          width="50%"
                          fontSize="15px"
                          colorScheme="dmOrange"
                          variant="outline"
                          onClick={clearDates}
                        >
                          Clear Dates
                        </Button>

                        {/* Don't show the Submit button on mobile */}
                        {
                          !isInline && (
                            <Button
                              width="50%"
                              fontSize="15px"
                              isDisabled={!listingStartDate || !listingEndDate}
                              colorScheme="dmOrange"
                              onClick={() => {
                                if (datepickerIsOpen) setDatepickerIsOpen(false)
                                //const datepickerElement = document.querySelector(".react-datepicker__tab-loop")
                                //datepickerElement.remove()
                                setTimeout(() => {
                                  setDatepickerIsOpen(true)
                                  //clickAwaySimulation.current.click()
                                }, 100)
                              }}
                            >
                              Continue
                            </Button>
                          )
                        }

                      </div>
                    </DatePicker>
                  )}
                </Flex>
              </FormControl>
              {/* END Dates Input */}

              {/* Guests Number Input */}
              <FormControl height={"50px"} w={"100%"} mt={4}>
                <Flex
                  gap={2}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Flex ml={1} w={"100%"}>
                    <Box display={{ base: "none", xl: "flex" }}>
                      Number of&nbsp;
                    </Box>
                    Guests
                  </Flex>
                  <Flex alignItems={"center"} gap={2}>
                    <Button
                      onClick={() =>
                        decrementGuestInput(setGuests, guests)
                      }
                      variant="outline"
                      borderColor="dmNavy.500"
                    >
                      -
                    </Button>
                    <Input
                      textAlign={"center"}
                      p="10px"
                      w="40px"
                      color="black"
                      type="number"
                      border="0"
                      onChange={(e) => {
                        const newValue = parseInt(e.target.value, 10);
                        if (
                          !isNaN(newValue) &&
                          newValue >= 1 &&
                          newValue <= houseData.occupancy
                        ) {
                          setGuests(newValue);
                        }
                      }}
                      value={guests || ""}
                    />
                    <Button
                      onClick={() =>
                        incrementGuestInput(
                          setGuests,
                          guests,
                          houseData.occupancy
                        )
                      }
                      variant="outline"
                      borderColor="dmNavy.500"
                    >
                      +
                    </Button>
                  </Flex>
                </Flex>
              </FormControl>
              {/* END Guests Number Input */}

              {/* Display Spinner While Quote Loading */}
              {listingStartDate &&
                listingEndDate &&
                guests &&
                !totalPrice &&
                !errorMessage && isSubmittingForQuote && (
                  <Flex
                    w={"100%"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    mt={7}
                    mb={5}
                  >
                    <Spinner size="xl" color="dmOrange" />
                  </Flex>
                )}

              {/* Pricing Info */}
              {listingStartDate && listingEndDate && subTotalPrice && reservationQuote && pricingLoaded && (
                <Flex flexDirection={"column"} gap={2} mt={2}>

                  {rebookDiscountEligible && !isSubmittingForQuote ? (
                    <>
                      {subTotalPrice && channelSubTotalPrice && discount > 0 &&
                        <Flex flexDirection="column" w="100%">
                          <Flex justifyContent={"space-between"} w="100%" style={{ textDecoration: 'line-through', color: '#e95037' }} fontWeight={'bold'} pb={1}>
                            <Box>
                              {getChannelDisplayName(reservationQuote.channel)} Price
                            </Box>
                            <Box>
                              {channelSubTotalPrice.toLocaleString("en-US", {
                                style: "currency",
                                currency: "USD",
                              })}
                            </Box>
                          </Flex>
                          {/* Display Direct Booking Savings */}
                          {subTotalPrice && channelSubTotalPrice && discount > 0 && (
                            <Box w="100%" justifyContent="space-between" className={'channel-price-comparison'} mt={2}>
                              <Flex
                                justifyContent="space-between"
                                w="100%"
                              >
                                <Alert status={'success'} bgColor="#00B2FF50">
                                  You are saving&nbsp;
                                  {discount.toLocaleString("en-US", {
                                    style: "currency",
                                    currency: "USD",
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })} by rebooking with Del Mar.
                                </Alert>
                              </Flex>
                            </Box>
                          )}
                        </Flex>
                      }
                      <Divider my={1}></Divider>
                      <Flex flexDirection="column" w="100%">
                        <Flex justifyContent={"space-between"} w="100%" pb={1} fontWeight={'bold'}>
                          <Flex>{reservationQuote.channel === "Website" ? 'Rebook' : 'Our'} Price</Flex>
                          <Flex>
                            {nightlyRatesPlusCleaning && nightlyRatesPlusCleaning.toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                            })}
                          </Flex>
                        </Flex>
                        <Flex justifyContent={"space-between"} w="100%" pb={1} fontSize={'sm'}>
                          <Flex gap={2}><ChevronRightIcon h={'100%'} /> Room Rate ({nightlyRates && (nightlyRates / numberOfNights).toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })} x {numberOfNights} nights)</Flex>
                          <Flex>
                            {nightlyRates && nightlyRates.toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                            })}
                          </Flex>
                        </Flex>
                        <Flex justifyContent={"space-between"} w="100%" pb={1} fontSize={'sm'}>
                          <Flex gap={2}><ChevronRightIcon h={'100%'} />Cleaning Fee</Flex>
                          <Flex>
                            {cleaningFee && cleaningFee.toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                            })}
                          </Flex>
                        </Flex>
                        {/*
                        <Flex justifyContent={"space-between"} w="100%" py={1}>
                          <Flex gap={2}>Processing Fee</Flex>
                          <Flex>
                            {processingFee && processingFee.toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                            })}
                          </Flex>
                        </Flex>
                        */}

                      </Flex>
                      <Divider my={0}></Divider>
                    </>
                  ) : (
                    <>
                      <Flex justifyContent={"space-between"} w="100%">
                        <Flex>Room Rate</Flex>
                        <Flex>
                          {nightlyRates && nightlyRates.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })}
                        </Flex>
                      </Flex>
                      <Flex justifyContent={"space-between"} w="100%">
                        <Flex>Cleaning Fee</Flex>
                        <Flex>
                          {cleaningFee && cleaningFee.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })}
                        </Flex>
                      </Flex>

                    </>
                  )}


                  <Flex
                    justifyContent={"space-between"}
                    w="100%"
                    fontWeight={"bold"} py={2}
                  >
                    <Flex>Subtotal</Flex>
                    <Flex>
                      {nightlyRatesPlusCleaning && nightlyRatesPlusCleaning.toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })}
                    </Flex>
                  </Flex>


                  {/*
                  {!rebookDiscountEligible &&
                    <Box w="100%" justifyContent="space-between">
                      <Flex
                        justifyContent="space-between"
                        w="100%"
                      >
                        <Alert status={'success'} bgColor="#54B4FF50">
                          You are saving up to 20% by booking direct with Del Mar. Plus, enjoy Free Cancellation and Flexible Modifications within 48 Hours After Booking — No Penalties!
                        </Alert>
                      </Flex>
                    </Box>
                  }
                  */}
                </Flex>
              )}
              {/* Submit Button - Book This Home */}
              {listingStartDate && listingEndDate && guests && totalPrice ? (
                <Button
                  h={{ base: "50px", md: "50px" }}
                  mt={{ base: 3, md: 6 }}
                  onClick={handleSubmit}
                  colorScheme="dmOrange"
                  disabled={!dateRange || !guests} // Disable if either is not known
                >
                  Select Payment Option
                </Button>
              ) : (
                <Button
                  h={{ base: "50px", md: "50px" }}
                  mt={{ base: 3, md: 6 }}
                  colorScheme="dmOrange"
                  isDisabled
                >
                  Select dates for pricing
                </Button>
              )}

              {/* Inquiry Button, hidden from ADMIN user */}
              {!userIsAdmin && (
                <Button
                  h={{ base: "50px", md: "50px" }}
                  mt={{ base: 3, md: 4 }}
                  onClick={onOpenInquiryModal}
                  colorScheme="dmOrange"
                  variant="outline"
                //disabled={!dateRange || !guests} // Disable if either is not known
                >
                  Inquire about this home
                </Button>
              )}
              <InquiryModal
                isOpen={isInquiryModalOpen}
                onClose={onCloseInquiryModal}
              />

              {/* ADMIN Only: Add to Quote Button */}
              {userIsAdmin && activeQuoteNumber && (
                <Flex w={"100%"} gap={3}>
                  <Tooltip
                    isDisabled={
                      !(
                        !listingStartDate ||
                        !listingEndDate ||
                        !guests ||
                        !totalPrice ||
                        !activeQuoteNumber
                      )
                    }
                    label="Dates, guests, and price must be set to add to quote."
                    aria-label="Dates, guests, and price must be set to add to quote."
                    p={3}
                    placement={"top"}
                    hasArrow
                  >
                    <Button
                      h={{ base: "50px", md: "50px" }}
                      mt={{ base: 3, md: 4 }}
                      onClick={addToQuote}
                      colorScheme="dmNavy"
                      variant="outline"
                      w={"60%"}
                      isDisabled={
                        addedToQuote ||
                        !listingStartDate ||
                        !listingEndDate ||
                        !guests ||
                        !totalPrice ||
                        !activeQuoteNumber
                      }
                    >
                      {addedToQuote ? "Added to Quote " : "Add to Quote "}
                      {activeQuoteNumber}
                    </Button>
                  </Tooltip>
                  <Button
                    h={{ base: "50px", md: "50px" }}
                    mt={{ base: 3, md: 4 }}
                    onClick={() => navigate(`../quotes/${activeQuoteNumber}`)}
                    colorScheme="dmNavy"
                    variant="outline"
                    w={"40%"}
                  >
                    View Quote
                  </Button>
                </Flex>
              )}
            </Flex>
            {/* End Datepicker Form */}

            {errorMessage && (
              <>
                {errorMessage.includes("rate plans are not applicable") ? (
                  <Text color="red.500" px={4} pb={3}>
                    {
                      "The dates you have selected are not available for this property. Please try again."
                    }
                  </Text>
                ) : (
                  <Text color="red.500" px={4} pb={3}>
                    {
                      "Error retrieving reservation quote. Please contact us for assistance."
                    }
                  </Text>
                )}
              </>
            )}
          </Flex>
          {/* End Floating Card */}
        </>
      )}
    </Flex>
  );

  if (houseLoading) {
    return <SkeletonBlock />;
  }

  if (!houseLoading && houseData && !houseData.activeListing) {
    return <NotAcceptedBlock />;
  }

  return (
    <Flex
      position={"relative"}
      width={"full"}
      borderTop={{ base: 0, md: "10px" }}
      borderStyle={"solid"}
      borderColor={"white"}
      data-id={"listing"}
      p={{ base: 0, md: 5 }}
      justifyContent={"center"}
      flexDirection={"column"}
      alignItems={"center"}
    >
      <ScrollToTop />
      <Flex
        w={"100%"}
        flexDirection={{ base: "column-reverse", md: "column" }}
        alignItems={"center"}
      >
        {/* Top Bar */}
        <TopBar />

        {/* Photogallery */}
        <PhotoGallery />
      </Flex>

      {/* Body Section */}
      <Flex
        maxWidth="1440px"
        w={"100%"}
        p={{ base: 4, md: 2 }}
        flexDirection={{ base: "column", md: "row" }}
      >
        {/* Home Details Section */}
        <Flex w={{ base: "100%", md: "67%" }} flexDirection={"column"}>
          {/* Occupancy, Bedroom, Bathroom Icons */}
          <Details />

          {/* Calendar */}
          <Flex flexDirection={"column"} w={"100%"}>
            <Box fontWeight={500} pb={1} fontSize={20}>
              {listingStartDate
                ? listingEndDate
                  ? houseData.neighborhood
                    ? `${Math.ceil(
                      (listingEndDate - listingStartDate) / 86400000
                    )} Nights in ${houseData.neighborhood}`
                    : `${Math.ceil(
                      (listingEndDate - listingStartDate) / 86400000
                    )} Nights In ${houseData.town}`
                  : "Select check-out date"
                : "Select check-in date:"}
            </Box>
            <Box fontSize={15} color={"#666"} pb={2}>
              {listingStartDate && listingEndDate
                ? `${formatFriendlyDate(
                  listingStartDate
                )} - ${formatFriendlyDate(listingEndDate)}`
                : "Please select dates"}
            </Box>
            {globalCheckIns && globalCheckIns.length > 0 && guestyData && (
              <DatePicker
                key={datePickerKey} // Add key to force re-render
                placeholderText="Select Dates"
                h={"100%"}
                isClearable={true}
                selectsRange={true}
                startDate={listingStartDate}
                endDate={listingEndDate}
                monthsShown={monthsShown}
                inline
                openToDate={listingStartDate || guestyData.find(item => item.status === "available").startDate}
                maxDate={guestyData.slice().reverse().find(item => item.status === "available").startDate}
                onChange={(update) => {
                  //setDateRange(update)
                  //setContextDateRange(update)
                  setListingStartDate(update[0]);
                  setListingEndDate(update[1]);
                  //console.log("update", update)

                  // Gray out bad check in dates either: (1) on first load (when both are null) *or* (2) when both are NOT null
                  // (So the user can't click on a bad check in date when a date range is already selected)
                  const grayOutBadCheckInDates =
                    (update[0] === null && update[1] === null) ||
                    (update[0] !== null && update[1] !== null);
                  //console.log('grayOutBadCheckInDates', grayOutBadCheckInDates)
                  // Gray out bad check out dates when a check out date hasn't been selected yet
                  const grayOutBadCheckOutDates =
                    update[0] !== null && update[1] === null;

                  if (grayOutBadCheckOutDates) {
                    setCurrentlyShowing("checkOut");
                    // Set the datepicker to gray out dates that can't be checked OUT
                    // First, find which dates ARE okay to check out for the user-selected check in date (update[0])
                    const datesOkayToCheckOut = processCheckOuts(
                      update[0],
                      guestyData
                    );
                    //console.log("datesOkayToCheckOut", datesOkayToCheckOut)
                    // Create an array of all the dates NOT in that array (ie: the ones that are NOT okay to check out)
                    const datesNotOkayToCheckOut = guestyData
                      .filter(
                        (date) => !datesOkayToCheckOut.includes(date.startDate)
                      )
                      .map((date) => date.startDate);
                    const datesNotOkayToCheckOutEpoch = guestyData
                      .filter(
                        (date) => !datesOkayToCheckOut.includes(date.startDate)
                      )
                      .map((date) => Date.parse(date.startDate));
                    //console.log("datesNotOkayToCheckOut", datesNotOkayToCheckOut)
                    // Set those dates (the ones NOT okay to check out) to be grayed out
                    setDatesToGrayOut(datesNotOkayToCheckOut);
                    setDatesToGrayOutEpoch(datesNotOkayToCheckOutEpoch);

                    // We have to create a new array to find the dates that are booked AND not okay to check out because datesNotOkayToCheckOut is dates only, do extra data
                    const datesNotOkayToCheckOutWithExtraData =
                      guestyData.filter(
                        (date) => !datesOkayToCheckOut.includes(date.startDate)
                      );
                    const getBookedAndNotOkayToCheckOutDates =
                      datesNotOkayToCheckOutWithExtraData
                        .filter((date) => date.status === "booked")
                        .map((date) => Date.parse(date.startDate));
                    setBookedAndNotOkayToCheckOutDates(
                      getBookedAndNotOkayToCheckOutDates
                    );
                  } else if (grayOutBadCheckInDates) {
                    setCurrentlyShowing("checkIn");
                    // Set the datepicker to gray out dates that can't be checked IN (basically same thing as what it does onload)
                    setDatesToGrayOut(datesNotOkayToCheckIn);
                    setDatesToGrayOutEpoch(datesNotOkayToCheckInEpoch);
                  }
                }}
                dayClassName={(date) => {
                  if (formatFriendlyDate(date) === formatFriendlyDate(listingStartDate)) return undefined
                  if (formatFriendlyDate(date) === formatFriendlyDate(listingEndDate)) return undefined

                  // If it's booked AND the previous day is NOT booked, make it slant up
                  // Else if it's NOT booked AND the previous day is booked, make it slant down
                  // Else if it's booked, make it full gray

                  const previousDate = new Date(date)
                  previousDate.setDate(date.getDate() - 1)

                  // If a check in date has NOT been picked yet (so we're currently showing check in dates) use ALL booked dates
                  if (currentlyShowing === "checkIn") {
                    if (bookedDates.includes(Date.parse(date)) && !bookedDates.includes(Date.parse(previousDate.toString()))) {
                      return "dm-datepicker-date-booked-available-start-wider"
                    } else if (!bookedDates.includes(Date.parse(date)) && bookedDates.includes(Date.parse(previousDate.toString()))) {
                      return "dm-datepicker-date-booked-available-end-wider"
                    } else if (bookedDates.includes(Date.parse(date))) {
                      return "dm-datepicker-date-booked-unavailable"
                    }
                    return undefined
                  }

                  // If a check in date HAS been picked (so we're currently showing check out dates),use booked dates that are *also* NOT okay to check out
                  if (currentlyShowing === "checkOut") {
                    if (bookedAndNotOkayToCheckOutDates.includes(Date.parse(date)) && !bookedAndNotOkayToCheckOutDates.includes(Date.parse(previousDate.toString()))) {
                      return "dm-datepicker-date-booked-available-start"
                    } else if (!bookedAndNotOkayToCheckOutDates.includes(Date.parse(date)) && bookedAndNotOkayToCheckOutDates.includes(Date.parse(previousDate.toString()))) {
                      return "dm-datepicker-date-booked-available-end"
                    } else if (bookedAndNotOkayToCheckOutDates.includes(Date.parse(date))) {
                      return "dm-datepicker-date-booked-unavailable"
                    }
                    return undefined
                  }
                }}
                excludeDates={datesToGrayOut}
                renderDayContents={renderDayContents}
                includeDateIntervals={[
                  {
                    start: (guestyData.find(item => item.status === "available").startDate || new Date()),
                    end: (guestyData.slice().reverse().find(item => item.status === "available").startDate || MAX_END_DATE),
                  },
                ]}
              />
            )}
            <Link
              textDecoration={"underline"}
              fontSize={"14px"}
              onClick={clearDates}
              mt={1}
            >
              Clear Dates
            </Link>
          </Flex>

          <Divider my={8} color={"black"} />

          {/* Reviews Section */}
          {houseData.reviews > 0 && (
            <Flex flexDirection={"column"}>
              <Flex
                fontWeight={500}
                pb={5}
                fontSize={24}
                className="listing-reviews-parent"
                alignItems={"center"}
              >
                <Image
                  src={"../media/ratingstar.png"}
                  alt="Home Rating"
                  objectFit={"contain"}
                  align={"left"}
                  width={"28px"}
                  height={"28px"}
                  className="listing-rating-star"
                  mx={3}
                />
                <Flex className="listing-rating" pr={10}>
                  {!isNaN(houseData.rating) && houseData.rating !== null
                    ? houseData.rating.toFixed(1)
                    : "0"}
                </Flex>
                <Flex
                  className="listing-reviews"
                  fontSize={20}
                  fontWeight={500}
                  onClick={onOpenReviewModal}
                  sx={{ "&:hover": { cursor: "pointer" } }}
                >
                  {!isNaN(houseData.reviews)
                    ? (houseData.reviews as number)
                    : 0}{" "}
                  Reviews
                </Flex>
              </Flex>
              <Grid
                templateColumns={{
                  base: "repeat(1, 1fr)",
                  md: "repeat(2, 1fr)",
                }}
                gap={6}
                alignItems="stretch"
              >
                {houseData.rawReviews
                  .sort(
                    (a, b) =>
                      new Date(b.submittedAt).getTime() -
                      new Date(a.submittedAt).getTime()
                  )
                  .slice(0, 4)
                  .map((review, index) => {
                    const roundedRating = Math.round(review.rating * 2) / 2; // Round to nearest 0.5
                    const fullStars = Math.floor(roundedRating);
                    const halfStar = roundedRating % 1 !== 0;
                    const emptyStars = 5 - fullStars - (halfStar ? 1 : 0);

                    return (
                      <Card
                        key={index + review.rating + review.publicReview}
                        boxShadow={"dmShadow"}
                      >
                        <CardBody p={8}>
                          <Flex className="star-rating" h={5} ml={0} mb={2}>
                            {Array.from({ length: fullStars }, (_, i) => (
                              <Image
                                key={i}
                                src="../media/ratingstar.png"
                                alt="star"
                              />
                            ))}
                            {halfStar && (
                              <Image
                                src="../media/halfstar.png"
                                alt="half star"
                              />
                            )}
                            {Array.from({ length: emptyStars }, (_, i) => (
                              <Image
                                key={`empty-${i}`}
                                src="../media/emptystar.png"
                                alt="empty star"
                              />
                            ))}
                          </Flex>
                          <Flex my={1}>
                            {review.firstName && (
                              <Text fontSize={"14px"} fontWeight={"500"}>
                                {review.firstName}
                                <Text as="span" mx={2}>
                                  &#8226;
                                </Text>
                              </Text>
                            )}
                          </Flex>
                          <Text>{review.publicReview}</Text>
                        </CardBody>
                      </Card>
                    );
                  })}
              </Grid>

              {houseData.rawReviews.length > 4 && (
                <Button
                  mt={10}
                  colorScheme="dmNavy"
                  variant="outline"
                  onClick={onOpenReviewModal}
                >
                  Show {houseData.rawReviews.length} recent reviews
                </Button>
              )}

              {/* Modal for displaying all reviews */}
              <Modal
                isOpen={isReviewModalOpen}
                onClose={onCloseReviewModal}
                scrollBehavior="inside"
                size="4xl"
              >
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader>
                    Recent Reviews for {houseData.houseNumber}
                  </ModalHeader>
                  <ModalCloseButton />
                  <ModalBody>
                    <Flex flexDirection="column" overflowY="auto">
                      <Flex
                        fontWeight={500}
                        pb={4}
                        fontSize={24}
                        className="listing-reviews-parent"
                        alignItems={"center"}
                      >
                        <Image
                          src={"../media/ratingstar.png"}
                          alt="Home Rating"
                          objectFit={"contain"}
                          align={"left"}
                          width={"28px"}
                          height={"28px"}
                          className="listing-rating-star"
                          mx={3}
                        />
                        <Flex className="listing-rating" pr={8}>
                          {!isNaN(houseData.rating) && houseData.rating !== null
                            ? houseData.rating.toFixed(1)
                            : "0"}
                        </Flex>
                        <Flex
                          className="listing-reviews"
                          fontSize={20}
                          fontWeight={500}
                        >
                          {!isNaN(houseData.reviews)
                            ? (houseData.reviews as number)
                            : 0}{" "}
                          Reviews
                        </Flex>
                      </Flex>
                      <Grid
                        templateColumns={{
                          base: "repeat(1, 1fr)",
                          md: "repeat(1, 1fr)",
                        }}
                        gap={2}
                        alignItems="stretch"
                      >
                        {houseData.rawReviews
                          .sort(
                            (a, b) =>
                              new Date(b.submittedAt).getTime() -
                              new Date(a.submittedAt).getTime()
                          )
                          .map((review, index) => {
                            const roundedRating =
                              Math.round(review.rating * 2) / 2; // Round to nearest 0.5
                            const fullStars = Math.floor(roundedRating);
                            const halfStar = roundedRating % 1 !== 0;

                            return (
                              <Card
                                key={
                                  index +
                                  review.rating +
                                  review.publicReview +
                                  "modal"
                                }
                                boxShadow={"none"}
                              >
                                <CardBody p={8}>
                                  <Flex
                                    className="star-rating"
                                    h={5}
                                    ml={0}
                                    mb={2}
                                  >
                                    {Array.from(
                                      { length: fullStars },
                                      (_, i) => (
                                        <Image
                                          key={i}
                                          src="../media/ratingstar.png"
                                          alt="star"
                                        />
                                      )
                                    )}
                                    {halfStar && (
                                      <Image
                                        src="../media/halfstar.png"
                                        alt="half star"
                                      />
                                    )}
                                  </Flex>
                                  <Flex my={1}>
                                    {review.firstName && (
                                      <Text
                                        fontSize={"14px"}
                                        fontWeight={"500"}
                                      >
                                        {review.firstName}
                                        <Text as="span" mx={2}>
                                          &#8226;
                                        </Text>
                                      </Text>
                                    )}
                                    <Text fontSize={"14px"}>
                                      Visited{" "}
                                      {new Date(
                                        review.submittedAt
                                      ).toLocaleString("en-US", {
                                        month: "long",
                                        year: "numeric",
                                      })}
                                    </Text>
                                  </Flex>
                                  <Text>{review.publicReview}</Text>
                                </CardBody>
                              </Card>
                            );
                          })}
                      </Grid>
                    </Flex>
                  </ModalBody>
                </ModalContent>
              </Modal>
            </Flex>
          )}
          {/* End Reviews Section */}

          {houseData.reviews > 0 && <Divider my={8} color={"black"} />}

          {/* Location Section */}
          <Flex flexDirection={"column"} minH={"550px"}>
            <Box fontWeight={500} mb={4} fontSize={20}>
              House Location
            </Box>
            <AspectRatio ratio={16 / 9}>
              <HouseMap
                latitude={parseFloat(houseData.latitude)}
                longitude={parseFloat(houseData.longitude)}
              />
            </AspectRatio>
          </Flex>
          {/* End Location Section */}
        </Flex>

        {/* Mobile Fixed Bottom Booking Menu */}
        <Flex
          bgColor={"dmNavy.500"}
          width={"100vw"}
          height="75px"
          display={{ base: "flex", md: "none" }}
          position="fixed"
          bottom="0"
          left="0"
          zIndex="500"
          justifyContent={"space-between"}
          px={4}
          alignItems={"center"}
        >
          <Flex
            color={"white"}
            fontSize={14}
            flexDirection={"column"}
            textAlign={"left"}
            onClick={onOpenBookingModal}
          >
            <Flex pb={1} mr={3} gap={2} alignItems={'center'}>
              <Box flexShrink={1}>
                {listingStartDate && listingEndDate
                  ? `${formatFriendlyDateShorter(listingStartDate)} - ${formatFriendlyDate(listingEndDate)}`
                  : "Click here to select dates"}
              </Box>
              <Icon as={EditIcon} boxSize="14px" />
            </Flex>
            {guests > 0 && subTotalPrice && pricingLoaded ? (
              <Box fontWeight={"400"}>
                {subTotalPrice.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}{" "}
                Subtotal
              </Box>
            ) : (
              listingStartDate &&
              listingEndDate &&
              !guests && <Box fontWeight={"400"}>Please select guests</Box>
            )}
          </Flex>
          {/* Submit Button */}
          {listingStartDate && listingEndDate && guests && totalPrice ? (
            <Button
              h={"50px"}
              onClick={handleSubmit}
              disabled={!houseData}
              colorScheme="dmOrange"
            >
              Select payment option
            </Button>
          ) : listingStartDate && listingEndDate && guests ? (
            <Button
              h={"50px"}
              px={8}
              onClick={handleSubmit}
              colorScheme="dmOrange"
            >
              Get Pricing
            </Button>
          ) : (
            <Button
              h={"50px"}
              px={8}
              onClick={onOpenBookingModal}
              colorScheme="dmOrange"
            >
              Get Pricing
            </Button>
          )}
        </Flex>
        {/* END Mobile Fixed Bottom Booking Menu */}

        {/* Booking Section */}
        {isMobile ? (
          <Modal
            isOpen={isBookingModalOpen}
            onClose={onCloseBookingModal}
            motionPreset="slideInBottom"
            size={"full"}
          >
            <ModalOverlay />
            <ModalContent
              top={"0px"}
              w="100%"
              maxW="100vw"
              h="auto"
              mt={"0px"}
              borderRadius="0px"
            >
              <ModalCloseButton zIndex={"50"} />
              {bookingContent}
            </ModalContent>
          </Modal>
        ) : (
          <Flex
            w={{ base: "100%", md: "38%", lg: "33%" }}
            ml={{ base: 0, md: 10 }}
            mt={5}
          >
            {bookingContent}
          </Flex>
        )}
      </Flex>
      {/* End Body Section */}
    </Flex>
  );
};

export { ListingBlock };

