import {
	Alert, AlertIcon, Box, Button, Flex, FormControl, FormLabel, Heading, Icon, Input, InputGroup, InputLeftElement,
	Link,
	ListItem,
	Modal, ModalContent, ModalFooter, ModalOverlay,
	NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper,
	OrderedList, Radio, RadioGroup,
	Spinner, Stack, Text, VStack
} from '@chakra-ui/react';
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useState } from 'react';
import { FaPhoneAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import * as Yup from 'yup';
import { useReservation } from '../../contexts/ReservationContext';
import { createAgreement } from '../../helpers/agreementService';
import { createDogPaymentIntent } from '../../helpers/fetch';
import { calculateDaysBetweenDates } from '../../helpers/helpers';
import { updateReservation } from '../../helpers/reservationService';

// Define the validation schema using Yup
const getValidationSchema = (bringDogValue) => Yup.object().shape({
	firstName: Yup.string().required('First name is required'),
	lastName: Yup.string().required('Last name is required'),
	email: Yup.string().email('Invalid email address').required('Email is required'),
	phone: bringDogValue === 'Yes' ? Yup.string().required('Phone number is required') : Yup.string(),
	street: bringDogValue === 'Yes' ? Yup.string().required('Street is required') : Yup.string(),
	city: bringDogValue === 'Yes' ? Yup.string().required('City is required') : Yup.string(),
	state: bringDogValue === 'Yes' ? Yup.string().required('State is required') : Yup.string(),
	zip: bringDogValue === 'Yes' ? Yup.string().required('ZIP/Postal Code is required') : Yup.string(),
	serviceAnimalRequired: bringDogValue === 'Service Animal' ? Yup.string().required('Response is required') : Yup.string(),
	serviceAnimalTrained: bringDogValue === 'Service Animal' ? Yup.string().required('Response is required') : Yup.string(),
});

// Define the ErrorMessageText component for error messages
const ErrorMessageText = ({ children }) => (
	<Text fontSize="sm" color="red.500">{children}</Text>
);

// Stripe CardElement Styling
const CARD_ELEMENT_OPTIONS = {
	style: {
		base: {
			color: "rgb(26, 32, 44)",
			fontFamily: "azo-sans-web, sans-serif",
			fontSmoothing: "antialiased",
			fontSize: "1em",
			"::placeholder": {
				color: "#aab7c4",
				fontFamily: "azo-sans-web, sans-serif",
			},
			border: '1px solid rgb(226, 232, 240)',
		},
		invalid: {
			color: "#fa755a",
			iconColor: "#fa755a"
		}
	}
};

const DogPaymentForm = () => {

	const { reservationData, updateReservationData } = useReservation();

	// Dynamic Validation Schema based on Whether Dog Guests
	const [currentSchema, setCurrentSchema] = useState(getValidationSchema(null));

	const perDogFee = 35;
	const numNights = calculateDaysBetweenDates(reservationData.checkinDate, reservationData.checkoutDate);

	const stripe = useStripe();
	const elements = useElements();
	const [phone, setPhone] = useState(reservationData.phone || '');
	const [errorMessage, setErrorMessage] = useState(null)

	// For Payment Processing Submission Display
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [modalContent, setModalContent] = useState({ message: '', isLoading: true });

	const dogFeePossible = () => {
		// Check if the reservation was created on or after February 12, 2024.
		if (new Date(reservationData.createdAt) >= new Date('2024-02-12T00:00:00.000Z')) {
			return true;
		}

		// For reservations created before February 12, 2024, check if any dog fee has been paid.
		return Boolean(!(reservationData.dogFeePaid && reservationData.dogFeePaid > 0));
	}

	//console.log("dogFeePossible",dogFeePossible())

	const handleSubmitDogAgreement = async (values, { setSubmitting }) => {
		//console.log("Submit handler called", values);
		setErrorMessage(null);

		// Bring Dog Yes
		if (values.bringDog === 'Yes') {

			if (!stripe) {
				// Stripe.js has not loaded yet. Make sure to disable form submission until Stripe.js has loaded.
				setSubmitting(false);
				return;
			}

			const cardNumberElement = elements?.getElement(CardNumberElement);

			if (cardNumberElement) {
				const { error, paymentMethod } = await stripe.createPaymentMethod({
					type: 'card',
					card: cardNumberElement,
					billing_details: {
						name: `${values.firstName} ${values.lastName}`,
						email: values.email,
						phone: values.phone,
						address: {
							line1: values.street,
							city: values.city,
							state: values.state,
							postal_code: values.zip
						}
					}
				});
				if (error) {
					//console.log('[error]', error);
					setErrorMessage(error.message);
					setSubmitting(false);
					return;
				} else {
					const paymentData = {
						reservationNumber: reservationData.reservationNumber, // Assuming you have the reservation number in your values
						paymentMethodId: paymentMethod.id,
						numberOfDogs: values.numberOfDogs, // Assuming you have the number of dogs in your values
						dogFeeAlreadyPaid: reservationData.dogFeePaid || 0
					};
					//console.log('Payment Data:', paymentData);

					// Show modal with loading spinner
					setIsModalOpen(true);
					setModalContent({ message: 'Processing payment...', isLoading: true });

					try {
						const response = await createDogPaymentIntent(paymentData);
						if (!response.ok) {
							throw new Error('Payment processing failed');
						}
						const data = await response.json();
						//console.log('Payment Intent created:', data);

						// Confirm the card payment
						const { error: confirmError } = await stripe.confirmCardPayment(data.clientSecret, {
							payment_method: paymentMethod.id,
						});

						if (confirmError) {
							setErrorMessage(confirmError.message);
							throw confirmError;
						} else {
							setModalContent({ message: 'Payment Successful', isLoading: false });
						}

						//console.log('Payment confirmed:', paymentIntent);
						// Handle post-payment success actions
					} catch (error) {
						console.error('Error:', error);
						setErrorMessage('Failed to process payment. Please check your details and try again.');
						setModalContent({ message: 'Payment failed. Please check your details and try again.', isLoading: false });
						setSubmitting(false);
						setIsModalOpen(false); // Optionally close the modal on error
						setSubmitting(false);
						return;
					} finally {
						setSubmitting(false);
					}
				}
			}
		}
		// END Bring Dog Yes

		// Prepare agreement data
		// First, determine if the total dog fee should be calculated and added
		const calculatedTotalDogFee = values.numberOfDogs > 0
			? values.numberOfDogs * perDogFee * numNights - (reservationData.dogFeePaid || 0)
			: 0;

		// Determine if adding totalDogFee to metadata is applicable
		const shouldIncludeTotalDogFee = dogFeePossible && calculatedTotalDogFee > 0;

		const agreementData = {
			...values,
			signedAt: new Date().toISOString(),
			type: 'dog',
			reservationNumber: reservationData.reservationNumber,
			metadata: {
				dogGuests: values.bringDog,
				...(values.numberOfDogs > 0 && values.bringDog === 'Yes' && { numDogs: values.numberOfDogs }),
				...(shouldIncludeTotalDogFee && values.bringDog === 'Yes' && { totalDogFee: `${calculatedTotalDogFee}` }),
			},
			houseNumber: reservationData.houseNumber,
			houseName: reservationData.house.name,
			address: reservationData.house.address,
			maxOccupants: reservationData.house.maxOccupancy,
			bedrooms: reservationData.house.bedrooms,
			bathrooms: reservationData.house.bathrooms,
			bookedOccupants: reservationData.adults,
			checkinDate: reservationData.checkinDate,
			checkoutDate: reservationData.checkoutDate,
			checkinTime: reservationData.checkinTime,
			checkoutTime: reservationData.checkoutTime,
			numNights: calculateDaysBetweenDates(reservationData.checkinDate, reservationData.checkoutDate),
			taxes: reservationData.taxes,
			cleaningFee: reservationData.cleaningFee,
			totalPrice: reservationData.totalPrice,
			channel: reservationData.channel,
			dogFriendly: reservationData.house.tags !== null ? reservationData.house.tags.includes('Dog Friendly') : false,
			serviceAnimalRequired: values.serviceAnimalRequired,
			serviceAnimalTrained: values.serviceAnimalTrained,
		};

		// Call agreements/create endpoint
		try {
			setErrorMessage(null)
			await createAgreement(agreementData);

			await updateReservation(reservationData.reservationNumber, { dogOptions: values.bringDog, dogFeePaid: reservationData.dogFeePaid + calculatedTotalDogFee })

			// Update reservation provider
			updateReservationData(reservationData.reservationNumber);
		} catch (error) {
			console.error('Error submitting dog agreement:', error);
			setErrorMessage(error)
		} finally {
			setSubmitting(false);
		}
	};

	const handleDogSelectionChange = (setFieldValue, value) => {
		setFieldValue("bringDog", value);
		setCurrentSchema(getValidationSchema(value));

		if (value === "Yes") {
			const numDogs = reservationData.maxDogs
				? String(reservationData.maxDogs)
				: "1";
			setFieldValue("numberOfDogs", numDogs);
		} else {
			setFieldValue("numberOfDogs", "0");
		}
	};

	return (
		<>
			<Formik
				initialValues={{
					bringDog: null,
					numberOfDogs: reservationData.maxDogs ?? 0,
					firstName: reservationData.firstName || '',
					lastName: reservationData.lastName || '',
					email: reservationData.agreementEmail || '',
					phone: reservationData.phone || '',
					street: '',
					city: '',
					state: '',
					zip: '',
					serviceAnimalTrained: '',
					serviceAnimalRequired: '',
				}}
				validationSchema={currentSchema}
				onSubmit={handleSubmitDogAgreement}
			>
				{({ values, setFieldValue, errors, touched, isSubmitting, setFieldTouched }) => {

					const totalDogFee = values.numberOfDogs ? parseInt(values.numberOfDogs) * perDogFee * numNights : 0;
					const dogBoolean = values.bringDog === "Yes";

					return (

						<Form>
							{errorMessage &&
								<Alert status="error"><AlertIcon />{errorMessage}</Alert>
							}
							{/* Dog Form Section */}
							<Flex fontSize={'1.4em'} py={3} fontWeight={'bold'}>
								Dog Guests
							</Flex>
							<Box>
								<FormControl as="fieldset">
									<FormLabel as="legend">Will you or one of your guests be bringing a dog?</FormLabel>
									<RadioGroup
										name="bringDog"
										value={values.bringDog}
										onChange={(value) => handleDogSelectionChange(setFieldValue, value)}
									>
										<Radio value="Yes" mr={6}>Yes</Radio>
										<Radio value="No" mr={6}>No</Radio>
										<Radio value="Service Animal">Service Animal</Radio>
									</RadioGroup>
									<Text pt={2} pb={4}><i>All other animals are prohibited and will result in a fine of at least $1,000.</i></Text>
									<ErrorMessage name="bringDog" component={Text} />
								</FormControl>

								{values.bringDog === 'No' && (
									<>
										<Text fontWeight={'bold'}>All dogs must be paid for. Failure to report and pay for all dogs at the property prior to arrival will result in a $1,000 fine per animal, no exceptions. Please be an honest traveler and contact us if your pet sitter or kennel arrangements change last minute to pay for your dog-guest.</Text>
										<Button h={"50px"} mt={6} mb={6} type="submit" colorScheme="dmOrange">
											Agree and Continue
										</Button>
									</>
								)}

								{values.bringDog === 'Service Animal' && (
									<Flex flexDirection={'column'} gap={3}>

										<Text>For certified Service Animals only, the fee is waived. Please complete the questions below.</Text>
										<Text fontWeight={'bold'} mb={3}>Emotional Support Animals do not apply, and you must select YES above.</Text>

										<Field name="serviceAnimalRequired">
											{({ field, form }) => (
												<FormControl isInvalid={form.errors.serviceAnimalRequired && form.touched.serviceAnimalRequired}>
													<FormLabel fontSize={'0.9em'} mb={0}>Is the animal a service animal required because of a disability?</FormLabel>
													<Input {...field} fontSize={'0.9em'} placeholder="" />
													<ErrorMessage name="firstName" component={ErrorMessageText} />
												</FormControl>
											)}
										</Field>
										<Field name="serviceAnimalTrained">
											{({ field, form }) => (
												<FormControl isInvalid={form.errors.serviceAnimalTrained && form.touched.serviceAnimalTrained}>
													<FormLabel fontSize={'0.9em'} mb={0}>What task or service is the animal trained to perform?</FormLabel>
													<Input {...field} fontSize={'0.9em'} placeholder="" />
													<ErrorMessage name="serviceAnimalTrained" component={ErrorMessageText} />
												</FormControl>
											)}
										</Field>
										<Text>For more information about service animals in the Commonwealth of Massachusetts, please refer to <Link href="https://www.mass.gov/info-details/massachusetts-law-about-service-animals" target="_blank" rel="noreferrer" textDecoration="underline">mass.gov</Link>.</Text>
										<Button h={"50px"} mt={6} mb={6} type="submit" colorScheme="dmOrange">
											Agree and Continue
										</Button>
									</Flex>
								)}

								{values.bringDog === 'Yes' && (
									<>
										<FormControl isInvalid={!!(errors.numberOfDogs && touched.numberOfDogs)}>

											<Flex w={'100%'} flexDirection={'row'} alignItems={'center'} mb={4}>
												<FormLabel fontSize={'1em'} mb={0}>How many dogs?</FormLabel>

												<Field name="numberOfDogs">
													{({ field }) => (
														<NumberInput
															{...field}
															onChange={(value) => setFieldValue("numberOfDogs", value)}
															value={field.value ?? 1}
															maxW={"100px"}
															max={reservationData.maxDogs ?? 3}
															min='1'
															defaultValue={reservationData.maxDogs ?? 1}
														>
															<NumberInputField fontSize={'0.9em'} placeholder="#" />
															<NumberInputStepper>
																<NumberIncrementStepper />
																<NumberDecrementStepper />
															</NumberInputStepper>
														</NumberInput>
													)}
												</Field>
												{dogFeePossible() &&
													<Text fontSize={'1em'} py={4} pl={2} fontWeight={'500'}> x ${perDogFee} per dog x {numNights} nights</Text>
												}
											</Flex>

											<ErrorMessage name="numberOfDogs" component={ErrorMessageText} />
										</FormControl>

										{/* Display a message if the number of dogs is 3 */}
										{values.numberOfDogs === "3" && reservationData.maxDogs === null && (
											<Text color="red.500" fontSize="md" fontWeight='500' pt={2}>
												Please contact guest services if you would like to bring more than 3 dogs at 508-247-0395.
											</Text>
										)}
										{reservationData.maxDogs !== null && (
											<Text color="green.500" fontSize="md" fontWeight='500' pb={2}>
												Your request for up to {reservationData.maxDogs} dogs has been approved. Please continue with payment.
											</Text>
										)}
										{reservationData.dogFeePaid && reservationData.dogFeePaid > 0 && values.numberOfDogs > 0 && dogFeePossible() ?
											<Flex w={'300px'} maxW={'100vw'} flexDirection={'column'}>
												<Flex fontSize={'lg'} pt={4} justifyContent={'space-between'} w={'100%'}>
													<Text>Total Dog Fee to be Paid: </Text>
													<Text>${totalDogFee}</Text>
												</Flex>
												<Flex fontSize={'lg'} pt={2} justifyContent={'space-between'} w={'100%'}>
													<Text>Dog Fee Already Paid: </Text>
													<Text>${reservationData.dogFeePaid}</Text>
												</Flex>
												<Flex fontSize={'lg'} pt={2} pb={8} justifyContent={'space-between'} w={'100%'} fontWeight={'bold'}>
													<Text>Remaining Payment Due: </Text>
													<Text>${totalDogFee - reservationData.dogFeePaid}</Text>
												</Flex>
											</Flex>
											: dogFeePossible() &&
											<Flex w={'300px'} maxW={'100vw'} justifyContent={'space-between'} fontSize={'lg'} py={4} fontWeight={'bold'}>
												<Text>Total Dog Fee to be Paid: </Text>
												<Text>${totalDogFee}</Text>
											</Flex>
										}
									</>
								)}

							</Box>

							{/* Stripe Payment Section */}
							{dogBoolean &&
								<Box>
									<Stack spacing="3">
										{dogFeePossible() && (totalDogFee - (reservationData.dogFeePaid || 0)) > 0 &&
											<Box>
												<FormLabel fontSize={'0.9em'} mb={1}>Credit Card</FormLabel>
												<Flex w={'100%'} justifyContent={'space-between'} gap={2}>
													<Box border="1px solid rgb(226, 232, 240)" p={2.5} borderRadius={8} w={'60%'}>
														<CardNumberElement
															options={CARD_ELEMENT_OPTIONS}
														/>
													</Box>
													<Box border="1px solid rgb(226, 232, 240)" p={2.5} borderRadius={8} w={'20%'}>
														<CardExpiryElement
															options={CARD_ELEMENT_OPTIONS}
														/>
													</Box>
													<Box border="1px solid rgb(226, 232, 240)" p={2.5} borderRadius={8} w={'20%'}>
														<CardCvcElement
															options={CARD_ELEMENT_OPTIONS}
														/>
													</Box>
												</Flex>
											</Box>
										}

										{/* Name fields */}
										<Stack direction={{ base: 'column', md: 'row' }}>
											<Field name="firstName">
												{({ field, form }) => (
													<FormControl isInvalid={form.errors.firstName && form.touched.firstName}>
														<FormLabel fontSize={'0.9em'} mb={0}>First Name</FormLabel>
														<Input {...field} fontSize={'0.9em'} placeholder="First Name" />
														<ErrorMessage name="firstName" component={ErrorMessageText} />
													</FormControl>
												)}
											</Field>
											<Field name="lastName">
												{({ field, form }) => (
													<FormControl isInvalid={form.errors.lastName && form.touched.lastName}>
														<FormLabel fontSize={'0.9em'} mb={0}>Last Name</FormLabel>
														<Input {...field} fontSize={'0.9em'} placeholder="Last Name" />
														<ErrorMessage name="lastName" component={ErrorMessageText} />
													</FormControl>
												)}
											</Field>
										</Stack>

										{/* Email and Phone fields */}
										<Stack direction={{ base: 'column', md: 'row' }}>
											<Field name="email">
												{({ field, form }) => (
													<FormControl isInvalid={form.errors.email && form.touched.email}>
														<FormLabel fontSize={'0.9em'} mb={0}>Email</FormLabel>
														<InputGroup>
															<InputLeftElement pointerEvents='none'>
																<Icon as={MdEmail} color='gray.300' />
															</InputLeftElement>
															<Input {...field} fontSize={'0.9em'} placeholder="Email" />
														</InputGroup>
														<ErrorMessage name="email" component={ErrorMessageText} />
													</FormControl>
												)}
											</Field>
											<Field name="phone">
												{({ form }) => (
													<FormControl isInvalid={form.errors.phone && form.touched.phone}>
														<FormLabel fontSize={'0.9em'} mb={0}>Cell Phone</FormLabel>
														<InputGroup>
															<InputLeftElement pointerEvents='none'>
																<Icon as={FaPhoneAlt} color='gray.300' />
															</InputLeftElement>
															<PhoneInput
																placeholder="Cell Phone"
																defaultCountry="US"
																value={phone}
																onChange={(value) => {
																	setPhone(value);
																	setFieldValue("phone", value);
																}}
																onBlur={() => {
																	setFieldTouched("phone", true, false);
																}}
																style={{ "width": "100%", "height": "40px", "border": "solid 1px rgb(226, 232, 240)", "paddingLeft": "10px" }}
															/>
														</InputGroup>
														<ErrorMessage name="phone" component={ErrorMessageText} />
													</FormControl>
												)}
											</Field>
										</Stack>

										{/* Address fields */}
										<Stack spacing="3" direction={{ base: 'column', md: 'row' }}>
											<Field name="street">
												{({ field, form }) => (
													<FormControl isInvalid={form.errors.street && form.touched.street}>
														<FormLabel fontSize={'0.9em'} mb={0}>Street</FormLabel>
														<Input {...field} fontSize={'0.9em'} placeholder="Street" />
														<ErrorMessage name="street" component={ErrorMessageText} />
													</FormControl>
												)}
											</Field>
											<Field name="city">
												{({ field, form }) => (
													<FormControl isInvalid={form.errors.city && form.touched.city}>
														<FormLabel fontSize={'0.9em'} mb={0}>City</FormLabel>
														<Input {...field} fontSize={'0.9em'} placeholder="City" />
														<ErrorMessage name="city" component={ErrorMessageText} />
													</FormControl>
												)}
											</Field>
											<Field name="state">
												{({ field, form }) => (
													<FormControl isInvalid={form.errors.state && form.touched.state}>
														<FormLabel fontSize={'0.9em'} mb={0}>State</FormLabel>
														<Input {...field} fontSize={'0.9em'} placeholder="State / Province" />
														<ErrorMessage name="state" component={ErrorMessageText} />
													</FormControl>
												)}
											</Field>
											<Field name="zip">
												{({ field, form }) => (
													<FormControl isInvalid={form.errors.zip && form.touched.zip}>
														<FormLabel fontSize={'0.9em'} mb={0}>ZIP</FormLabel>
														<Input {...field} fontSize={'0.9em'} placeholder="ZIP / Postal Code" />
														<ErrorMessage name="zip" component={ErrorMessageText} />
													</FormControl>
												)}
											</Field>
										</Stack>
									</Stack>

									{/* Disclaimer text */}
									{dogFeePossible() && (totalDogFee - (reservationData.dogFeePaid || 0)) > 0 ?
										<>
											<Text mt={5} fontSize={'0.9em'}>
												<i>This Dog Agreement once paid and eSigned is an amendment to your Guest agreement. By clicking the Agree and Pay button below, you authorize the above charges and understand that you and the dog owner are responsible for the registered dog at all times and accept full legal liability for any damage or harm the animal may do to the property or persons on and near the property. If you do not adhere to the dog rules or you violate this provision with respect to other animals, your Security Authorization Hold will be retained and your dog may be removed from the property.</i>
											</Text>
											<Button mt={3} colorScheme="dmOrange" type="submit" disabled={isSubmitting || !stripe}>
												Agree and Pay ${totalDogFee - (reservationData.dogFeePaid || 0)}
											</Button>
										</>
										:
										<>
											<Text mt={5} fontSize={'0.9em'}>
												<i>This Dog Agreement once eSigned is an amendment to your Guest agreement. By clicking the Agree button below, you understand that you and the dog owner are responsible for the registered dog at all times and accept full legal liability for any damage or harm the animal may do to the property or persons on and near the property. If you do not adhere to the dog rules or you violate this provision with respect to other animals, your Security Authorization Hold will be retained and your dog may be removed from the property.</i>
											</Text>
											<Button mt={3} colorScheme="dmOrange" type="submit" disabled={isSubmitting}>
												Agree
											</Button>
										</>
									}



									<Box mt={5} p={5} maxHeight={'200px'} overflowY={'scroll'} fontSize={'0.8em'} border={'solid 1px rgb(226, 232, 240)'} borderRadius={'0.375rem'}>
										<VStack spacing={4} align="stretch">
											<Heading as="h2" size="md">Dog Rules</Heading>
											<OrderedList>
												<ListItem>Dogs may not bark so as to cause noise complaints.</ListItem>
												<ListItem>Dogs are not allowed on the beds or furniture.</ListItem>
												<ListItem>Dogs may not cause damage and their presence cannot result in extra cleaning being required.</ListItem>
												<ListItem>You are responsible to remove any and all pet waste from the exterior of the property.</ListItem>
												<ListItem>Dog owners are required to crate their dogs when left home alone, for the safety of the dog and service staff.</ListItem>
											</OrderedList>
										</VStack>
									</Box>
								</Box>
							}
						</Form>
					)
				}}
			</Formik>

			<Modal isOpen={isModalOpen} isCentered size="lg" onClose={() => { }}>
				<ModalOverlay />
				<ModalContent>
					<Flex direction="column" align="center" justify="center" p={5} mt={5} mb={10}>
						{modalContent.isLoading ? <Spinner size="xl" /> : null}
						<Text mt={4}>{modalContent.message}</Text>
					</Flex>
				</ModalContent>
				<ModalFooter>
					<Button colorScheme={'dmOrange'} mt={3} onClick={() => setIsModalOpen(false)} isDisabled={modalContent.isLoading}>
						Close
					</Button>
				</ModalFooter>
			</Modal>

		</>
	);
};

export default DogPaymentForm;
