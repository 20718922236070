import { Flex, Spinner } from "@chakra-ui/react";
import * as React from "react";
import { fetchHouseDetails } from "../../helpers/fetch";
import { HouseCard } from "../HouseCard";

import { useReservation } from '../../contexts/ReservationContext';
import { SearchResultsContext } from '../../contexts/SearchResultsContext';

const ReservationSummary = () => {

	const { reservationData } = useReservation();

	const [houseData, setHouseData] = React.useState(null);
	const [houseDataLoading, setHouseDataLoading] = React.useState(true);

	const { loading: searchContextLoading } = React.useContext(SearchResultsContext);

	React.useEffect(() => {
		const fetchHouseData = async () => {
			try {
				//console.log("Fetching data for", reservationData.houseNumber);
				const params = { houseNumber: reservationData.houseNumber };

				setHouseDataLoading(true);

				const response = await fetchHouseDetails(params);

				if (!response.ok) {
					throw new Error(`fetchHouseDetails Error! Status: ${response.status}`);
				}

				const returnedHouseData = await response.json();
				setHouseData(returnedHouseData.results);
				//console.log("returnedHouseData", returnedHouseData.results)


			} catch (error) {
				console.error("Error fetching house data from DB:", error);
			} finally {
				setHouseDataLoading(false);
				//console.log("returnedHouseData", houseData);
			}
		};

		fetchHouseData();
	}, [reservationData.houseNumber])

	return (
		<Flex
			position={"relative"}
			width={"full"}
			data-id={"listing"}
			justifyContent={'center'}
			flexDirection={'column'}
			alignItems={'center'}
		>
			{/* Top Bar */}
			{(!houseDataLoading && !searchContextLoading) ?
				<HouseCard houseData={houseData} />
				:
				<Spinner />
			}
		</Flex>
	)
}

export { ReservationSummary };

