import { Button, Flex, Spinner, Text } from "@chakra-ui/react";
import { useUser } from "@clerk/clerk-react";
import { useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useLocation, useNavigate } from "react-router-dom";
import { CustomLogin } from "../components/confirmation/CustomLogin";
import { DogAgreement } from "../components/confirmation/DogAgreement";
import { GuestAgreement } from "../components/confirmation/GuestAgreement";
import { InvalidReservation } from "../components/confirmation/InvalidReservation";
import { useReservation } from "../contexts/ReservationContext";

const Confirmation = () => {
  const {
    reservationData,
    updateReservationData,
    isReservationContextValid,
    setReservationContextValid,
    isReservationContextLoaded,
    setReservationContextLoaded,
  } = useReservation();

  // This is a Clerk method that grabs the user from the session
  const { user } = useUser();
  // This is a native React method to grab the URL (for the reservation ID)
  const { pathname } = useLocation();

  const navigate = useNavigate();
  const returnToHome = async () => {
    navigate(`/`);
  };

  // --- Step 1:
  // On Confirmation page load, we grab the reservation number from the URL to fetch reservation data from the DB

  useEffect(() => {
    // A. Get the reservationNumber from the pathname
    let reservationNumber = null;

    // B. Make sure the URL is correct first, then grab the reservationNumber from it if it is
    if (pathname.includes("/confirmation")) {
      const splitPath = pathname.split("/confirmation/");
      //console.log("splitPath",splitPath)
      if (splitPath.length === 2) {
        reservationNumber = splitPath[1];
        updateReservationData(reservationNumber).catch(console.error);
        //console.log("Updating Reservation Data (Context)")
      } else {
        setReservationContextValid(false);
        setReservationContextLoaded(true);

        return;
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  // Note: Steps 2 and 3 are moved to CustomLogin.tsx

  return (
    <Flex
      position={"relative"}
      width={"full"}
      data-id={"listing"}
      p={{ base: 2, md: 5 }}
      justifyContent={"top"}
      flexDirection={"column"}
      alignItems={"center"}
      minH={{ base: "calc(100vh - 150px)", md: "calc(100vh - 230px)" }}
    >
      {/* Top Bar */}
      <Flex
        maxWidth="1124px"
        width={"100%"}
        p={2}
        textAlign={"left"}
        alignItems={"start"}
        flexDirection={"column"}
      >
        {!isReservationContextLoaded ? (
          <Flex w={"100%"} justifyContent={"center"}>
            <Spinner />
          </Flex>
        ) : !reservationData &&
          (isReservationContextLoaded || !isReservationContextValid) ? (
          <InvalidReservation />
        ) : reservationData &&
          reservationData.guestAgreementSigned &&
          reservationData.dogAgreementSigned ? (
          <>
            <Text
              pt={3}
              w={"100%"}
              textAlign={"center"}
              fontSize={"1.4em"}
              fontWeight={"700"}
            >
              Congrats! You're going on vacation!
            </Text>
            <CustomLogin user={user} />
          </>
        ) : !reservationData.guestAgreementSigned ? (
          <GuestAgreement />
        ) : !reservationData.dogAgreementSigned ? (
          <DogAgreement />
        ) : (
          <Button
            h={"50px"}
            mt={6}
            mb={6}
            onClick={returnToHome}
            colorScheme="dmOrange"
          >
            Return to Home
          </Button>
        )}
      </Flex>
    </Flex>
  );
};

export { Confirmation };

