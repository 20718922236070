import Cookies from "js-cookie";
import config from "../config";

const defaultHeaders = () => {
  const token = Cookies.get("__session");
  return {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
};

const API_ENDPOINT = config.apiEndpoint;


export const fetchGlobalCalendar = () => {
  return fetch(`${API_ENDPOINT}/listings/calendarAvailability`, {
    method: "GET",
    headers: defaultHeaders(),
  });
};