import { Alert, AlertIcon, Box, Button, Flex, Grid, GridItem, Link, NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper, Text, useDisclosure } from "@chakra-ui/react";
import { useReservation } from '../../contexts/ReservationContext';
import { calculateDaysBetweenDates, formatCurrency, formatDateTimeString } from '../../helpers/helpers';
import { updateReservation } from "../../helpers/reservationService";
import { GuestAgreementSignature } from './GuestAgreementSignature';

import { useState } from 'react';
import { PaymentScheduleWidget } from "../guestportal/PaymentScheduleWidget";

const GuestAgreement = () => {
	const { reservationData, updateReservationData, isReservationContextLoaded } = useReservation();
	console.log("reservationData", reservationData);
	const [errorMessage, setErrorMessage] = useState("");
	const [successMessage, setSuccessMessage] = useState("");

	const { isOpen: isPaymentScheduleOpen, onOpen: onPaymentScheduleOpen, onClose: onPaymentScheduleClose } = useDisclosure(); // Payment Schedule Modal

	// User can update number of booked guests on the reservation
	// This updates the reservation context, as well as calls the function to update Guesty and the DB
	const [isEditingGuests, setIsEditingGuests] = useState(false);
	const [isSubmittingGuests, setIsSubmittingGuests] = useState(false);
	const [guestCount, setGuestCount] = useState(reservationData.adults);

	const toggleEditGuests = () => setIsEditingGuests(!isEditingGuests);

	const submitNewGuestCount = async () => {
		//console.log("Triggered submitNewGuestCount");
		setErrorMessage("");
		setSuccessMessage("");
		if (guestCount !== reservationData.adults) {
			setIsSubmittingGuests(true);
			try {
				const response = await updateReservation(reservationData.reservationNumber, { adults: guestCount });
				if (!response.ok) {
					throw new Error('Network response was not ok');
				}
				let guestResponse = await response.json();

				updateReservationData(reservationData.reservationNumber);

				// Update local state to reflect the new guest count
				setGuestCount(guestResponse.adults);
				setSuccessMessage("Number of guests updated. Please complete and submit the Guest Agreement.");

			} catch (error) {
				console.error("Error updating reservation:", error);
				setErrorMessage("Failed to update reservation. Please try again."); // Set an error message
				// Optionally, handle errors (e.g., show an error message to the user)
			} finally {
				setIsSubmittingGuests(false);
			}
			toggleEditGuests();
		} else {
			toggleEditGuests();
		}
	};

	const numNights = calculateDaysBetweenDates(reservationData.checkinDate, reservationData.checkoutDate);

	// Reservation Summary Rows
	const InfoRow = ({ label, value }) => (
		label === 'Booked Via' && (reservationData.channel === 'Standard' || reservationData.typename === 'BE-API' || reservationData.typename === 'Direct' || reservationData.typename === 'website') ?
			<></>
			: label === 'Spacer' ?
				<Box height="10px" /> // Adjust the height as needed for the spacer
				: !label ?
					<Grid templateColumns="7fr" gap={2}>
						<Text>{value}</Text>
					</Grid>
					: label === 'Booked Occupants' ?
						<Grid templateColumns="3fr 4fr" gap={2}>
							<Text fontWeight={500}>{label}:</Text>
							<Flex gap={2} alignItems={'top'}>
								{isEditingGuests ? (
									<Flex alignItems={'center'} gap={3}>
										<NumberInput
											size="sm"
											defaultValue={guestCount}
											value={guestCount}
											onChange={(valueString) => setGuestCount(parseInt(valueString))}
											min={1} // Set the minimum value as needed
											max={reservationData.house.maxOccupancy} // Set the maximum value as needed
											maxW={'80px'}
										>
											<NumberInputField />
											<NumberInputStepper>
												<NumberIncrementStepper />
												<NumberDecrementStepper />
											</NumberInputStepper>
										</NumberInput>

										<Button
											colorScheme={'dmOrange'}
											size={'xs'}
											onClick={submitNewGuestCount}
											isLoading={isSubmittingGuests} // Disable button and show loading indicator while updating
										>
											Submit
										</Button>
									</Flex>
								) : (
									<>
										<Text>{value}</Text>
										<Button colorScheme={'dmOrange'} size={'xs'} onClick={toggleEditGuests}>Edit Guests</Button>
									</>
								)}
							</Flex>
						</Grid>
						:
						<Grid templateColumns="3fr 4fr" gap={2}>
							<Text fontWeight={500}>{label}:</Text>
							<Text>{value}</Text>
						</Grid>
	);

	// Splitting data into two columns
	const firstColumnData = [
		{ label: "Confirmation #", value: reservationData.reservationNumber },
		{ label: "Spacer" },
		{ label: "Max Occupants", value: reservationData.house.maxOccupancy },
		{ label: "Bedrooms", value: reservationData.house.bedrooms },
		{ label: "Bathrooms", value: reservationData.house.bathrooms },
		{ label: "Spacer" },
		{ label: "Booked Occupants", value: reservationData.adults },
	];

	// Define a helper function to format the second column data based on reservationData.typename
	const formatSecondColumnData = () => {
		const baseData = [
			{
				label: "Check-In Date",
				value: reservationData.checkinDate && formatDateTimeString(reservationData.checkinDate, reservationData.checkinTime)
			},
			{
				label: "Check-Out Date",
				value: reservationData.checkoutDate && formatDateTimeString(reservationData.checkoutDate, reservationData.checkoutTime)
			},
			{ label: "Spacer" },
		];

		// If reservationData.typename indicates a direct booking or BE-API
		if (["manual", "direct", "website", "be-api"].includes(reservationData.typename.toLowerCase())) {
			// Add the standard pricing details
			baseData.push(
				{ label: `${numNights} Nights`, value: formatCurrency(reservationData.nightlyRates) },
				{ label: "Taxes and Fees", value: formatCurrency(reservationData.totalPrice - reservationData.nightlyRates) },
				{ label: "Total Price", value: formatCurrency(reservationData.totalPrice) }
			);
		} else if (reservationData.typename.includes("VRBO")) {
			// For VRBO bookings, only show total price and a specific message
			baseData.push(
				{ label: "Total Price", value: formatCurrency(reservationData.totalPrice) },
				{ label: "Spacer" },
				{ label: "Note", value: "Since you booked via VRBO, you are also charged a booking fee by VRBO that Del Mar Vacations does not collect and therefore is not reflected in the above total." }
			);
		} else {
			// For other third-party bookings, display a generic message
			baseData.push(
				{ label: "Note", value: "Since you booked through a third party booking channel, Del Mar Vacations does not collect your rental payments. Please refer back to your booking site for payment terms and information." }
			);
		}

		return baseData;
	};

	const secondColumnData = formatSecondColumnData();

	const typenameMapper = (typename) => {
		switch (typename.toLowerCase()) {
			case 'airbnb2':
				return "Airbnb";
			case 'manual':
			case 'direct':
			case 'be-api':
			case 'website':
				return "Direct";
			case 'vrbo':
			case 'uploaded_vrbo':
				return "VRBO";
			case 'booking.com':
				return "Booking.com";
			case 'homeaway us':
			case 'homeaway ca':
			case 'homeaway de':
			case 'homeaway cafr':
				return "HomeAway";
			default:
				return typename;
		}
	}


	return (
		isReservationContextLoaded &&
		<>
			{errorMessage && <Alert status="error"><AlertIcon />{errorMessage}</Alert>}
			{successMessage && <Alert status="success"><AlertIcon />{successMessage}</Alert>}
			<Flex
				position={"relative"}
				width={"full"}
				data-id={"listing"}
				p={{ base: 2, md: 5 }}
				justifyContent={'center'}
				flexDirection={'column'}
				alignItems={'center'}
			>

				<Flex maxWidth='1440px' width={'100%'} p={2} textAlign={'left'} alignItems={'start'} flexDirection={'column'}>

					<Text pb={3} w={'100%'} textAlign={'center'} fontSize={'1.4em'} fontWeight={'700'}>
						{reservationData.firstName} {reservationData.lastName}'s Vacation Rental Agreement for
					</Text>
					<Text pb={6} w={'100%'} textAlign={'center'} fontSize={'1.1em'} >
						#{reservationData.house.houseNumber} {reservationData.house.name} at {reservationData.house.address.split(', United States')[0]}
					</Text>

					{/* Reservation Summary */}
					<Grid templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }}
						gap={{ md: 8, lg: 4, xl: 2 }} fontSize={'0.9em'}
						width={'100%'}
					>
						<GridItem pb={{ base: 3, md: 0 }}>
							{firstColumnData.map((item, index) => (
								<InfoRow key={index} label={item.label} value={item.value} />
							))}
						</GridItem>
						<GridItem>
							{secondColumnData.map((item, index) => (
								<InfoRow key={index} label={item.label} value={item.value} />
							))}
						</GridItem>
					</Grid>
					{/* END Reservation Summary */}

					{reservationData.pricingType ? (
						<Flex direction={'column'} fontSize={"0.9em"}>
							{(reservationData.pricingType === 'rebook-1' || reservationData.pricingType === 'rebook-2' || reservationData.pricingType === 'rebook-3') &&
								<Alert status='success' mt={6} fontWeight={500}><AlertIcon />By rebooking directly with Del Mar, you saved 10-20% in fees as compared to if you had booked on 3rd party websites! Same Local Service; Same Homes; Lower Costs!</Alert>
							}
							{reservationData.pricingType?.includes('rebook') ? (
								<Text fontWeight={"500"} pt={4}>
									Del Mar VIP Vacation Pass Member Payment Terms:
								</Text>
							) : (
								<Text fontWeight={"500"} pt={4}>
									Payment Terms:
								</Text>
							)
							}
							{reservationData.pricingType === 'full' &&
								<Text>
									As you booked with less than 60 days to check in, the entirety of your payment is charged in full on the day of booking.
								</Text>
							}
							{reservationData.pricingType === 'rebook-1' &&
								<Text>
									As you booked with less than 60 days to check in, the entirety of your payment is charged in full on the day of booking.
								</Text>
							}
							{reservationData.pricingType === 'full-save' &&
								<Text>
									As you elected to pay in full today for your reservation, the entirety of your payment will be charged in full on the day of booking. A 2% discount has been applied.
								</Text>
							}
							{reservationData.pricingType === 'standard' &&
								<Text>
									50% of your total payment is charged on the day of booking, and 50% of your total payment 60 days prior to check-in.
								</Text>
							}
							{reservationData.pricingType === 'rebook-2' &&
								<Text>
									50% of your total payment is charged on the day of booking, and 50% of your total payment 60 days prior to check-in.
								</Text>
							}
							{reservationData.pricingType === 'bnpl' &&
								<Text>
									You have elected to use our monthly payment plan. You will be charged in equal payments starting today until the month prior to your check in.&nbsp;
									{reservationData?.paymentSchedule &&
										<Link as="span" textDecoration={'underline'} onClick={onPaymentScheduleOpen}>View Payment Schedule</Link>
									}
								</Text>
							}
							{reservationData.pricingType === 'rebook-3' &&
								<Text>
									You will have 3 scheduled payments. The first payment is $300, due upon booking. The second payment 50% of the remaining balance, due 180 days prior check in. The third and final payment is the remaining balance, due 60 days prior to check in.
								</Text>
							}
						</Flex>
					) : (
						["manual", "Direct", "BE-API", "direct", "website", "be-api", "standard"].includes(reservationData.typename.toLowerCase())) ? (
						<>
							{reservationData.rebook ? (
								<>
									<Alert status='success' mt={6} fontSize={'0.9em'} fontWeight={500}><AlertIcon />By rebooking directly with Del Mar, you saved 10-20% in fees as compared to if you had booked on 3rd party websites! Same Local Service; Same Homes; Lower Costs!</Alert>
									<Text fontWeight={"bold"} pt={4} fontSize={"0.9em"}>
										Del Mar VIP Vacation Pass Member Payment Schedule:
									</Text>
									<Text pt={2} fontSize={"0.9em"} fontWeight={"bold"} >Reservation Rebook 6+ Months in Advance</Text>
									<Text pt={0} fontSize={"0.9em"}>You will have 3 scheduled payments. The first payment is $300, due upon booking. The second payment 50% of the remaining balance, due 180 days prior check in. The third and final payment is the remaining balance, due 60 days prior to check in.</Text>
									<Text pt={2} fontSize={"0.9em"} fontWeight={"bold"} >Reservation Rebook Less Than 6 Months in Advance</Text>
									<Text pt={0} fontSize={"0.9em"}>Upon booking confirmation, 51% of the total reservation cost will be charged to the credit card on file. The remaining balance will be charged 60 days prior to the check-in date. If the reservation is booked within 60 days of check-in, the total reservation cost will be charged in full upon booking confirmation.</Text>
								</>
							) : (
								<>
									<Alert status='success' mt={6} fontSize={'0.9em'} fontWeight={500}><AlertIcon />By booking directly with Del Mar, you saved 10-20% in fees as compared to if you had booked on 3rd party websites! Same Local Service; Same Homes; Lower Costs!</Alert>
									<Text pt={4} fontSize={'0.9em'} fontWeight={500}>Payment Schedule:</Text>
									<Text fontSize={'0.9em'}>Upon booking confirmation, 51% of the total reservation cost will be charged to the credit card on file. The remaining balance will be charged 60 days prior to the check-in date. If the reservation is booked within 60 days of check-in, the total reservation cost will be charged in full upon booking confirmation.</Text>
								</>
							)}
						</>
					) : (
						<>
							<Text pt={6} fontSize={'0.9em'} fontWeight={500}>Booked Via: {typenameMapper(reservationData.typename)}</Text>
							<Text pt={1} fontSize={'0.9em'} fontWeight={500}>Payment Schedule:</Text>
							<Text fontSize={'0.9em'}>Since you booked with a 3rd party website, your payment schedule is according to their posted terms of service.</Text>
						</>
					)
					}

					{reservationData && reservationData.house.tags && reservationData.house.tags.includes('Dog Friendly') ?
						<>
							<Text pt={6} fontSize={'0.9em'} fontWeight={500}>Pet Policy:</Text>
							<Text fontSize={'0.9em'}>This home is dog friendly (no other animals are permitted), but requires payment for a dog guest unless a licensed Service Animal. Please confirm and pay for dogs on the next page or in your Guest Portal and review and adhere to all dog rules.</Text>
						</>
						:
						<>
							<Text pt={6} fontSize={'0.9em'} fontWeight={500}>No Animals Permitted:</Text>
							<Text fontSize={'0.9em'}>This home is not authorized for dogs or animals of any kind. A fine of $1,000 and removal of your pet will occur for non-compliance. Service animals unfortunately are also not authorized at this home - however, if you have a documented Service Animal then it is authorized at one of our Dog Friendly homes and we will waive the Dog Fee but you must provide notice and documentation. Please call us to change to a dog or service animal friendly home.</Text>
						</>
					}

					<GuestAgreementSignature mt={6} />

					{reservationData?.paymentSchedule &&
						<PaymentScheduleWidget
							modalInitiateOpens={isPaymentScheduleOpen}
							paymentSchedule={reservationData.paymentSchedule}
							onModalClose={onPaymentScheduleClose}
						/>
					}

				</Flex>
			</Flex>
		</>
	)
}

export { GuestAgreement };

