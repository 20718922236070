/* eslint-disable react-hooks/exhaustive-deps */
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import {
  Box, Button, Flex, Grid, Image,
  ListItem,
  Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay,
  Spinner, Text,
  UnorderedList,
  useDisclosure
} from "@chakra-ui/react";
import { useUser } from "@clerk/clerk-react";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import * as React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { animated, useTransition } from 'react-spring';
import config from "../../config";
import { useTrips } from "../../contexts/TripContext";
import { eventBus } from "../../helpers/EventBus";
import { fetchSupportManager } from "../../helpers/guestportalfetch";
import { Beach } from "./Beach";
import { CheckInInfo } from "./CheckInInfo";
import { CheckOutInfo } from "./CheckOutInfo";
import { DogAgreementPortal } from "./DogAgreementPortal";
import { FAQ } from "./FAQ";
import { GuestAgreementPortal } from "./GuestAgreementPortal";
import { GuestAgreements } from "./GuestAgreements";
import { GuestServicesPopOver } from "./GuestServicesPopOver";
import { HowThisHomeWorks } from "./HowThisHomeWorks";
import { RebookCalendar } from "./RebookCalendar";
import { Receipts } from "./Receipts";
import { ReportDamage } from "./ReportDamage";
import { ReviewModal } from "./ReviewModal";
import { ShareWidget } from "./ShareWidget";
import SupportManager from "./SupportManager";
import { TipMyCleaner } from "./TipMyCleaner";
import { CustomGridItem } from "./TripDetailsGridIcon";
import { WelcomeDetails } from "./WelcomeDetails";

const stripePromise = loadStripe(config.stripeKey)

type Props = {
  reservationIndex?: number;
  userEmail: string;
};

const TripDetails: React.FunctionComponent<Props> = ({
  reservationIndex: initialReservationIndex,
  userEmail,
}) => {

  const navigate = useNavigate();

  const { allTripsData, updateAllTrips, selectedTrip, setSelectedTrip, currentFutureTrips, isTripsContextLoaded } = useTrips();
  const [searchParams] = useSearchParams();

  const [reservationData] = React.useState(currentFutureTrips);
  const [activeReservation, setActiveReservation] = React.useState(selectedTrip);

  const [reservationIndex, setReservationIndex] = React.useState(initialReservationIndex || 0);
  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState(null);

  const { isOpen, onOpen, onClose: mainModalOnClose } = useDisclosure(); // Main Modal

  const [shareClickedOnce, setShareClickedOnce] = React.useState(false);
  const [rebookClickedOnce, setRebookClickedOnce] = React.useState(false);

  React.useEffect(() => {
    if (selectedTrip) {
      setActiveReservation(selectedTrip);
    }
  }, [selectedTrip]);

  React.useEffect(() => {
    // Assuming allTripsData is passed as a prop and contains the updated trips
    const updatedTrip = allTripsData.find(trip => trip.reservationNumber === selectedTrip.reservationNumber);
    if (updatedTrip) {
      setSelectedTrip(updatedTrip);
      setActiveReservation(updatedTrip);
    }

  }, [allTripsData, selectedTrip.reservationNumber]); // React to changes in allTripsData or the ID of the selected trip


  // Custom onClose for the "Dogs" modal
  const onCloseModalRefresh = () => {
    mainModalOnClose();
    //console.log('selectedItem',selectedItem)
    if (selectedItem === 'Add Dogs' || selectedItem === 'Guest Agreement' || selectedItem === 'Tip My Cleaner') {
      //console.log('Special Modal just closed');
      updateAllTrips();
      const updatedTrip = allTripsData.find(trip => trip.reservationNumber === selectedTrip.reservationNumber);
      if (updatedTrip) {
        setSelectedTrip(updatedTrip);
        setActiveReservation(updatedTrip);
      }
    }
  };

  const AnimatedBox = animated(Box);

  function reservationSelected(reservation) {
    const index = reservationData.indexOf(reservation);
    let houseNumber = reservationData[index]['houseNumber'];
    //console.log("Switched to Reservation",reservation);
    setSelectedTrip(reservationData[index]);
    setActiveReservation(reservationData[index]);
    setReservationIndex(reservationIndex);
    setDropdownOpen(false);

    eventBus.dispatch("houseData", { detail: { houseNumber: houseNumber, reservationNumber: reservationData[index].reservationNumber } });
    navigate(`/trips/${reservation.reservationNumber}`);
  }

  const transitions = useTransition(dropdownOpen, {
    from: { opacity: 0, transform: 'translateY(-10%)' },
    enter: { opacity: 1, transform: 'translateY(0)' },
    leave: { opacity: 0, transform: 'translateY(-10%)' },
  });

  const handleReportDamage = () => { setSelectedItem("Report Damage"); }
  const handleReportedDamage = () => { setSelectedItem("Check-Out Instructions"); }
  const handleSupportClose = () => { /*console.log("close support popover");*/ }


  // Support Manager Data
  const { isSignedIn, user } = useUser();

  interface Manager {
    name: string;
    title: string;
    avatar: string;
  }
  const [manager, setManager] = React.useState<Manager | null>(null);

  React.useEffect(() => {
    const fetchData = async () => {
      if (user && isSignedIn) {
        let userEmail = user.primaryEmailAddress.emailAddress;
        try {
          const response = await fetchSupportManager({ tenantEmail: userEmail });
          const managerInfo = await response.json();
          setManager(managerInfo[0]);
        } catch (error) {
          console.error('Error:', error);
        }
      }
    };

    fetchData();
  }, [user]);


  React.useEffect(() => {
    // Normalize function to remove spaces and hyphens and convert to lower case
    const normalize = (str) => str.replace(/[\s-]/g, '').toLowerCase();

    // Check URL params to open a modal by default based on the open parameter
    const openParam = searchParams.get('open');
    const validItems = [
      'Guest Agreement', 'Add Dogs', 'Share', 'Rebook', 'Tip My Cleaner',
      'FAQs', 'Check-Out Instructions', 'Access Information', 'Report Damage',
      'Beach Stickers', 'Home Guide', 'Pre-Arrival Guide', 'House Rules', 'Help', 'My Receipts'
    ];

    if (openParam) {
      const normalizedOpenParam = normalize(openParam);
      const matchedItem = validItems.find(item => normalize(item) === normalizedOpenParam);

      if (matchedItem) {
        setSelectedItem(matchedItem);
        onOpen();
      }
    }
  }, [searchParams, onOpen]);


  React.useEffect(() => {
    // Check session storage to see if the item has been clicked before
    const hasShareBeenClicked = localStorage.getItem('shareClicked');

    if (hasShareBeenClicked) {
      setShareClickedOnce(true);
    }
  }, []);

  const handleShareItemClick = () => {
    if (!shareClickedOnce) {
      // Update session storage to indicate the item has been clicked
      localStorage.setItem('shareClicked', 'true');
      setShareClickedOnce(true);
    }
  };

  React.useEffect(() => {
    // Check session storage to see if the item has been clicked before
    const hasRebookBeenClicked = localStorage.getItem('rebookClicked');

    if (hasRebookBeenClicked) {
      setRebookClickedOnce(true);
    }
  }, []);

  const handleRebookItemClick = () => {
    if (!rebookClickedOnce) {
      // Update session storage to indicate the item has been clicked
      localStorage.setItem('rebookClicked', 'true');
      setRebookClickedOnce(true);
    }
  };

  return (
    <Flex textAlign="center" fontSize="xl" bg={"white"}
      minH={activeReservation ? '1100px' : '70vh'}
      w={'100%'}
    >
      {(!isTripsContextLoaded || !selectedTrip) ? (
        <Flex width="100%" justifyContent={'center'} alignItems={'center'}>
          <Spinner size='xl' />
        </Flex>
      ) : (
        <>
          <Flex
            width={'100%'}
            flexDirection={'column'}
          >
            <Flex direction={"column"} alignItems={"start"} position="relative" textAlign={'start'} px={2}>
              <Flex direction={"column"} position="relative" w={'100%'}>
                {selectedTrip && (
                  <Box
                    cursor="pointer"
                    _hover={{ bg: "gray.100" }}
                    bg={"white"}
                    pr={{ base: 2, sm: 6 }}
                    w={{ base: '100%' }}
                    onClick={() => setDropdownOpen(!dropdownOpen)}
                    border={'solid 1px rgba(83, 180, 255, 1)'}
                    borderRadius="16px"
                    boxShadow={'dmShadow'}
                    overflow='hidden'
                  >
                    <Flex height={'100%'} borderRadius="16px" overflow='hidden'>
                      <Image
                        objectFit="cover"
                        src={selectedTrip?.house?.images[0]?.link}
                        alt="House"
                        mr={3}
                        height={'100%'}
                        maxW={'80px'}
                      />
                      <Flex direction={'column'} justifyContent="center" fontSize={{ base: 14, sm: 14, md: 16, lg: 16, xl: 16 }} my={3}>
                        <Text><b>Res #:</b> {selectedTrip.reservationNumber}</Text>
                        <Text mt={1}><b>Address:</b> {selectedTrip.house.address.split(', United States')[0]} </Text>
                      </Flex>
                      <Flex flexGrow={1} justifyContent="flex-end" alignItems="center">
                        {selectedTrip.length > 1 ? dropdownOpen ? <ChevronUpIcon /> : <ChevronDownIcon /> : <i />}
                      </Flex>
                    </Flex>
                  </Box>
                )}

                {transitions((styles, item) => item ? (
                  <AnimatedBox
                    style={styles}
                    border="1px solid"
                    borderColor="gray.200"
                    bg="gray.50"
                    position={"absolute"} top={"100%"}
                    zIndex={2}
                    w={'100%'}
                    _hover={{
                      backgroundColor: "rgba(83, 180, 255, 0.2)"
                    }}
                    borderRadius="16px"
                    boxShadow={'dmShadow'}
                    overflow={'hidden'}
                  >
                    {reservationData.length > 0 && (
                      reservationData
                        .filter((reservation) => reservation !== selectedTrip)
                        .map((reservation) => (
                          <Box
                            key={reservation.reservationNumber}
                            cursor="pointer"
                            _hover={{ bg: "gray.100" }}
                            bg={"white"}
                            mt={1} pr={{ base: 2, sm: 6 }}
                            w={{ base: '100%' }}
                            onClick={() => reservationSelected(reservation)}
                            border={'solid 1px #99999950'}
                            borderRadius="16px"
                            boxShadow={'dmShadow'}
                            zIndex={2}
                          >
                            <Flex height={'100%'}>
                              <Image
                                objectFit="cover"
                                src={reservation?.house?.images[0]?.link}
                                alt="House"
                                mr={3}
                                maxW={'80px'}
                              />
                              <Flex direction={'column'} justifyContent="center" fontSize={{ base: 14, sm: 14, md: 16, lg: 16, xl: 16 }} my={3}>
                                <Text><b>Res #:</b> {reservation.reservationNumber}</Text>
                                <Text mt={1}><b>Address:</b> {reservation.house.address.split(', United States')[0]} </Text>
                              </Flex>
                            </Flex>

                          </Box>
                        ))
                    )}
                  </AnimatedBox>
                ) : null
                )}
              </Flex>
            </Flex>

            <Text as="h2" px={{ base: 0, sm: 4 }}
              fontSize={{ base: 16, md: 20 }} fontWeight={'bold'}
              m={'32px auto 0px auto'} textAlign={'start'} w={'100%'} maxW={'500px'}
            >
              Before You Arrive
            </Text>

            <Grid
              h="auto" w="100%" maxW={'500px'}
              px={{ base: 0, sm: 4 }} pt={4} pb={4} margin="0 auto"
              templateRows="repeat(2, 1fr)"
              gap={{ base: 4, sm: 8 }} // Unified gap property for simplicity
              // Use a responsive setup for columns to allow wrapping
              templateColumns={{
                base: "repeat(auto-fill, minmax(70px, 1fr))", // Adjust minmax() as needed for your content
                sm: "repeat(auto-fill, minmax(80px, 1fr))", // Example breakpoint adjustment
              }}
            >
              <CustomGridItem setSelectedItem={setSelectedItem} onOpen={onOpen} bgImage='/media/whiteicons/guestagreement.png' title='Guest Agreement' status={activeReservation.guestAgreementSigned ? 'complete' : 'alert'} />
              <CustomGridItem setSelectedItem={setSelectedItem} onOpen={onOpen} bgImage='/media/whiteicons/dogs.png' title='Add Dogs' status={activeReservation.dogAgreementSigned ? 'complete' : 'alert'} />
              <CustomGridItem setSelectedItem={setSelectedItem} onOpen={onOpen} bgImage='/media/whiteicons/beachstickers.png' title='Beach Stickers' />
              <CustomGridItem setSelectedItem={setSelectedItem} onOpen={onOpen} bgImage='/media/whiteicons/prearrivalguide.png' title='Pre-Arrival Guide' />
              <CustomGridItem setSelectedItem={setSelectedItem} onOpen={onOpen} bgImage='/media/whiteicons/checkininfo.png' title='Access Information' />

              {/*
              <Box onClick={() => {handleExtrasItemClick(); navigate(`/store/${selectedTrip.reservationNumber}`)}}>
                <CustomGridItem setSelectedItem={setSelectedItem} onOpen={onOpen} bgImage='/media/whiteicons/shop.png' title='Shop' status={extrasClickedOnce ? 'complete' : 'alert'} />
              </Box>              
              <Box onClick={() => window.open(
                          "https://delmarvacations.myshopify.com/collections/products",
                          "_blank"
                        )}>
                <CustomGridItem setSelectedItem={setSelectedItem} onOpen={onOpen} bgImage='/media/whiteicons/merch.png' title='Merch' />
              </Box>
              */}
              <Box onClick={handleShareItemClick}>
                <CustomGridItem setSelectedItem={setSelectedItem} onOpen={onOpen} bgImage='/media/whiteicons/share.png' title='Share' status={shareClickedOnce ? 'complete' : 'alert'} />
              </Box>
              {/*<CustomGridItem setSelectedItem={setSelectedItem} onOpen={onOpen} bgImage='/media/whiteicons/help.png' title='Help' />*/}
            </Grid>

            <Text as="h2" px={{ base: 0, sm: 4 }}
              fontSize={{ base: 16, md: 20 }} fontWeight={'bold'}
              m={{ base: '0px auto 0px auto', md: '32px auto 0px auto' }} textAlign={'start'} w={'100%'} maxW={'500px'}
            >
              Before You Go
            </Text>

            <Grid
              h="auto" w="100%" maxW={'500px'}
              px={{ base: 0, sm: 4 }} pt={4} pb={4} margin="0 auto"
              templateRows="repeat(1, 1fr)"
              gap={{ base: 3, sm: 8 }} // Unified gap property for simplicity
              // Use a responsive setup for columns to allow wrapping
              templateColumns={{
                base: "repeat(auto-fill, minmax(70px, 1fr))", // Adjust minmax() as needed for your content
                sm: "repeat(auto-fill, minmax(80px, 1fr))", // Example breakpoint adjustment
              }}
            >
              {/* Allow Rebook if House Still Active */}
              {selectedTrip.house.activeListing &&
                <Box onClick={handleRebookItemClick}>
                  <CustomGridItem setSelectedItem={setSelectedItem} onOpen={onOpen} bgImage='/media/whiteicons/rebook.png' title='Rebook' status={rebookClickedOnce ? 'complete' : 'alert'} />
                </Box>
              }

              <CustomGridItem setSelectedItem={setSelectedItem} onOpen={onOpen} bgImage='/media/whiteicons/tipmycleaner.png' title='Tip My Cleaner' />
              <CustomGridItem setSelectedItem={setSelectedItem} onOpen={onOpen} bgImage='/media/whiteicons/review.png' title='Review' disable={activeReservation ? activeReservation['disableReview'] : true} />
              <CustomGridItem setSelectedItem={setSelectedItem} onOpen={onOpen} bgImage='/media/whiteicons/checkoutinstructions.png' title='Check-Out Instructions' />

              {/*
              <CustomGridItem setSelectedItem={setSelectedItem} onOpen={onOpen} bgImage='/media/whiteicons/homeguide.png' title='Home Guide' />
              <CustomGridItem setSelectedItem={setSelectedItem} onOpen={onOpen} bgImage='/media/whiteicons/houserules.png' title='House Rules' />
              */}

            </Grid>

            <Text as="h2" px={{ base: 0, sm: 4 }}
              fontSize={{ base: 16, md: 20 }} fontWeight={'bold'}
              m={{ base: '0px auto 0px auto', md: '32px auto 0px auto' }} textAlign={'start'} w={'100%'} maxW={'500px'}
            >
              Add-Ons
            </Text>

            <Grid
              h="auto" w="100%" maxW={'500px'}
              px={{ base: 0, sm: 4 }} pt={4} pb={4} margin="0 auto"
              templateRows="repeat(1, 1fr)"
              gap={{ base: 3, sm: 8 }} // Unified gap property for simplicity
              // Use a responsive setup for columns to allow wrapping
              templateColumns={{
                base: "repeat(auto-fill, minmax(70px, 1fr))", // Adjust minmax() as needed for your content
                sm: "repeat(auto-fill, minmax(80px, 1fr))", // Example breakpoint adjustment
              }}
            >
              <Box onClick={() => { navigate(`/store/${selectedTrip.reservationNumber}?category=Food%20%2B%20Drink%20Deliveries`) }}>
                <CustomGridItem setSelectedItem={setSelectedItem} onOpen={onOpen} bgImage='/media/whiteicons/fooddrink.png' title='Food + Drink' />
              </Box>
              <Box onClick={() => { navigate(`/store/${selectedTrip.reservationNumber}?category=Hospitality%20Services`) }}>
                <CustomGridItem setSelectedItem={setSelectedItem} onOpen={onOpen} bgImage='/media/whiteicons/hospitality.png' title='Hospitality Services' />
              </Box>
              <Box onClick={() => { navigate(`/store/${selectedTrip.reservationNumber}?category=Beach%20Gear%20Rentals`) }}>
                <CustomGridItem setSelectedItem={setSelectedItem} onOpen={onOpen} bgImage='/media/whiteicons/beachgear.png' title='Beach Gear Rentals' />
              </Box>
              <Box onClick={() => { navigate(`/store/${selectedTrip.reservationNumber}?category=Watersports%20Deliveries`) }}>
                <CustomGridItem setSelectedItem={setSelectedItem} onOpen={onOpen} bgImage='/media/whiteicons/watersports.png' title='Watersports Deliveries' />
              </Box>
              <Box onClick={() => window.open(
                "https://delmarvacations.myshopify.com/collections/products",
                "_blank"
              )}>
                <CustomGridItem setSelectedItem={setSelectedItem} onOpen={onOpen} bgImage='/media/whiteicons/apparel.png' title='Apparel + Accessories' />
              </Box>
              <CustomGridItem setSelectedItem={setSelectedItem} onOpen={onOpen} bgImage='/media/whiteicons/receipt.png' title='My Receipts' />
            </Grid>

            <Text as="h2" px={{ base: 0, sm: 4 }}
              fontSize={{ base: 16, md: 20 }} fontWeight={'bold'}
              m={{ base: '0px auto 0px auto', md: '32px auto 0px auto' }} textAlign={'start'} w={'100%'} maxW={'500px'}
            >
              Home Information
            </Text>

            <Grid
              h="auto" w="100%" maxW={'500px'}
              px={{ base: 0, sm: 4 }} pt={4} pb={4} margin="0 auto"
              templateRows="repeat(1, 1fr)"
              gap={{ base: 3, sm: 8 }} // Unified gap property for simplicity
              // Use a responsive setup for columns to allow wrapping
              templateColumns={{
                base: "repeat(auto-fill, minmax(70px, 1fr))", // Adjust minmax() as needed for your content
                sm: "repeat(auto-fill, minmax(80px, 1fr))", // Example breakpoint adjustment
              }}
            >
              <CustomGridItem setSelectedItem={setSelectedItem} onOpen={onOpen} bgImage='/media/whiteicons/homeguide.png' title='Home Guide' />
              <CustomGridItem setSelectedItem={setSelectedItem} onOpen={onOpen} bgImage='/media/whiteicons/houserules.png' title='House Rules' />
              <CustomGridItem setSelectedItem={setSelectedItem} onOpen={onOpen} bgImage='/media/whiteicons/faqs.png' title='FAQs' />
            </Grid>

            <Text as="h2" px={{ base: 0, sm: 4 }}
              fontSize={{ base: 16, md: 20 }} fontWeight={'bold'}
              m={{ base: '0px auto 0px auto', md: '32px auto 0px auto' }} textAlign={'start'} w={'100%'} maxW={'500px'}
            >
              Activities
            </Text>

            <Grid
              h="auto" w="100%" maxW={'500px'}
              px={{ base: 0, sm: 4 }} pt={4} pb={4} margin="0 auto"
              templateRows="repeat(2, 1fr)"
              gap={{ base: 3, sm: 8 }} // Unified gap property for simplicity
              // Use a responsive setup for columns to allow wrapping
              templateColumns={{
                base: "repeat(auto-fill, minmax(70px, 1fr))", // Adjust minmax() as needed for your content
                sm: "repeat(auto-fill, minmax(80px, 1fr))", // Example breakpoint adjustment
              }}
            >
              <CustomGridItem setSelectedItem={setSelectedItem} onOpen={onOpen} bgImage='/media/whiteicons/thingstodo.png' title='Things To Do' reservationNumber={selectedTrip ? selectedTrip.reservationNumber : null} />
              <CustomGridItem setSelectedItem={setSelectedItem} onOpen={onOpen} bgImage='/media/whiteicons/localdiscounts.png' title='Local Discounts' reservationNumber={selectedTrip ? selectedTrip.reservationNumber : null} />
            </Grid>
          </Flex>
        </>
      )}

      {selectedItem === 'Review' &&
        <ReviewModal
          onReviewClose={mainModalOnClose}
          reservationData={selectedTrip}
          modalInitiateOpen={true}
        />
      }

      {selectedItem === 'Guest Agreement' &&
        <Modal isOpen={isOpen} onClose={mainModalOnClose} size={{ base: 'full', md: '6xl' }}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Agreements</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {activeReservation.guestAgreementSigned ?
                <GuestAgreements reservationNumber={selectedTrip.reservationNumber} rebook={selectedTrip.rebook} pricingType={selectedTrip.pricingType} paymentSchedule={selectedTrip.paymentSchedule} />
                :
                <GuestAgreementPortal onSuccessfulSubmit={onCloseModalRefresh} />
              }
            </ModalBody>
            {activeReservation.guestAgreementSigned &&
              <ModalFooter>
                <Button colorScheme="dmNavy" onClick={mainModalOnClose}>
                  Close
                </Button>
              </ModalFooter>
            }
          </ModalContent>
        </Modal>
      }

      {selectedItem === 'Add Dogs' &&
        <Modal isOpen={isOpen} onClose={mainModalOnClose} size={{ base: 'full', md: '6xl' }}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Dog Guests</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <DogAgreementPortal reservationData={activeReservation} onSuccessfulPayment={mainModalOnClose} />
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="dmNavy" onClick={mainModalOnClose}>
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      }

      {selectedItem === 'Share' &&
        <ShareWidget reservationData={activeReservation} modalInitiateOpen={isOpen} onModalClose={mainModalOnClose} />
      }

      {selectedItem === 'Rebook' &&
        <RebookCalendar
          modalInitiateOpen={isOpen}
          reservationData={activeReservation}
          onModalClose={mainModalOnClose}
        />
      }

      {selectedItem === 'Tip My Cleaner' &&
        <Modal isOpen={isOpen} onClose={onCloseModalRefresh} size={{ base: 'full', md: '2xl' }}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Tip My Cleaner</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Elements stripe={stripePromise}>
                <TipMyCleaner reservationData={activeReservation} onSuccessfulPayment={onCloseModalRefresh} />
              </Elements>
            </ModalBody>
          </ModalContent>
        </Modal>
      }

      {selectedItem === 'FAQs' &&
        <Modal isOpen={isOpen} onClose={mainModalOnClose} size={{ base: 'full', md: '6xl' }}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Frequently Asked Questions</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <FAQ reservationNumber={activeReservation.reservationNumber} />
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="dmNavy" onClick={mainModalOnClose}>
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      }

      {selectedItem === 'Check-Out Instructions' &&
        <Modal isOpen={isOpen} onClose={mainModalOnClose} size={{ base: 'full', md: '6xl' }}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Check-Out Instructions</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <CheckOutInfo
                reservationData={activeReservation}
                onReportDamage={handleReportDamage}
              />
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="dmNavy" onClick={mainModalOnClose}>
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      }

      {selectedItem === 'Access Information' &&
        <Modal isOpen={isOpen} onClose={mainModalOnClose} size={{ base: 'full', md: '6xl' }}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Home Access Information</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <CheckInInfo
                reservationData={activeReservation}
              />
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="dmNavy" onClick={mainModalOnClose}>
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      }

      {selectedItem === "Report Damage" &&
        <Modal isOpen={isOpen} onClose={mainModalOnClose} size={{ base: 'full', md: 'xl' }}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Report Damage</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              <ReportDamage
                onPopoverClose={handleSupportClose}
                userEmail={userEmail}
                contactOwner={<SupportManager />}
                houseNumber={selectedTrip.house.houseNumber}
                reservationNumber={selectedTrip.reservationNumber}
                onReportedDamage={handleReportedDamage}
              />
            </ModalBody>
          </ModalContent>
        </Modal>
      }

      {selectedItem === "Beach Stickers" &&
        <Modal isOpen={isOpen} onClose={mainModalOnClose} size={{ base: 'full', md: '6xl' }}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Beach Stickers</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Beach
                openItems={[0, 1]}
                town={selectedTrip.house.town}
              />
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="dmNavy" onClick={mainModalOnClose}>
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      }

      {selectedItem === "Home Guide" &&
        <Modal isOpen={isOpen} onClose={mainModalOnClose} size={{ base: 'full', md: '6xl' }}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Home Guide</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <HowThisHomeWorks
                reservationData={selectedTrip}
              />
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="dmNavy" onClick={mainModalOnClose}>
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      }

      {selectedItem === "Pre-Arrival Guide" &&
        <Modal isOpen={isOpen} onClose={mainModalOnClose} size={{ base: 'full', md: '6xl' }}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Pre-Arrival Guide</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <WelcomeDetails
                town={selectedTrip.house.town}
                channel={selectedTrip.channel}
                standalone={false}
                laundry={selectedTrip.house.laundry}
              />
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="dmNavy" onClick={mainModalOnClose}>
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      }

      {selectedItem === "House Rules" &&
        <Modal isOpen={isOpen} onClose={mainModalOnClose} size={{ base: 'full', md: '6xl' }}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>House Rules</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Flex direction='column'>
                <Box>We're so excited to host you at our home. To ensure you have the best vacation possible, and our home is well taken care of, please abide by the following house rules:
                  <UnorderedList ml={6}>
                    <ListItem>No smoking in the home or on the property</ListItem>
                    <ListItem>Leave sandy/dirty items outside (beach towels, sandals, shoes)</ListItem>
                    <ListItem>Only flush toilet paper–all other items should be disposed of in the trash</ListItem>
                    <ListItem>If the AC is on, ensure all windows and doors are closed</ListItem>
                    <ListItem>IF the home has central A/C please do not put the temperature below 69° - keeping the temperature any lower runs the risk of freezing the system. If the system freezes, you will likely be without AC until it defrosts and a vendor can repair the system.</ListItem>
                    <ListItem>The only guests allowed at the home overnight are those accounted for on your guest agreement. Do not exceed the maximum number of guests allowed at the home.</ListItem>
                    <ListItem>IF home is dog friendly, please do not allow them on furniture. Dogs are not to be left unattended and uncrated in the home.</ListItem>
                    <ListItem>IF there is any accidental damage to the home, please report it. You are covered for up to $500 of accidental damage.</ListItem>
                  </UnorderedList>
                </Box>
              </Flex>
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="dmNavy" onClick={mainModalOnClose}>
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      }

      {selectedItem === "Help" &&
        <Modal
          isOpen={isOpen}
          onClose={() => void 0}
          isCentered
          size={"lg"}
        >
          <ModalOverlay
            bg="blackAlpha.700"
            backdropFilter="blur(10px) hue-rotate(90deg)"
          />
          <ModalContent borderRadius="none" mx="0">
            <ModalCloseButton size="lg" onClick={mainModalOnClose} />
            <ModalBody padding="0">
              {isTripsContextLoaded && selectedTrip &&
                <GuestServicesPopOver
                  onPopoverClose={mainModalOnClose}
                  userEmail={userEmail}
                  contactOwner={manager}
                  houseNumber={selectedTrip.house.houseNumber}
                  reservationNumber={selectedTrip.reservationNumber}
                />
              }
            </ModalBody>
          </ModalContent>
        </Modal>
      }

      {selectedItem === "My Receipts" &&
        <Modal isOpen={isOpen} onClose={mainModalOnClose} size={{ base: 'full', md: '6xl' }}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>My Receipts</ModalHeader>
            <ModalCloseButton />
            <ModalBody maxH={{ base: 'auto', md: '80vh' }} overflowY={{ base: 'auto', md: 'scroll' }}>
              <Receipts
                reservationNumber={selectedTrip.reservationNumber}
              />
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="dmNavy" onClick={mainModalOnClose}>
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      }

    </Flex>
  );
}

export { TripDetails };

