import {
  Flex, Image, Link
} from "@chakra-ui/react";
import * as React from "react";
import "react-datepicker/dist/react-datepicker.css";

const iconNameMapper = {
  "free parking on premises": "freeparkingonpremises.svg",
  "air conditioning": "airconditioning.svg",
  "heating": "heating.svg",
  "indoor fireplace": "indoorfireplace.svg",
  "wireless internet": "wifi.svg",
  "kitchen": "kitchen.svg",
  "washer": "washer.svg",
  "dryer": "dryer.svg",
  "iron": "iron.svg",
  "iron and ironing board": "iron.svg",
  "cable tv": "cabletv.svg",
  "tv": "tv.svg",
  "essentials": "essentials.svg",
  "hangers": "hangers.svg",
  "smoke detector": "smokedetector.svg",
  "carbon monoxide detector": "carbonmonoxidedetector.svg",
  "fire extinguisher": "fireextinguisher.svg",
  "pets allowed": "petsallowed.svg",
  "dogs allowed w/ fee": "petsallowed.svg",
  "bed linens": "bedlinens.svg",
  "microwave": "microwave.svg",
  "coffee maker": "coffeemaker.svg",
  "refrigerator": "refrigerator.svg",
  "dishwasher": "dishwasher.svg",
  "dishes and silverware": "dishesandsilverware.svg",
  "cookware": "cookware.svg",
  "oven": "oven.svg",
  "stove": "stove.svg",
  "bbq grill": "bbqgrill.svg",
  "garden or backyard": "gardenorbackyard.svg",
  "suitable for children (2-12 years)": "children.svg",
  "suitable for infants (under 2 years)": "infants.svg",
  "hot water": "hotwater.svg",
  "hot tub": "hottub.svg",
  "extra pillows and blankets": "pillow.svg"
};


type Props = {
  iconName: string;
  iconLink?: string;
  iconText: string;
  margin?: number;
  padding?: number;
  gap?: number;
  hidden?: boolean;
  fontSize?: number;
  borderRadius?: number;
  imageWidth?: number;
  imageHeight?: number;
};

const IconText: React.FC<Props> = ({
  iconName, iconLink, iconText,
  margin = 2, padding = 2, gap = 2, hidden = false, fontSize = 16, borderRadius = 0, imageWidth = 20, imageHeight = 20
}) => {

  // Use the iconNameMapper to get the actual image file name
  const imageFileName = iconNameMapper[iconName.toLowerCase()] || iconName;

  // Modify iconText if iconName is "pets allowed"
  const displayedIconText = iconName.toLowerCase() === "pets allowed" ? "Dogs Allowed w/ Fee" : iconText;


  return (

    <Flex justifyContent={'center'} alignItems={'center'} className={"icon-parent"}>
      {!hidden &&
        <>
          <Link href={iconLink}>
            <Image
              src={`../media/icons/${imageFileName}`}
              alt={displayedIconText}
              objectFit="cover"
              width={imageWidth + "px"}
              height={imageHeight + "px"}
              className={iconName + ' icon'}
              borderRadius={borderRadius}
            //fallbackSrc={'../media/ratingstar.png'}
            />
          </Link>
          <Flex w={gap + "px"}></Flex>
          <Flex fontSize={fontSize} m={margin + "px"} p={padding + "px"}>{displayedIconText}</Flex>
        </>
      }
    </Flex>
  );
}

export default IconText;
