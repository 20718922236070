import { Button, Flex, Text } from "@chakra-ui/react";
import { useSignIn } from "@clerk/clerk-react";
import { OAuthStrategy } from "@clerk/types";
import { FaFacebook } from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";
import config from "../../config";

// Confirmation Home Page for Redirect
const homePage = config.homePage;


function ClerkSignInOauth({ oauthType, reservationId }) {
  const { signIn } = useSignIn();

  //console.log("oauthType", oauthType)

  const signInWith = (strategy: OAuthStrategy) => {
    return signIn.authenticateWithRedirect({
      strategy,
      redirectUrl: `${homePage}${reservationId}`,
      redirectUrlComplete: `${homePage}${reservationId}`,
    });
  };

  const capitalOauth = oauthType === "oauth_google" ? "Google" : "Facebook"

  // Render a button for each supported OAuth provider
  // you want to add to your app
  return (
    <Flex w={'100%'} justifyContent={'center'} alignItems={'center'} flexDirection={'column'}>
      <Text textAlign={'center'}>We found an existing Del Mar Guest Portal Account with your email.</Text>

      <Button w={'300px'} my={5} colorScheme={oauthType === "oauth_google" ? "gray" : "facebook"}
        onClick={() => signInWith(oauthType)}
        leftIcon={oauthType === "oauth_google" ? <FcGoogle /> : <FaFacebook />}
      >
        Sign in with {capitalOauth}
      </Button>
    </Flex>
  );
}

export default ClerkSignInOauth;