/* eslint-disable react-hooks/exhaustive-deps */
import {
  CheckCircleIcon,
  ChevronDownIcon,
  ChevronUpIcon
} from "@chakra-ui/icons";
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  Image,
  Input,
  Link,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Tag,
  Text,
  UnorderedList,
  useDisclosure
} from "@chakra-ui/react";
import { useUser } from "@clerk/clerk-react";
import { Elements } from "@stripe/react-stripe-js";
import {
  Appearance,
  StripeElementsOptions,
  loadStripe,
} from "@stripe/stripe-js";
import { format } from "date-fns";
import isEqual from "lodash.isequal";
import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { FiChevronLeft } from "react-icons/fi";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import PaymentForm from "../components/PaymentForm";
import config from "../config";
import { SearchResultsContext } from "../contexts/SearchResultsContext";
import { MAX_END_DATE } from "../helpers/constants";
import {
  createReservationQuote,
  fetchHouseCalendar,
  fetchHouseDetails
} from "../helpers/fetch";
import {
  convertStringtoDateAndFormat,
  createDateAtLocalMidnight,
  getChannelDisplayName,
  omitNullAndUndefined,
  reformatDateString
} from "../helpers/helpers";
import { sendHubspotEvent } from "../helpers/hubspot";
import {
  fetchBookingPaymentIntent
} from "../helpers/reservationService";

const stripePromise = loadStripe(config.stripeKey);

const appearance: Appearance = {
  theme: "stripe",
  variables: {
    colorPrimary: "#ed5f74",
    borderRadius: "20px",
    fontFamily: "sans-serif",
    colorBackground: "#fafafa",
  },
};

const amount = 100; // $1 for stripe initialization

const CheckoutNew: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // House Number from URL
  let { houseNumber } = useParams();
  // Context Data
  const {
    guests,
    setGuests,
    houseData,
    setHouseData,
    houseCalendar,
    setHouseCalendar,
  } = React.useContext(SearchResultsContext);
  // Stripe Client Secret from Backend
  const [clientSecret, setClientSecret] = useState<string | null>(null);

  const { user } = useUser();

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top when component mounts

    const fetchClientSecret = async () => {
      const response = await fetchBookingPaymentIntent(amount);
      const data = await response.json();
      setClientSecret(data.clientSecret);
    };

    fetchClientSecret();
  }, []);

  const stripeOptions: StripeElementsOptions = {
    clientSecret: clientSecret!,
    appearance,
  };

  /// Rebook Mode Variables ///
  const [rebookDiscountEligible, setRebookDiscountEligible] = React.useState(false);
  const [rebookTermsEligible] = React.useState(false);

  // Terms Modal Handler
  const {
    isOpen: isTermsModalOpen,
    onOpen: onOpenTermsModal,
    onClose: onCloseTermsModal,
  } = useDisclosure();

  //// Submit for Reservation Quote ////

  const [reservationQuote, setReservationQuote] = React.useState(null);
  const [pricingBreakdown, setPricingBreakdown] = React.useState(null);
  const [originalGuestyQuote, setOriginalGuestyQuote] = React.useState(null);

  const [nightlyRates, setNightlyRates] = React.useState(null);
  const [totalPrice, setTotalPrice] = React.useState(null);

  const [errorMessage, setErrorMessage] = React.useState(null);
  const [isQuoteLoading, setIsQuoteLoading] = React.useState(true);

  const [numNights, setNumNights] = React.useState(null);

  const [fromLocation, setFromLocation] = React.useState(null);
  const [editMode, setEditMode] = React.useState(false);
  const [isTaxesExpanded, setIsTaxesExpanded] = React.useState(false);
  const [isOurPriceExpanded, setIsOurPriceExpanded] = React.useState(false);
  const toggleTaxesExpand = () => setIsTaxesExpanded(!isTaxesExpanded);
  const toggleOurPriceExpand = () => setIsOurPriceExpanded(!isOurPriceExpanded);
  const [totalTaxes, setTotalTaxes] = React.useState(null);

  const [taxesLocal, setTaxesLocal] = React.useState(null);
  const [taxesCity, setTaxesCity] = React.useState(null);
  const [taxesTourism, setTaxesTourism] = React.useState(null);
  const [cleaningFee, setCleaningFee] = React.useState(null);
  const [processingFee, setProcessingFee] = React.useState(null);
  const [reservationFeeAdjustment, setReservationFeeAdjustment] = React.useState(0);
  const [subTotalPrice, setSubTotalPrice] = React.useState(null);
  const [channelSubTotalPrice, setChannelSubTotalPrice] = React.useState(null);
  const [rebookDiscount, setRebookDiscount] = React.useState(null);
  const [nightlyRatesPlusCleaning, setNightlyRatesPlusCleaning] = React.useState(null);
  const [rebookCoupon, setRebookCoupon] = React.useState(null);

  const [coupon, setCoupon] = React.useState(null);
  const [couponValidated, setCouponValidated] = React.useState(null);
  const [editCouponMode, setEditCouponMode] = React.useState(false);

  const [checkoutStartDate, setCheckoutStartDate] = React.useState(
    reservationQuote ? reservationQuote.checkInDateLocalized : null
  );
  const [checkoutEndDate, setCheckoutEndDate] = React.useState(
    reservationQuote ? reservationQuote.checkOutDateLocalized : null
  );
  const [checkoutDateRange, setCheckoutDateRange] = React.useState([
    null,
    null,
  ]);


  /// Payment Options ///
  const [selectedPricingType, setSelectedPricingType] = React.useState(null);
  const [selectedOption, setSelectedPaymentOption] = React.useState<number | null>(null);

  React.useEffect(() => {
    if (pricingBreakdown?.depositsBreakdown?.length === 3) {
      setSelectedPaymentOption(0);
    }
    if (pricingBreakdown?.depositsBreakdown?.length === 1) {
      setSelectedPricingType(pricingBreakdown.depositsBreakdown[0]);
    }
  }, [pricingBreakdown]);

  React.useEffect(() => {
    if (pricingBreakdown?.depositsBreakdown?.length === 3) {
      setSelectedPricingType(pricingBreakdown.depositsBreakdown[selectedOption])
    }
  }, [selectedOption]);

  React.useEffect(() => {
    //console.log('selectedPricingType', selectedPricingType);

    let reservationFeeAdjustment = 0;

    if (pricingBreakdown?.depositsBreakdown[selectedOption]?.type === "full-save") {
      reservationFeeAdjustment = pricingBreakdown.depositsBreakdown[selectedOption].lineItems[0].amount - totalPrice
      setReservationFeeAdjustment(reservationFeeAdjustment);
      //console.log('reservationFeeAdjustment', reservationFeeAdjustment);
    }
    else if (pricingBreakdown?.depositsBreakdown[selectedOption]?.type === "bnpl") {
      const lineItems = pricingBreakdown.depositsBreakdown[selectedOption].lineItems || [];
      const totalLineItemAmount = lineItems.reduce((sum, item) => sum + (item.amount || 0), 0);
      reservationFeeAdjustment = totalLineItemAmount - totalPrice
      setReservationFeeAdjustment(reservationFeeAdjustment);
      //console.log('reservationFeeAdjustment', reservationFeeAdjustment);
    } else {
      setReservationFeeAdjustment(0);
      //console.log('reservationFeeAdjustment', 0);
    }

  }, [selectedPricingType, reservationQuote]);

  /// END Payment Options ///


  /// Abandon Checkout Handling

  const [sessionId] = React.useState(uuidv4());

  const [abandonCheckoutData, setAbandonCheckoutData] = React.useState({
    firstName: "", // String
    lastName: "", // String
    email: "", // String
    phone: "", // String
    houseNumber: houseNumber, // String
    checkinDate: checkoutStartDate || null, // String or null
    checkoutDate: checkoutEndDate || null, // String or null
    guests: guests || null, // Integer or null
    coupon: coupon || null, // String or null
    totalPrice: totalPrice || null, // Float or null
    sessionId: sessionId, // String generated by uuidv4(), ex: 7d1669f1-05e5-4aa9-8669-1a19cb4d0662
  });

  const prevAbandonCheckoutData = React.useRef(abandonCheckoutData);

  const getHubspotUTK = () => {
    const cookies = document.cookie.split(";");
    const hubspotCookie = cookies.find((cookie) =>
      cookie.trim().startsWith("hubspotutk=")
    );
    return hubspotCookie ? hubspotCookie.split("=")[1] : null;
  };

  useEffect(() => {
    const hasChanged = !isEqual(
      abandonCheckoutData,
      prevAbandonCheckoutData.current
    );

    if (hasChanged) {
      prevAbandonCheckoutData.current = abandonCheckoutData;

      if (
        abandonCheckoutData.firstName ||
        abandonCheckoutData.lastName ||
        abandonCheckoutData.email ||
        abandonCheckoutData.phone ||
        abandonCheckoutData.checkinDate ||
        abandonCheckoutData.checkoutDate ||
        abandonCheckoutData.guests ||
        abandonCheckoutData.coupon ||
        abandonCheckoutData.totalPrice
      ) {
        const hubspotUTK = getHubspotUTK();
        const eventName = "pe21053466_checkout_form_initiated";
        const properties = {
          firstname: abandonCheckoutData.firstName,
          lastname: abandonCheckoutData.lastName,
          email: abandonCheckoutData.email,
          phone: abandonCheckoutData.phone,
          housenumber: abandonCheckoutData.houseNumber,
          checkindate: abandonCheckoutData.checkinDate,
          checkoutdate: abandonCheckoutData.checkoutDate,
          guests: abandonCheckoutData.guests,
          coupon: abandonCheckoutData.coupon,
          totalprice: abandonCheckoutData.totalPrice,
          sessionid: abandonCheckoutData.sessionId,
        };

        const eventData = {
          eventName,
          properties,
          objectType: "contacts",
          email: abandonCheckoutData.email || undefined,
          utk: hubspotUTK || undefined,
        };

        if (abandonCheckoutData.email || abandonCheckoutData.phone) {
          // Fire and forget
          (async () => {
            try {
              await sendHubspotEvent(eventData);
            } catch (error) {
              console.error("Error sending HubSpot event:", error);
            }
          })();
        }
      }
      prevAbandonCheckoutData.current = abandonCheckoutData;
    }
  }, [abandonCheckoutData]);

  const userEmail = user?.primaryEmailAddress?.emailAddress;
  const eventFiredRef = React.useRef(false); // Track if event has been fired

  const rebooReservationParam = localStorage.getItem("dm-rebook-reservation")
  const attributionParam = localStorage.getItem("dm-attribution")
  const eventName = "pe21053466_checkout_initiated";
  const properties = omitNullAndUndefined({
    house_number: houseNumber,
    rebook_reservation_source: rebooReservationParam,
    attribution: attributionParam,
    hs_page_url: window.location.href,
    check_in_date: checkoutStartDate || null, // String or null
    check_out_date: checkoutEndDate || null, // String or null
    guests: guests || null, // Integer or null
    total_price: totalPrice || null, // Float or null
    nightly_rates: nightlyRates || null,
    coupon: coupon || null,
    checkout_page_type: 'Guesty Checkout (Original)'
  })
  const eventData = {
    eventName,
    properties,
    ...(userEmail ? { email: userEmail } : {}), // Only add email if it’s valid
  };

  React.useEffect(() => {
    if (!eventFiredRef.current && Object.keys(properties).length > 0) {
      // Fire the event only once
      eventFiredRef.current = true;
      (async () => {
        try {
          //console.log('Sending eventData:', eventData);
          await sendHubspotEvent(eventData);
        } catch (error) {
          console.error('Error sending HubSpot event:', error);
        }
      })();
    }
  }, [properties]); // Only re-run if `properties` change

  /// END Abandon Checkout Handling

  /// Load House Data if Not in Context Already
  const [houseLoading, setHouseLoading] = React.useState(true);
  const [calendarLoading, setCalendarLoading] = React.useState(true);

  const fetchHouseData = async () => {
    setHouseLoading(true);
    try {
      //console.log("Fetching data for", houseNumber);
      const params = { houseNumber: houseNumber };

      const response = await fetchHouseDetails(params);

      if (!response.ok) {
        throw new Error(`fetchHouseDetails Error! Status: ${response.status}`);
      }

      const returnedHouseData = await response.json();
      setHouseData(returnedHouseData.results);
      //console.log("returnedHouseData", returnedHouseData.results)
    } catch (error) {
      console.error("Error fetching house data from DB:", error);
    } finally {
      setHouseLoading(false);
      //console.log("returnedHouseData", houseData);
    }
  };

  React.useEffect(() => {
    if (houseData) {
      setHouseLoading(false);
      return;
    } else {
      if (!guests) {
        setGuests(2);
      }
      fetchHouseData();
    }
  }, [houseNumber]);

  const fetchHouseAvailability = async () => {
    try {
      const startDate = format(new Date(), "yyyy-MM-dd"); // Today's date
      const endDate = MAX_END_DATE; // End of 2025

      setCalendarLoading(true);
      //console.log("Fetching house availability for", houseData.guestyHouseId);

      const response = await fetchHouseCalendar(
        houseData.guestyHouseId,
        startDate,
        endDate
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const availabilityData = await response.json();
      // Set the state or handle the data as needed
      //console.log("availabilityData", availabilityData)
      setHouseCalendar(availabilityData);
    } catch (error) {
      console.error("Error fetching house availability:", error);
    } finally {
      setCalendarLoading(false);
    }
  };

  React.useEffect(() => {
    if (houseCalendar) {
      setCalendarLoading(false);
      return;
    } else {
      if (houseData && houseData.guestyHouseId) {
        fetchHouseAvailability();
      }
    }
  }, [houseData]);

  /// END Load House Data if Not in Context Already


  // Function to Process Reservation Quote on Page Load and on Date/Guests Edit
  function processReservationQuote(reservationQuote) {
    //console.log("isQuoteLoading", isQuoteLoading);

    setSubTotalPrice(reservationQuote.subTotalPrice);
    setTotalPrice(reservationQuote.totalPrice);
    setChannelSubTotalPrice(reservationQuote.channelSubTotalPrice);
    setRebookDiscountEligible(reservationQuote.rebookDiscountEligible);
    setNightlyRates(reservationQuote.nightlyRatesFinal);
    setRebookDiscount(reservationQuote.rebookDiscountAmount);
    setCleaningFee(reservationQuote.cleaningFee);

    setNightlyRatesPlusCleaning(reservationQuote.nightlyRatesPlusCleaning);

    setTaxesCity(reservationQuote.totalCityTax);
    setTaxesLocal(reservationQuote.totalLocalTax);
    setTaxesTourism(reservationQuote.totalTourismTax);
    setTotalTaxes(reservationQuote.totalTax);

    setProcessingFee(reservationQuote.processingFee);
    setCleaningFee(reservationQuote.cleaningFee);

    setNumNights(reservationQuote.numberOfNights);

    setCheckoutStartDate(reservationQuote.checkInDateLocalized);
    setCheckoutEndDate(reservationQuote.checkOutDateLocalized);
    setAbandonCheckoutData((prev) => ({
      ...prev,
      checkinDate: reservationQuote.checkInDateLocalized,
      checkoutDate: reservationQuote.checkOutDateLocalized,
      guests: reservationQuote.guestsCount,
      coupon: reservationQuote.rebookCode,
      totalPrice: reservationQuote.totalPrice
    }));
    setCheckoutDateRange([
      createDateAtLocalMidnight(reservationQuote.checkInDateLocalized),
      createDateAtLocalMidnight(reservationQuote.checkOutDateLocalized),
    ]);
    if (reservationQuote.rebookDiscountEligible && reservationQuote.rebookDiscount) {
      setRebookCoupon(`rebook-discount-${reservationQuote.rebookDiscount * 100}%`)
    }
  }

  // Receive Initial Reservation Quote from Listing.tsx
  React.useEffect(() => {
    if (location.state?.reservationQuote) {
      setReservationQuote(location.state.reservationQuote);
      processReservationQuote(location.state.reservationQuote);
    }
    setIsQuoteLoading(false);
    if (location.state?.houseData) {
      setHouseData(location.state.houseData);
    }
    if (location.state?.guests) {
      setGuests(location.state.guests);
    }
    if (location.state?.fromLocation) {
      setFromLocation(location.state.fromLocation);
    }
    if (location.state?.pricingBreakdown) {
      setPricingBreakdown(location.state.pricingBreakdown);
    }
    if (location.state?.originalGuestyQuote) {
      setOriginalGuestyQuote(location.state.originalGuestyQuote);
    }
    //console.log('location.state.originalGuestyQuote', location.state.originalGuestyQuote)
    //console.log('location.state.reservationQuote', location.state.reservationQuote)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state]);

  // Edit Date/Guests
  function editQuote() {
    setEditMode(true);
  }

  // Handler for Checkout Edit, Generate New Quote
  const handleEditSubmit = async (
    action: string,
    e?: React.MouseEvent<HTMLButtonElement>
  ) => {
    if (e) e.preventDefault();

    if (action !== "couponEdit") {
      setEditCouponMode(true);
    } else {
      setEditMode(true);
    }
    setIsQuoteLoading(true);

    const oldQuote = reservationQuote; // Saving oldQuote for coupon error handling

    try {
      if (!houseData) {
        console.error("houseData is not available");
        return;
      }

      setErrorMessage(null);
      setNightlyRates(null);
      setTotalPrice(null);
      setErrorMessage(null);

      const formattedStartDate = format(checkoutDateRange[0], "yyyy-MM-dd");
      const formattedEndDate = format(checkoutDateRange[1], "yyyy-MM-dd");

      const listingId = houseData.guestyHouseId;

      const queryParams = {
        checkInDateLocalized: formattedStartDate,
        checkOutDateLocalized: formattedEndDate,
        listingId,
        guestsCount: guests,
        ...(coupon ? { coupons: coupon } : {}), // Conditionally add coupons only if coupon is truthy
      };

      // Fetch call to backend server
      const response = await createReservationQuote(queryParams);

      if (!response.ok) {
        const errorResponse = await response.json();
        throw new Error(
          errorResponse.error.message ||
          `HTTP error! Status: ${response.status}`
        );
      }
      // Process the response as needed
      const reservationQuote = await response.json();
      //console.log('Edit Coupon Reservation Quote:', reservationQuote);

      setReservationQuote(reservationQuote.reservationQuote);
      setOriginalGuestyQuote(reservationQuote.originalGuestyQuote);

      processReservationQuote(reservationQuote.reservationQuote);
      setPricingBreakdown(reservationQuote.pricingBreakdown);
      setCouponValidated(true);
      setEditCouponMode(false);
    } catch (error) {
      console.error("Error:", error.message);
      setErrorMessage(error.message);

      if (error.message.includes("coupons")) {
        processReservationQuote(oldQuote);
        setReservationQuote(oldQuote);
        setCoupon(null);
        //console.log("coupon error")
      } else {
        setReservationQuote(null);
        //console.log("non-coupon error")
      }
    } finally {
      setEditMode(false);
      setIsQuoteLoading(false);
    }
  };

  // Return to Listing Page with Search URL Params
  const returnToListing = async () => {
    if (fromLocation && fromLocation.includes("quote")) {
      navigate(`${fromLocation}`);
      return;
    }

    // Serialize parameters into a query string
    const queryParams = new URLSearchParams();
    if (checkoutStartDate && checkoutEndDate) {
      const startDate = checkoutStartDate;
      const endDate = checkoutEndDate;
      queryParams.set("startdate", startDate);
      queryParams.set("enddate", endDate);
    }
    if (guests) {
      queryParams.set("guests", JSON.stringify(guests));
    }

    const queryString = queryParams.toString();
    const url = `/${houseNumber}?${queryString}`;
    //console.log("queryString",queryString)

    navigate(url);
  };


  /// End Form Abandonment Handling ///


  return clientSecret && reservationQuote ? (
    <Flex
      flexDirection={{ base: "column-reverse", md: "row" }}
      gap={{ base: 0, md: 6 }}
      p={{ base: 0, md: 3 }}
      maxW={"1480px"}
      margin={"0 auto"}
      alignItems={"flex-start"}
      minH={"calc(100vh - 236px)"}
    >
      {/* Left Panel */}
      <Flex
        flexDirection={"column"}
        w={"100%"}
        borderTop={{ base: "solid 10px #E9E9E9", md: "none" }}
      >
        <Flex
          maxWidth="1440px"
          width={"100%"}
          pt={{ base: 0, md: 5 }}
          px={{ base: 3, md: 5 }}
          textAlign={"left"}
          alignItems={"start"}
          flexDirection={"column"}
          margin={"0 auto"}
          display={{ base: "none", md: "flex" }}
        >
          <Flex
            alignItems={"center"}
            gap={2}
            pb={{ base: 0, md: 3 }}
            display={{ base: "flex", md: "flex" }}
            _hover={{ cursor: "pointer" }}
            onClick={returnToListing}
          >
            <FiChevronLeft />
            {fromLocation && fromLocation.includes("quote") ? (
              <Text>Return to Quote</Text>
            ) : (
              <Text>Return to Listing</Text>
            )}
          </Flex>
        </Flex>
        {houseData && (
          <Flex
            pt={5}
            px={{ base: 3, md: 5 }}
            w={"100%"}
            justifyContent={"center"}
          >
            <Elements stripe={stripePromise} options={stripeOptions}>
              <PaymentForm
                checkInDateLocalized={reservationQuote.checkInDateLocalized}
                checkOutDateLocalized={reservationQuote.checkOutDateLocalized}
                houseNumber={houseNumber}
                guestyHouseId={houseData.guestyHouseId}
                guests={guests}
                nightlyRates={nightlyRates}
                rebookMode={rebookTermsEligible}
                coupon={coupon || rebookCoupon}
                selectedPricingType={selectedPricingType}
                guestyQuoteId={originalGuestyQuote.quote._id}
                rebookCode={reservationQuote.rebookCode && reservationQuote.rebookCode !== 'null' && reservationQuote.rebookCode}
              />
            </Elements>
          </Flex>
        )}
      </Flex>
      {/* END Left Panel */}

      {/* Right Panel */}
      <Flex
        flexDirection={"column"}
        my={{ base: 0, md: 10 }}
        mx={{ base: 0, md: 10 }}
        w={{ base: "100%", md: "40%" }}
        border={{ base: "0", md: "solid 1px #CCC" }}
        borderRadius={"8px"}
        pb={{ base: 0, md: 5 }}
      >
        <Flex
          maxWidth="1440px"
          width={"100%"}
          pt={{ base: 3, md: 5 }}
          px={{ base: 3, md: 5 }}
          textAlign={"left"}
          alignItems={"start"}
          flexDirection={"column"}
          margin={"0 auto"}
          display={{ base: "flex", md: "none" }}
        >
          <Flex
            alignItems={"center"}
            gap={2}
            pb={{ base: 0, md: 3 }}
            display={{ base: "flex", md: "flex" }}
          >
            <Box _hover={{ cursor: "pointer" }}>
              <FiChevronLeft onClick={returnToListing} />
            </Box>
            <Text fontSize={"1.4em"}>Confirm and Pay</Text>
          </Flex>
        </Flex>
        {houseData && !houseLoading && !calendarLoading && (
          <>
            {/* House Summary */}
            <Flex
              width={{ base: "100%" }}
              py={{ base: 3, md: 2 }}
              px={{ base: 3, md: 5 }}
              textAlign={"left"}
              alignItems={"start"}
              flexDirection={"column"}
            >
              <Flex maxWidth="1440px" width={"100%"} pt={{ base: 1, md: 3 }}>
                <Flex
                  width={{ base: "150px", md: "250px" }}
                  textAlign={"left"}
                  alignItems={"start"}
                  flexDirection={"column"}
                >
                  <Image
                    src={houseData.pictures[0]?.original || '../media/comingsoon.jpg'}
                    alt="Listing Image 1"
                    height="100%"
                    maxH={"180px"}
                    width="100%"
                    objectFit="cover"
                    borderRadius={8}
                  />
                </Flex>
                <Flex flexDirection={"column"} pl={5}>
                  {/* House Number */}
                  <Flex
                    fontSize={{ base: "16px", md: "20px" }}
                    fontWeight={500}
                    color={"#00000090"}
                    pt={{ base: 0, md: 0 }}
                    display={{ base: "none", md: "flex" }}
                  >
                    #{houseNumber}
                  </Flex>
                  {/* Listing Title */}
                  <Flex
                    fontSize={{ base: "16px", md: "20px" }}
                    mb={{ base: 0, md: 0 }}
                    fontWeight={500}
                  >
                    {houseData.title}
                  </Flex>
                  <Flex
                    fontSize={{ base: "14px", md: "16px" }}
                    mb={{ base: 0, md: 0 }}
                    color={"#00000090"}
                    mt={2}
                  >
                    {houseData.town}, MA
                  </Flex>
                  {/* Occupancy, Bedroom, Bathroom Icons */}
                  <Flex
                    className="listing-details"
                    fontSize={"12px"}
                    alignItems={"flex-start"}
                    mt={{ base: 1, md: 4 }}
                    px={1}
                    display={{ base: "none", md: "flex" }}
                  >
                    <Flex
                      flexDirection={"column"}
                      className="listing-occupancy"
                      alignItems={"center"}
                      width={"max-content"}
                      me={3}
                    >
                      <Image
                        src={"../media/occupancy.png"}
                        alt="Max Occupancy"
                        objectFit={"cover"}
                        align={"left"}
                        width={"25px"}
                        height={"25px"}
                        className="listing-occupancy-image"
                      />
                      <Text className="listing-occupancy-text">
                        {!isNaN(houseData.occupancy)
                          ? (houseData.occupancy as number)
                          : 0}{" "}
                        Guests
                      </Text>
                    </Flex>
                    <Flex
                      flexDirection={"column"}
                      className="listing-bedrooms"
                      alignItems={"center"}
                      width={"max-content"}
                      me={3}
                    >
                      <Image
                        src={"../media/bedrooms.png"}
                        alt="Bedrooms"
                        objectFit={"cover"}
                        align={"left"}
                        width={"25px"}
                        height={"25px"}
                        className="listing-bedrooms-image"
                      />
                      <Text className="listing-bedrooms-text">
                        {!isNaN(houseData.bedrooms)
                          ? (houseData.bedrooms as number)
                          : 0}{" "}
                        Bedrooms
                      </Text>
                    </Flex>
                    <Flex
                      flexDirection={"column"}
                      className="listing-bathrooms"
                      alignItems={"center"}
                      width={"max-content"}
                    >
                      <Image
                        src={"../media/bathrooms.png"}
                        alt="Bathrooms"
                        objectFit={"cover"}
                        align={"left"}
                        width={"25px"}
                        height={"25px"}
                        className="listing-bathrooms-image"
                      />
                      <Text className="listing-bathrooms-text">
                        {!isNaN(houseData.bathrooms)
                          ? (houseData.bathrooms as number)
                          : 0}{" "}
                        Bathrooms
                      </Text>
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
            {/* End House Summary */}

            {/* Dates, Guests, Coupon */}
            <Flex w="100%">
              {(reservationQuote && houseData) ||
                (editMode && !editCouponMode) ? (
                <Box w={"100%"}>
                  <Flex
                    position={"relative"}
                    width={"100%"}
                    maxW={"1440px"}
                    data-id={"checkout"}
                    justifyContent={"start"}
                    flexDirection={{ base: "column", md: "row" }}
                    alignItems={"start"}
                    margin={"0 auto"}
                    gap={3}
                  >
                    <Flex direction="column" w={"100%"}>
                      {/* Dates and Guests Section */}
                      <Flex
                        pt={{ base: 2, md: 2 }}
                        flexDirection={{ base: "column", md: "row" }}
                        maxW={{ base: "100vw", md: "1440px" }}
                        gap={3}
                      >
                        <Box
                          w={{ base: "100%", md: "100%" }}
                          px={{ base: 3, md: 5 }}
                        >
                          <Flex flexDirection={"column"}>
                            <Flex
                              fontSize={{ base: "14px", md: "16px" }}
                              fontWeight={500}
                            >
                              Dates
                            </Flex>
                            <Flex justifyContent={"space-between"}>
                              <Box fontSize={15} color={"#666"} pb={2}>
                                {checkoutStartDate && checkoutEndDate
                                  ? `${reformatDateString(
                                    checkoutStartDate
                                  )} - ${reformatDateString(
                                    checkoutEndDate
                                  )}`
                                  : "Please select dates"}
                              </Box>
                              <Box
                                onClick={returnToListing}
                                cursor={"pointer"}
                                textDecoration={"underline"}
                              >
                                edit
                              </Box>
                            </Flex>
                          </Flex>
                          <Flex
                            flexDirection={"column"}
                            pt={{ base: 1, md: 3 }}
                          >
                            <Flex
                              fontSize={{ base: "14px", md: "16px" }}
                              fontWeight={500}
                            >
                              Guests
                            </Flex>
                            <Flex justifyContent={"space-between"}>
                              <Box fontSize={15} color={"#666"} pb={2}>
                                {guests
                                  ? `${guests} guests`
                                  : "Please select guests"}
                              </Box>
                              <Box
                                onClick={returnToListing}
                                cursor={"pointer"}
                                textDecoration={"underline"}
                              >
                                edit
                              </Box>
                            </Flex>
                          </Flex>
                          {rebookDiscountEligible && rebookDiscount ? (
                            <Flex
                              flexDirection={"column"}
                            > {/*
                              <Flex justifyContent={"space-between"} gap={2} pt={{ base: 1, md: 3 }}>
                                <Alert
                                  status="info"
                                  borderRadius="md"
                                  fontSize="md"
                                  mb={3}
                                  fontWeight={"normal"}
                                >
                                  <AlertIcon />
                                  <span>
                                    Your rebooking discount code has been
                                    automatically applied. You are saving an
                                    additional ${(rebookDiscount).toFixed(2)} on
                                    this reservation.
                                  </span>
                                </Alert>
                              </Flex>
                              */}
                            </Flex>
                          ) : (
                            <Flex flexDirection={"column"} pt={{ base: 1, md: 3 }}>
                              <Flex justifyContent={"space-between"} gap={2}>
                                <Flex
                                  fontSize={{ base: "14px", md: "16px" }}
                                  fontWeight={500}
                                >
                                  Coupon
                                </Flex>
                                {errorMessage && errorMessage.includes("coupons") && (
                                  <Text color="red">Invalid Coupon</Text>
                                )}
                              </Flex>
                              {coupon && coupon !== "" && couponValidated === true && !editMode ? (
                                <Flex justifyContent={"space-between"}>
                                  <Flex gap={2} pb={2} alignItems={'center'}>
                                    <Box fontSize={15} color={"#666"}>
                                      {coupon ? coupon : ""}
                                    </Box>
                                    <CheckCircleIcon color="green" /><Text fontSize="15px" color="green">Coupon Applied</Text>
                                  </Flex>
                                  <Box
                                    onClick={editQuote}
                                    cursor={"pointer"}
                                    textDecoration={"underline"}
                                  >
                                    {coupon ? "edit" : "add"}
                                  </Box>
                                </Flex>
                              ) : (
                                <FormControl
                                  height={"30px"}
                                  w={"100%"}
                                  mt={1}
                                >
                                  <Flex
                                    gap={2}
                                    justifyContent={"space-between"}
                                    alignItems={"center"}
                                  >
                                    <Input
                                      textAlign={"center"}
                                      flexGrow={1}
                                      maxW={"300px"}
                                      color="black"
                                      type="text"
                                      border="solid 1px #D4D8E1"
                                      onChange={(e) => {
                                        setCouponValidated(false);
                                        const newValue = e.target.value;
                                        if (newValue && newValue !== "") {
                                          setCoupon(newValue);
                                        }
                                      }}
                                      value={coupon || ""}
                                    />
                                    <Button
                                      onClick={(e) =>
                                        handleEditSubmit("couponEdit", e)
                                      }
                                      isLoading={isQuoteLoading}
                                    >
                                      Save
                                    </Button>
                                  </Flex>
                                </FormControl>
                              )}
                            </Flex>
                          )}


                        </Box>
                      </Flex>
                      {/* END Dates and Guests Section */}
                    </Flex>
                  </Flex>
                </Box>
              ) : editMode ? (
                <Flex>Updating</Flex>
              ) : (
                <Flex
                  position={"relative"}
                  width={"full"}
                  data-id={"checkout"}
                  p={{ base: 2, md: 5 }}
                  justifyContent={"center"}
                  flexDirection={"column"}
                  alignItems={"center"}
                  minH={"calc(100vh - 236px)"}
                >
                  {/* Top Bar */}
                  <Flex
                    maxWidth="1440px"
                    width={"100%"}
                    p={2}
                    textAlign={"left"}
                    alignItems={"center"}
                    flexDirection={"column"}
                  >
                    Reservation quote has expired.
                    <Button
                      h={"50px"}
                      mt={6}
                      onClick={returnToListing}
                      colorScheme="dmOrange"
                    >
                      {fromLocation && fromLocation.includes("quote") ? (
                        <Text>Return to Quote</Text>
                      ) : (
                        <Text>Return to Listing</Text>
                      )}
                    </Button>
                  </Flex>
                </Flex>
              )}
            </Flex>
            {/* END Dates, Guests, Coupon */}

            {/* Price Details */}
            <Box w={"100%"} pt={2}>
              <Flex
                flexDirection={"column"}
                pt={{ base: 3, md: 3 }}
                px={{ base: 3, md: 5 }}
                pb={{ base: 5, md: 0 }}
              >
                <Flex fontSize={{ base: "14px", md: "16px" }} fontWeight={500}>
                  Price Details
                </Flex>
                <Flex
                  flexDirection={"column"}
                  fontSize={15}
                  color={"#666"}
                  lineHeight={{ base: 1.5, md: 1.75 }}
                >
                  {subTotalPrice && channelSubTotalPrice && rebookDiscount > 0 &&
                    <Flex flexDirection="column" w="100%">
                      <Flex justifyContent={"space-between"} w="100%" style={{ textDecoration: 'line-through', color: '#e95037' }} fontWeight={'bold'}>
                        <Box>
                          {getChannelDisplayName(reservationQuote.channel)} Price
                        </Box>
                        <Box>
                          {channelSubTotalPrice.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })}
                        </Box>
                      </Flex>
                      <Flex justifyContent={"space-between"} w="100%">
                        <Flex>Rebook Discount</Flex>
                        <Flex>
                          {rebookDiscount && (rebookDiscount * -1).toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })}
                        </Flex>
                      </Flex>
                    </Flex>
                  }
                  <Flex flexDirection="column" w="100%">
                    <Flex justifyContent={"space-between"} w="100%" fontWeight={rebookDiscountEligible ? '500' : 'normal'} color={rebookDiscountEligible ? "dmNavy.500" : 'unset'} mt={1}>
                      <Flex>
                        <Flex>{rebookDiscountEligible ? reservationQuote.channel === "Website" ? 'Rebook' : 'Our' : 'Subtotal'} Price</Flex>
                        <Box
                          onClick={toggleOurPriceExpand}
                          aria-label={
                            isTaxesExpanded ? "Collapse Taxes" : "Expand Taxes"
                          }
                          width={8}
                          _hover={{ cursor: "pointer" }}
                          mt={"-1px"}
                          ml={2}
                        >
                          {isOurPriceExpanded ? (
                            <ChevronUpIcon />
                          ) : (
                            <ChevronDownIcon />
                          )}
                        </Box>
                      </Flex>
                      <Flex>
                        {nightlyRatesPlusCleaning && nightlyRatesPlusCleaning.toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                        })}
                      </Flex>
                    </Flex>
                    {isOurPriceExpanded &&
                      <Flex direction={'column'} color={"#00000080"}>
                        <Flex justifyContent={"space-between"} w="100%" fontSize={'sm'} display={{ base: 'none', md: 'flex' }}>
                          <Flex>Room Rate</Flex>
                          <Flex>
                            {nightlyRates && nightlyRates.toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                            })}
                          </Flex>
                        </Flex>
                        <Flex justifyContent={"space-between"} w="100%" fontSize={'sm'} display={{ base: 'none', md: 'flex' }}>
                          <Flex>Cleaning Fee</Flex>
                          <Flex>
                            {cleaningFee && cleaningFee.toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                            })}
                          </Flex>
                        </Flex>
                      </Flex>
                    }
                    <Flex justifyContent={"space-between"} w="100%" display={{ base: 'none', md: 'flex' }}>
                      <Flex gap={2}>Processing Fee</Flex>
                      <Flex>
                        {processingFee && (processingFee + reservationFeeAdjustment).toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                        })}
                      </Flex>
                    </Flex>
                  </Flex>
                  <Flex flexDirection="column" w="100%">
                    <Flex justifyContent={"space-between"} w="100%" mt="0px">
                      <Flex>
                        <Flex>Taxes</Flex>
                        <Box
                          onClick={toggleTaxesExpand}
                          aria-label={
                            isTaxesExpanded ? "Collapse Taxes" : "Expand Taxes"
                          }
                          width={8}
                          _hover={{ cursor: "pointer" }}
                          mt={"-1px"}
                          ml={2}
                        >
                          {isTaxesExpanded ? (
                            <ChevronUpIcon />
                          ) : (
                            <ChevronDownIcon />
                          )}
                        </Box>
                      </Flex>
                      {totalTaxes && (
                        <Flex>
                          {totalTaxes.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })}
                        </Flex>
                      )}
                    </Flex>
                    {isTaxesExpanded && (
                      <Flex
                        flexDirection="column"
                        w="100%"
                        fontSize={"14px"}
                        color={"#00000080"}
                      >
                        <Flex
                          justifyContent={"space-between"}
                          w="100%"
                        >
                          <Flex>Local Tax</Flex>
                          <Flex>
                            {taxesLocal.toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                            })}
                          </Flex>
                        </Flex>
                        <Flex
                          justifyContent={"space-between"}
                          w="100%"
                        >
                          <Flex>City Tax</Flex>
                          <Flex>
                            {taxesCity.toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                            })}
                          </Flex>
                        </Flex>
                        <Flex
                          justifyContent={"space-between"}
                          w="100%"
                        >
                          <Flex>Tourism Tax</Flex>
                          <Flex>
                            {taxesTourism.toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                            })}
                          </Flex>
                        </Flex>
                      </Flex>
                    )}
                  </Flex>
                  <Divider my={1}></Divider>

                  {/* Payment Scenario 1: All Due Today */}
                  {pricingBreakdown?.depositsBreakdown?.length === 1 && pricingBreakdown?.depositsBreakdown[0]?.lineItems?.length === 1 &&
                    <>
                      <Flex
                        justifyContent={"space-between"}
                        w="100%"
                        fontWeight={"500"}
                        color={"dmNavy.500"}
                      >
                        <Flex>Total</Flex>
                        <Flex>
                          {totalPrice &&
                            totalPrice.toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                            })}
                        </Flex>
                      </Flex>
                      <Flex
                        justifyContent={"space-between"}
                        w="100%"
                        fontWeight={"500"}
                        color={"dmOrange.500"}
                      >
                        <Flex>Total Due Today</Flex>
                        <Flex>
                          {totalPrice &&
                            totalPrice.toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                            })}
                        </Flex>
                      </Flex>
                    </>
                  }

                  {/* Payment Scenario 2: Rebook: Two Payments 50/50 */}
                  {pricingBreakdown?.depositsBreakdown?.length === 1 && pricingBreakdown?.depositsBreakdown[0]?.lineItems?.length === 2 &&
                    <>
                      <Flex
                        justifyContent={"space-between"}
                        w="100%"
                        fontWeight={"500"}
                        color={"dmNavy.500"}
                      >
                        <Flex>Total</Flex>
                        <Flex>
                          {totalPrice &&
                            totalPrice.toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                            })}
                        </Flex>
                      </Flex>
                      <Flex
                        justifyContent={"space-between"}
                        w="100%"
                        fontWeight={"500"}
                        color={"dmOrange.500"}
                      >
                        <Flex>Total Due Today</Flex>
                        <Flex>
                          {pricingBreakdown.depositsBreakdown[0].lineItems[0].amount &&
                            pricingBreakdown.depositsBreakdown[0].lineItems[0].amount.toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                            })}
                        </Flex>
                      </Flex>
                      <Flex
                        justifyContent={"space-between"}
                        w="100%"
                        color={"dmNavy.400"}
                      >
                        <Flex>Total Due on {convertStringtoDateAndFormat(pricingBreakdown.depositsBreakdown[0].lineItems[1].payAt)}</Flex>
                        <Flex>
                          {pricingBreakdown.depositsBreakdown[0].lineItems[1].amount &&
                            pricingBreakdown.depositsBreakdown[0].lineItems[1].amount.toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                            })}
                        </Flex>
                      </Flex>
                    </>
                  }

                  {/* Payment Scenario 3: Rebook: Three Payments $300/50/50 */}
                  {pricingBreakdown?.depositsBreakdown?.length === 1 && pricingBreakdown?.depositsBreakdown[0]?.lineItems?.length === 3 &&
                    <>
                      <Flex
                        justifyContent={"space-between"}
                        w="100%"
                        fontWeight={"500"}
                        color={"dmNavy.500"}
                      >
                        <Flex>Total</Flex>
                        <Flex>
                          {totalPrice &&
                            totalPrice.toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                            })}
                        </Flex>
                      </Flex>
                      <Flex
                        justifyContent={"space-between"}
                        w="100%"
                        fontWeight={"500"}
                        color={"dmOrange.500"}
                      >
                        <Flex>Total Due Today</Flex>
                        <Flex>
                          {pricingBreakdown.depositsBreakdown[0].lineItems[0].amount &&
                            pricingBreakdown.depositsBreakdown[0].lineItems[0].amount.toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                            })}
                        </Flex>
                      </Flex>
                      <Flex
                        justifyContent={"space-between"}
                        w="100%"
                        color={"dmNavy.400"}
                      >
                        <Flex>Total Due on {convertStringtoDateAndFormat(pricingBreakdown.depositsBreakdown[0].lineItems[1].payAt)}</Flex>
                        <Flex>
                          {pricingBreakdown.depositsBreakdown[0].lineItems[1].amount &&
                            pricingBreakdown.depositsBreakdown[0].lineItems[1].amount.toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                            })}
                        </Flex>
                      </Flex>
                      <Flex
                        justifyContent={"space-between"}
                        w="100%"
                        color={"dmNavy.400"}
                      >
                        <Flex>Total Due on {convertStringtoDateAndFormat(pricingBreakdown.depositsBreakdown[0].lineItems[2].payAt)}</Flex>
                        <Flex>
                          {pricingBreakdown.depositsBreakdown[0].lineItems[2].amount &&
                            pricingBreakdown.depositsBreakdown[0].lineItems[2].amount.toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                            })}
                        </Flex>
                      </Flex>
                      <Flex justifyContent={"space-between"} gap={2}>
                        <Alert
                          status="info"
                          borderRadius="md"
                          fontSize="md"
                          my={3}
                          fontWeight={"normal"}
                          color={'dmNavy.700'}
                          lineHeight={1.6}
                        >
                          <AlertIcon />
                          <span>
                            Your special rebook payment terms are active! Only $300 deposit due today; change dates or houses at anytime in the future; best discount available.
                          </span>
                        </Alert>
                      </Flex>
                    </>
                  }

                  {/* Payment Scenario 4: Standard: Multiple Payment Options */}
                  {pricingBreakdown?.depositsBreakdown?.length === 3 && (
                    <>
                      <Flex justifyContent={"space-between"} w="100%" fontWeight={"500"} color={"dmNavy.500"}>
                        <Flex>Total</Flex>
                        <Flex>
                          {totalPrice &&
                            (totalPrice + reservationFeeAdjustment).toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                            })}
                        </Flex>
                      </Flex>

                      {/* Sort options so "Standard" appears first */}
                      {(() => {
                        const options = [...pricingBreakdown.depositsBreakdown]

                        return (
                          <>
                            {/* Payment Option Buttons */}
                            <Flex alignItems={'center'} mt={4} mb={1} gap={1}>
                              <Text fontWeight={'bold'} color="dmNavy.500" fontSize='md'>Flexible Payment Options</Text>
                              <Tag size="sm" variant='subtle' colorScheme='green' h={'20px'}>New!</Tag>
                            </Flex>
                            <Flex gap={{ base: 1.5, md: 2 }} flexDirection={{ base: "column", md: "row" }}>
                              {options.map((option, index) => (
                                <Button
                                  key={index}
                                  onClick={() => setSelectedPaymentOption(index)}
                                  bg={selectedOption === index ? "dmNavy.500" : "gray.200"}
                                  color={selectedOption === index ? "white" : "black"}
                                  _hover={{ bg: selectedOption === index ? "dmNavy.600" : "gray.300" }}
                                  width={{ base: "100%", md: "33%" }}
                                  size="sm"
                                  whiteSpace="normal"
                                  textAlign="center"
                                  py={2}
                                  height="auto"
                                  display="flex"
                                  flex="1"
                                  alignItems="center"
                                  justifyContent="center"
                                >
                                  {option.description}
                                </Button>
                              ))}
                            </Flex>



                            {/* Show payment breakdown for selected option */}
                            {selectedOption !== null && options[selectedOption] && (
                              <>
                                <Flex mt={2} flexDir="column">
                                  {options[selectedOption].lineItems.map((item, idx) => {
                                    const isDueToday = idx === 0;

                                    return (
                                      <Flex
                                        key={idx}
                                        justifyContent="space-between"
                                        w="100%"
                                        mt={0.5}
                                        color={isDueToday ? "dmOrange.500" : "dmNavy.400"}
                                        fontWeight={isDueToday ? 500 : "normal"}
                                      >
                                        <Flex>
                                          Due {isDueToday ? "Today" : `on ${convertStringtoDateAndFormat(item.payAt)}`}
                                        </Flex>

                                        <Flex>
                                          {item.amount &&
                                            item.amount.toLocaleString("en-US", {
                                              style: "currency",
                                              currency: "USD",
                                            })}
                                        </Flex>
                                      </Flex>
                                    );
                                  })}
                                </Flex>


                                {/*
                                  options[selectedOption].type === "full-save" &&
                                  <Flex
                                    justifyContent={"space-between"}
                                    w="100%"
                                    color={"dmOrange.500"} fontWeight="500"
                                  >
                                    <Flex>Pay in Full Savings</Flex>
                                    <Flex>
                                      {options[selectedOption].lineItems[0].amount &&
                                        (totalPrice - options[selectedOption].lineItems[0].amount).toLocaleString("en-US", {
                                          style: "currency",
                                          currency: "USD",
                                        })}
                                    </Flex>
                                  </Flex>
                                */}
                              </>
                            )}

                          </>
                        );
                      })()}
                    </>
                  )}

                  <Flex
                    gap={3}
                    w={"100%"}
                    justifyContent={"start"}
                    mt={{ base: 1, md: 2 }}
                  >
                    <Link
                      fontSize="xs"
                      color={"dmNavy.500"}
                      textDecoration={"underline"}
                      onClick={onOpenTermsModal}
                    >
                      Payment Terms
                    </Link>
                  </Flex>

                  {/* Payment Terms Modal */}
                  <Modal
                    isOpen={isTermsModalOpen}
                    onClose={onCloseTermsModal}
                    size={{ base: "full", md: "2xl" }}
                  >
                    <ModalOverlay />

                    <ModalContent position="relative">
                      <ModalHeader>
                        <ModalCloseButton />
                      </ModalHeader>
                      <ModalBody mt={0} mb={4} textAlign={"start"}>
                        {selectedPricingType.type !== 'rebook-3' &&
                          <Text fontWeight={"bold"} my={2} fontSize={"lg"}>
                            Payment Terms
                          </Text>
                        }
                        {(selectedPricingType.type === 'full' || selectedPricingType.type === 'rebook-1') &&
                          <Text>
                            As you are booking with less than 60 days to check in, the entirety of your payment will be charged in full today.
                          </Text>
                        }
                        {(selectedPricingType.type === 'full-save') &&
                          <Text>
                            You have elected to pay in full today for your reservation, the entirety of your payment will be charged in full today. A 2% discount has been applied.
                          </Text>
                        }
                        {selectedPricingType.type === 'standard' &&
                          <Text>
                            You will be charged 50% of your total payment today, and 50% of your total payment 60 days prior to check-in.
                          </Text>
                        }
                        {selectedPricingType.type === 'rebook-2' &&
                          <Text>
                            You will be charged 50% of your total payment today, and 50% of your total payment 60 days prior to check-in.
                          </Text>
                        }
                        {selectedPricingType.type === 'bnpl' &&
                          <Text>
                            You have elected to use our monthly payment plan. You will be charged in equal payments starting today until the month prior to your check in. Please refer to the previous screen for your exact payments and dates.
                          </Text>
                        }
                        {selectedPricingType.type === 'rebook-3' &&
                          <>
                            <Text fontWeight={"bold"} my={2} fontSize={"lg"}>
                              Del Mar VIP Vacation Pass Member
                            </Text>
                            <UnorderedList>
                              <ListItem>Become a member by rebooking your next stay with us after checkout and enjoy:</ListItem>
                              <UnorderedList styleType='circle'>
                                <ListItem>Only $300 non-refundable deposit down.</ListItem>
                                <ListItem>Full Refund up to 30 days prior to check-in (except for $300 deposit).</ListItem>
                                <ListItem>Exclusive discount on your next stay (12%, 10%, or 5% off!)</ListItem>
                              </UnorderedList>
                              <ListItem pt={3} fontWeight={'bold'}>Del Mar VIP Member Cancellation Policy (Rebook):</ListItem>
                              <UnorderedList styleType='circle'>
                                <ListItem><b>Full Refund:</b> If you cancel more than 30 days before check-in (minus the $300 deposit).</ListItem>
                                <ListItem><b>50% Refund:</b> If you cancel more than 14 days before check-in.</ListItem>
                                <ListItem><b>No Refund:</b> If you cancel 14 days or less to check-in.</ListItem>
                                <ListItem><b>Flexibility:</b> Change to any home or dates for just the difference in cost—no additional fees.</ListItem>
                              </UnorderedList>
                            </UnorderedList>
                            <Text py={3}><i>Cancel within 48hrs of booking to receive a full refund. If the property's availability changes for any reason, you’ll receive a full refund or have the option to be relocated to another similar property.</i></Text>
                          </>
                        }

                        {numNights && numNights >= 29 ? (
                          <>
                            <Text
                              fontWeight={"bold"}
                              my={2}
                              pt={3}
                              fontSize={"lg"}
                            >
                              Long Term Stay Cancellation Policy - For bookings longer than 28 nights:
                            </Text>
                            <UnorderedList>
                              <ListItem><b>50% Cancellation Fee:</b> if you cancel more than 30 days before check-in.</ListItem>
                              <ListItem><b>No Refund:</b> If you cancel 30 days or less to check-in.</ListItem>
                            </UnorderedList>
                            <Text py={3}><i>Cancel within 48hrs of booking to receive a full refund. If the property's availability changes for any reason, you’ll receive a full refund or have the option to be relocated to another similar property.</i></Text>
                          </>
                        ) : selectedPricingType.type !== 'rebook-3' && (
                          <>
                            <Text
                              fontWeight={"bold"}
                              my={2}
                              pt={3}
                              fontSize={"lg"}
                            >
                              48-Hour Risk Free Cancellation
                            </Text>
                            <UnorderedList>
                              <ListItem><b>Full Refund:</b> If you cancel more than 30 days before check-in.</ListItem>
                              <ListItem><b>50% Refund:</b> If you cancel more than 14 days before check-in.</ListItem>
                              <ListItem><b>No Refund:</b> If you cancel 14 days or less to check-in.</ListItem>
                            </UnorderedList>
                            <Text py={3}><i>Cancel within 48hrs of booking to receive a full refund. If the property's availability changes for any reason, you’ll receive a full refund or have the option to be relocated to another similar property.</i></Text>
                          </>
                        )}



                        {houseData &&
                          houseData.tags.includes("Dog Friendly") && (
                            <>
                              <Text
                                fontWeight={"bold"}
                                my={2}
                                pt={3}
                                fontSize={"lg"}
                              >
                                Dogs
                              </Text>
                              <Text>
                                If you are booking a pet friendly home, you
                                will have the opportunity to add and pay for
                                your dogs after you complete your booking.
                              </Text>
                            </>
                          )}
                      </ModalBody>

                    </ModalContent>
                  </Modal>
                  {/* END Payment Terms Modal */}
                </Flex>
              </Flex>
            </Box>
            {/* End Price Details */}
          </>
        )}
      </Flex>
      {/* END Right Panel yay */}
    </Flex>
  ) : calendarLoading || houseLoading || isQuoteLoading || !clientSecret ? (
    <Flex
      position={"relative"}
      width={"full"}
      data-id={"checkout"}
      p={{ base: 2, md: 5 }}
      justifyContent={"center"}
      flexDirection={"column"}
      alignItems={"center"}
      minH={"calc(100vh - 236px)"}
    >
      <Spinner size="xl" color="dmNavy.500" />
    </Flex>
  ) : (
    <Flex
      position={"relative"}
      width={"full"}
      data-id={"checkout"}
      p={{ base: 2, md: 5 }}
      justifyContent={"center"}
      flexDirection={"column"}
      alignItems={"center"}
      minH={"calc(100vh - 236px)"}
    >
      {/* Top Bar */}
      <Flex
        maxWidth="1440px"
        width={"100%"}
        p={2}
        textAlign={"left"}
        alignItems={"center"}
        flexDirection={"column"}
      >
        Reservation quote has expired.
        <Button
          h={"50px"}
          mt={6}
          onClick={returnToListing}
          colorScheme="dmOrange"
        >
          Start New Search
        </Button>
      </Flex>
    </Flex>
  );
};

export { CheckoutNew };

