import { Button, Flex, Text } from "@chakra-ui/react";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import config from "../../config";
import { useReservation } from '../../contexts/ReservationContext';
import { updateReservation } from "../../helpers/reservationService";
import DogPaymentForm from './DogPaymentForm';

const LOAD_STRIPE = loadStripe(config.stripeKey)

const DogAgreement = () => {

	const { reservationData, updateReservationData } = useReservation();

	const noDogAgreementSubmit = async () => {
		await updateReservation(reservationData.reservationNumber, { dogAgreementSigned: true });
		updateReservationData(reservationData.reservationNumber);
	}

	return (
		<Flex
			position={"relative"}
			width={"full"}
			data-id={"listing"}
			p={{ base: 2, md: 5 }}
			justifyContent={'center'}
			flexDirection={'column'}
			alignItems={'center'}
		>
			<Flex maxWidth='1440px' width={'100%'} p={2} textAlign={'left'} alignItems={'start'} flexDirection={'column'}>

				<Text pb={3} w={'100%'} textAlign={'center'} fontSize={'1.4em'} fontWeight={'700'}>
					{reservationData.firstName} {reservationData.lastName}: Dog Guest Terms
				</Text>
				{reservationData.house.tags && reservationData.house.tags.includes('Dog Friendly') ?
					<Elements stripe={LOAD_STRIPE}>
						<DogPaymentForm />
					</Elements>
					:
					<>
						<Text pt={6} fontSize={'lg'} fontWeight={500}>No Animals Permitted:</Text>
						<Text pt={3} fontSize={'md'}>This home is not authorized for dogs or animals of any kind. A fine of $1,000 and removal of your pet will occur for non-compliance. Service animals unfortunately are also not authorized at this home - however, if you have a documented Service Animal then it is authorized at one of our Dog Friendly homes and we will waive the Dog Fee but you must provide notice and documentation. Please call us to change to a dog or service animal friendly home.</Text>
						<Button colorScheme="dmOrange" mt={6} size={'lg'} onClick={noDogAgreementSubmit}>Agree and Continue</Button>
					</>
				}
			</Flex>
		</Flex>
	)
}

export { DogAgreement };

