import {
	Box, Button,
	Flex,
	FormControl, FormLabel,
	Input,
	Modal, ModalContent, ModalFooter, ModalOverlay,
	Radio, RadioGroup,
	Spinner,
	Stack, Text, useToast
} from '@chakra-ui/react';
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useState } from 'react';
import { createTipPaymentIntent } from '../../helpers/fetch';
import { updateReservation } from '../../helpers/reservationService';

// Stripe CardElement Styling
const CARD_ELEMENT_OPTIONS = {
	style: {
		base: {
			color: "rgb(26, 32, 44)",
			fontFamily: "azo-sans-web, sans-serif",
			fontSmoothing: "antialiased",
			fontSize: "1em",
			"::placeholder": {
				color: "#aab7c4",
				fontFamily: "azo-sans-web, sans-serif",
			},
			border: '1px solid rgb(226, 232, 240)',
		},
		invalid: {
			color: "#fa755a",
			iconColor: "#fa755a"
		}
	}
};

// Define your component
const TipMyCleaner = ({ reservationData, onSuccessfulPayment }) => {
	const stripe = useStripe();
	const elements = useElements();
	const toast = useToast();
	const [tipAmount, setTipAmount] = useState('');
	const [customTip, setCustomTip] = useState('');
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [modalContent, setModalContent] = useState({ message: '', isLoading: true });
	const [zipCode, setZipCode] = useState('');
	const [firstName, setFirstName] = useState(reservationData.firstName || '');
	const [lastName, setLastName] = useState(reservationData.lastName || '');
	const [email, setEmail] = useState(reservationData.agreementEmail || '');

	const closeModal = () => {
		setIsModalOpen(false);
	};

	const handleTipSubmission = async () => {
		if (!stripe || !elements) {
			toast({
				title: 'Payment service not available',
				description: "Stripe hasn't loaded yet, please try again.",
				status: 'error',
				duration: 9000,
				isClosable: true,
				position: 'top',
			});
			return;
		}

		setIsModalOpen(true);
		setModalContent({ message: 'Processing tip...', isLoading: true });

		const cardNumberElement = elements.getElement(CardNumberElement);

		if (!cardNumberElement) {
			toast({
				title: 'Payment error',
				description: "Couldn't get card details, please try again.",
				status: 'error',
				duration: 9000,
				isClosable: true,
				position: 'top',
			});
			setIsModalOpen(false);
			return;
		}

		const { error, paymentMethod } = await stripe.createPaymentMethod({
			type: 'card',
			card: cardNumberElement,
			billing_details: {
				name: `${firstName} ${lastName}`,
				email: email,
				address: {
					postal_code: zipCode,
				},
			},
		});

		if (error) {
			console.error('[createPaymentMethod Error]', error);
			setModalContent({ message: error.message, isLoading: false });
			return;
		}

		try {
			const paymentData = {
				reservationNumber: reservationData.reservationNumber,
				paymentMethodId: paymentMethod.id,
				amount: tipAmount === 'Custom' ? customTip : tipAmount,
			};

			const response = await createTipPaymentIntent(paymentData);
			if (!response.ok) {
				throw new Error('Network response was not ok');
			}

			const data = await response.json();

			const result = await stripe.confirmCardPayment(data.clientSecret);

			const totalTip = (Number(reservationData.cleaningTipPaid) + Number((tipAmount === 'Custom' ? customTip : tipAmount))).toString();

			if (result.error) {
				throw result.error;
			} else {
				await updateReservation(
					reservationData.reservationNumber,
					{ cleaningTipPaid: totalTip }
				);
				setModalContent({ message: 'Tip Successful. Thank you for your generosity!', isLoading: false });
				toast({
					title: 'Tip Successful.',
					description: "Thank you for your generosity!",
					status: 'success',
					duration: 9000,
					isClosable: true,
					position: 'top',
				});
				onSuccessfulPayment();
			}
		} catch (error) {
			console.error('Payment error:', error);
			setModalContent({ message: 'Failed to process tip. Please try again.', isLoading: false });
		}
	};

	return (
		<Flex flexDirection={'column'} alignItems={'center'} w={'100%'}>
			<Box fontSize={'1.75em'} textAlign='center' maxW={'400px'}>Our cleaning staff consistently achieves a 5-star standard.</Box>
			<Box fontSize={'0.9em'} textAlign='center' py={4}>Your dedicated house cleaner worked hard to prepare this home for your vacation! If deserving, would you consider a small gratuity?</Box>
			{reservationData.cleaningTipPaid > 0 &&
				<Box fontSize={'1em'} textAlign='start' pt={4} pb={6} w={'100%'}>Thank you for your tip of ${reservationData.cleaningTipPaid}. If you'd like to leave an additional gratuity, please use the form below.</Box>
			}
			<FormControl as="fieldset">
				<FormLabel as="legend">How much would you like to tip?</FormLabel>
				<RadioGroup onChange={setTipAmount} value={tipAmount}>
					<Stack direction="column" >
						<Stack direction="row" spacing="5">
							<Radio value="10">$10</Radio>
							<Radio value="20">$20</Radio>
							<Radio value="40">$40</Radio>
							<Radio value="Custom">Custom</Radio>
						</Stack>

						{tipAmount === 'Custom' && (
							<Input
								placeholder="Enter custom amount"
								type="number"
								value={customTip}
								onChange={(e) => setCustomTip(e.target.value)}
							/>
						)}
					</Stack>
				</RadioGroup>
			</FormControl>
			{(!reservationData.firstName || !reservationData.lastName) && (
				<Flex gap={2} mt={4} mb={2} w={'100%'}>
					<FormControl>
						<FormLabel fontSize={'0.9em'}>First Name</FormLabel>
						<Input
							value={firstName}
							onChange={(e) => setFirstName(e.target.value)}
							placeholder="First Name"
							fontSize={'0.9em'}
						/>
					</FormControl>
					<FormControl>
						<FormLabel fontSize={'0.9em'}>Last Name</FormLabel>
						<Input
							value={lastName}
							onChange={(e) => setLastName(e.target.value)}
							placeholder="Last Name"
							fontSize={'0.9em'}
						/>
					</FormControl>
				</Flex>
			)}
			{!reservationData.agreementEmail && (
				<FormControl mb={3}>
					<FormLabel fontSize={'0.9em'}>Email</FormLabel>
					<Input
						value={email}
						onChange={(e) => setEmail(e.target.value)}
						placeholder="Email"
						fontSize={'0.9em'}
					/>
				</FormControl>
			)}

			<Box w={'100%'} mb={3}>
				<Box mb={4}>
					<FormLabel fontSize={'0.9em'} mb={1}>Credit Card</FormLabel>
					<Flex w={'100%'} justifyContent={'space-between'} gap={2}>
						<Box border="1px solid rgb(226, 232, 240)" p={2.5} borderRadius={8} w={'40%'}>
							<CardNumberElement
								options={CARD_ELEMENT_OPTIONS}
							/>
						</Box>
						<Box border="1px solid rgb(226, 232, 240)" p={2.5} borderRadius={8} w={'20%'}>
							<CardExpiryElement
								options={CARD_ELEMENT_OPTIONS}
							/>
						</Box>
						<Box border="1px solid rgb(226, 232, 240)" p={2.5} borderRadius={8} w={'20%'}>
							<CardCvcElement
								options={CARD_ELEMENT_OPTIONS}
							/>
						</Box>
						<FormControl id="zipCode" w={'20%'}>
							<Input type="text" value={zipCode} onChange={(e) => setZipCode(e.target.value)} placeholder={'Zip Code'}
								_placeholder={{ color: 'gray.400' }}
								fontSize="0.9em"
								fontFamily="azo-sans-web, sans-serif"
								border="1px solid rgb(226, 232, 240)"
								borderRadius="8px"
								padding="10px"
							/>
						</FormControl>
					</Flex>
				</Box>
			</Box>

			<Button mb={4} colorScheme="dmOrange" onClick={handleTipSubmission} isLoading={isModalOpen}>
				Submit Tip
			</Button>

			<Modal isOpen={isModalOpen} isCentered size="lg" onClose={closeModal}>
				<ModalOverlay />
				<ModalContent>
					<Flex direction="column" align="center" justify="center" p={5} mt={5} mb={10}>
						{modalContent.isLoading ? <Spinner size="xl" /> : null}
						<Text mt={4}>{modalContent.message}</Text>
					</Flex>
				</ModalContent>
				<ModalFooter>
					<Button my={4} colorScheme="dmOrange" onClick={() => setIsModalOpen(false)} isDisabled={modalContent.isLoading}>
						Close
					</Button>
				</ModalFooter>
			</Modal>
		</Flex>
	);
};

export { TipMyCleaner };

