
import { Box, Button, Skeleton } from "@chakra-ui/react";
import GoogleMapReact from 'google-map-react';
import { isNumber } from "lodash";
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { SearchResultsContext } from '../contexts/SearchResultsContext';




const CAPE_COD_CENTER = { lat: 41.7688, lng: -70.2962 };
const MAX_ZOOM_LEVEL = 17;





const getInfoWindowString = (listing) => `
    <div class="info-window-content" style="max-width:200px; cursor: pointer;text-align:left;">
      <div style="font-size: 16px;">
        <img src="${listing.thumbnail ? listing.thumbnail : listing.pictures[0].original}" width="100%" loading="lazy">
      </div>
      <div style="font-size: 16px;margin-top:5px;">
        ${listing.houseNumber} - ${listing.title}
      </div>
      <div style="justify-content:space-between; display:flex;">
        <div style="font-size: 14px; color: grey;margin-top:5px;">
          $${listing.price.toFixed(0)} per night
        </div>
        <div style="font-size: 14px;margin-top:3px;">
          <span style="color: orange;">${String.fromCharCode(9733).repeat(Math.round(listing.rating))}</span><span style="color: lightgrey;">${String.fromCharCode(9733).repeat(5 - Math.round(listing.rating))}</span>
        </div>
      </div>
    </div>`;





const SearchMap = ({ navigate, displayedSearchResults }) => {
  const { loading,
    boundingBox, setBoundingBox,
    setTriggerSearch,
    boundingBoxMoved, setBoundingBoxMoved,
    setMapAreaSearch, setTown
  } = React.useContext(SearchResultsContext);
  const [mapObjects, setMapObjects] = useState({ map: null, maps: null });
  const [updatingMarkers, setUpdatingMarkers] = useState(false)
  const [canChangeBoundingBoxMoved, setCanChangeBoundingBoxMoved] = useState(false);
  const [mapMarkersLoading, setMapMarkersLoading] = useState(false);

  //const [canUpdateResults, setCanUpdateResults] = useState(true)
  const mapRef = useRef(null)
  const markersRef = useRef([]);

  // Limit displayedSearchResults to up to 20 items
  const limitedSearchResults = displayedSearchResults.slice(0, 20);


  const handleApiLoaded = ({ map, maps }) => {
    // Set map objects
    setMapObjects({ map, maps });

    // Set the maxZoom option (if needed)
    map.setOptions({
      maxZoom: MAX_ZOOM_LEVEL, // Optional, only if you're enforcing a maxZoom
    });

    // Add a listener for zoom changes
    maps.event.addListener(map, 'zoom_changed', () => {
      const currentZoom = map.getZoom();
      // Call function to update markers based on zoom level
      updateMarkerIcons(currentZoom, maps);
    });
  };





  const updateMarkerIcons = (zoomLevel, maps) => {
    const customMarkerIcon = {
      url: 'media/icons/house_icon_orange_border.png', // URL of your custom marker image
      scaledSize: new maps.Size(20, 20), // Adjust size as needed
    };

    const orangeCircle = {
      path: maps.SymbolPath.CIRCLE,
      scale: 120, // Size of the circle
      strokeColor: '#e95037',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: '#e95037',
      fillOpacity: 0.35,
    };

    markersRef.current.forEach(marker => {
      marker.setIcon(zoomLevel > 16 ? orangeCircle : customMarkerIcon);
    });
  };





  // Function to update markers
  const updateMarkers = useCallback(() => {
    setMapMarkersLoading(true)
    setBoundingBoxMoved(false)
    if (!mapObjects.map || !mapObjects.maps) {
      //console.log("Map object not available yet.");
      return;
    }

    const customMarkerIcon = {
      url: 'media/icons/house_icon_orange_border.png', // URL of your custom marker image
      scaledSize: new mapObjects.maps.Size(20, 20), // Adjust size as needed
    };

    if (!limitedSearchResults || limitedSearchResults.length === 0) {
      return;
    }

    // Clear existing markers
    markersRef.current.forEach(marker => marker.setMap(null));
    markersRef.current = [];

    setUpdatingMarkers(true);

    markersRef.current.forEach(marker => marker.setMap(null));
    markersRef.current = [];

    const bounds = new mapObjects.maps.LatLngBounds();
    let openInfowindow = null;

    const closeOpenInfowindow = () => {
      if (openInfowindow) {
        openInfowindow.close();
        openInfowindow = null;
      }
    };

    // Ensure displayedSearchResults is valid before processing
    const validResults = limitedSearchResults.filter(listing =>
      listing &&
      typeof listing.latitude === 'number' &&
      typeof listing.longitude === 'number' &&
      !isNaN(listing.latitude) &&
      !isNaN(listing.longitude)
    );

    const generateRandomOffset = (degree = 0.0005) => {
      // Generates a random number between -degree and +degree
      return Math.random() * degree * 2 - degree;
    };

    validResults.forEach(listing => {
      const lat = Number(listing.latitude);
      const lng = Number(listing.longitude);

      if (!isNaN(lat) && !isNaN(lng) && isNumber(lat) && isNumber(lng) && lat !== 0 && lng !== 0) {
        // Apply a small, random offset to latitude and longitude
        const offsetLat = lat + generateRandomOffset();
        const offsetLng = lng + generateRandomOffset();

        const markerPosition = new mapObjects.maps.LatLng(offsetLat, offsetLng);
        const marker = new mapObjects.maps.Marker({
          position: markerPosition,
          map: mapObjects.map,
          icon: customMarkerIcon,
        });

        bounds.extend(markerPosition);
        markersRef.current.push(marker);

        if (listing.thumbnail || listing.pictures[0]) {
          const infoWindowContent = getInfoWindowString(listing);
          const infoWindow = new mapObjects.maps.InfoWindow({ content: infoWindowContent });

          mapObjects.maps.event.addListener(infoWindow, 'domready', () => {
            document.querySelectorAll('.info-window-content').forEach(elem => {
              elem.addEventListener('click', () => navigate(`/${listing.houseNumber}`));
            });
          });

          marker.addListener('click', () => {
            closeOpenInfowindow();
            infoWindow.open(mapObjects.map, marker);
            openInfowindow = infoWindow;
          });
        }
      }
    });

    if (validResults.length === 1) {
      const singleResult = validResults[0];
      const latLng = new mapObjects.maps.LatLng(Number(singleResult.latitude), Number(singleResult.longitude));
      mapObjects.map.setCenter(latLng);
      mapObjects.map.setZoom(16);
    } else if (validResults.length > 1) {
      mapObjects.map.fitBounds(bounds);
    } else {
      mapObjects.map.setCenter(CAPE_COD_CENTER);
      mapObjects.map.setZoom(10);
    }

    setUpdatingMarkers(false);
    setBoundingBoxMoved(false);
    setMapMarkersLoading(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapObjects, displayedSearchResults]);


  // Make it so that the Refresh Listings Based on Map button is disabled for first 5 seconds
  useEffect(() => {
    setCanChangeBoundingBoxMoved(false)
    setBoundingBoxMoved(false);
    //console.log('boundingBoxMoved', boundingBoxMoved, 'canChangeBoundingBoxMoved', canChangeBoundingBoxMoved)
    setTimeout(() => {
      setBoundingBoxMoved(false)
      setCanChangeBoundingBoxMoved(true)
    }, 5000);
    //console.log('timeout ended')
    setBoundingBoxMoved(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const getBounds = async () => {

    const movedMargin = 4

    if (!mapRef) {
      //console.log("getBounds -- no mapRef");
      return
    }

    if (!mapRef.current) {
      //console.log("getBounds -- no mapRef.current");
      return
    }

    if (!mapRef.current.map_) {
      //console.log("getBounds -- no mapRef.current.map_");
      return
    }

    const currentBoundingBox = {
      bbLatitude: mapRef.current.map_.getBounds().getNorthEast().lat(),
      bbLongitude: mapRef.current.map_.getBounds().getNorthEast().lng(),
      bbOppositeLatitude: mapRef.current.map_.getBounds().getSouthWest().lat(),
      bbOppositeLongitude: mapRef.current.map_.getBounds().getSouthWest().lng(),
    }

    if (boundingBox === null) {
      //console.log("getBounds -- boundingBox === null")

    } else {

      if (currentBoundingBox.bbLatitude.toFixed(movedMargin) === boundingBox.bbLatitude.toFixed(movedMargin)
        && currentBoundingBox.bbLongitude.toFixed(movedMargin) === boundingBox.bbLongitude.toFixed(movedMargin)
        && currentBoundingBox.bbOppositeLatitude.toFixed(movedMargin) === boundingBox.bbOppositeLatitude.toFixed(movedMargin)
        && currentBoundingBox.bbOppositeLongitude.toFixed(movedMargin) === boundingBox.bbOppositeLongitude.toFixed(movedMargin)) {
        //console.log("getBounds -- No change in bounding box")
        return
      }

    }

    setBoundingBox(currentBoundingBox)
    if (canChangeBoundingBoxMoved) setBoundingBoxMoved(true)
    //console.log("currentBoundingBox:", currentBoundingBox)
  }

  const refreshListingsBasedOnMap = () => {
    setCanChangeBoundingBoxMoved(false)
    setBoundingBoxMoved(false)
    setTimeout(() => {
      setCanChangeBoundingBoxMoved(true)
    }, 5000);
    setMapAreaSearch(true);
    setTown(null);
    setTriggerSearch(prevTrigger => prevTrigger + 1);
  }


  useEffect(() => {
    updateMarkers();
    // Cleanup function to remove markers when component unmounts or data changes
    return () => {
      markersRef.current.forEach(marker => marker.setMap(null));
      markersRef.current = [];
    };
  }, [updateMarkers]);


  return (
    <Box width="100%" h="100%">
      {(loading || updatingMarkers) ? <Skeleton w={'100%'} h={'100%'} /> :
        <>
          <Button
            position={"absolute"} top={2} left={2} zIndex={1}
            m={2} py={2} px={4}
            border={"solid 1px"} borderColor={"dmNavy.100"} backgroundColor={"white"} borderRadius={8}
            textAlign={'left'} alignItems={'center'}
            _hover={{ 'backgroundColor': '#D3D3D3' }}
            onClick={refreshListingsBasedOnMap}
            isDisabled={!boundingBoxMoved} visibility={(mapMarkersLoading || canChangeBoundingBoxMoved && boundingBoxMoved) ? 'visible' : 'hidden'}
          >
            Search Map Area
          </Button>

          <GoogleMapReact
            ref={mapRef}
            bootstrapURLKeys={{ key: "AIzaSyAH2xQVEEEVPM_w1G6IgqOHsmbJF0L-ihE" }}
            defaultZoom={11}
            defaultCenter={CAPE_COD_CENTER}
            onGoogleApiLoaded={handleApiLoaded}
            yesIWantToUseGoogleMapApiInternals
            onChange={getBounds}
          >
          </GoogleMapReact>
        </>
      }
    </Box>
  );
};

export default SearchMap;
