import {
  Button,
  Flex,
  FormControl,
  Grid,
  GridItem,
  HStack,
  Input,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Select,
  Switch,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useUser } from "@clerk/clerk-react";
import { addDays, format, parseISO } from "date-fns";
import * as React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FiSearch } from "react-icons/fi";
import { useLocation, useNavigate } from "react-router-dom";
import { SearchResultsContext } from "../../../contexts/SearchResultsContext";
import { fetchGlobalCalendar } from "../../../helpers/availabilityService";
import {
  fetchSearchResults,
  getFavoritesWithHouseData,
} from "../../../helpers/fetch";
import { formatDateToYYYYMMDD } from "../../../helpers/helpers";
import { MobileSearch } from "./Mobile";
import { MAX_END_DATE, MAX_GUESTS, MIN_GUESTS, TOWNS } from "./constants";

function SearchWidget() {
  const navigate = useNavigate();
  const location = useLocation();

  /* This is where the context gets set to pass into the SearchResultsGrid */
  const {
    searchResults,
    setSearchResults,
    setFlexSearchResults,
    queryOffset,
    setTotalResults,
    setLoading,
    setBufferLoading,
    setQueryOffset,
    guests,
    setGuests,
    dogs,
    setDogs,
    contextDateRange,
    setContextDateRange,
    setPriceSearch,
    setMaxSearchPriceValue,
    setMinSearchPriceValue,
    setPathingFilterSettings,
    searchFilterOptions,
    setSearchFilterOptions,
    userFavorites,
    setUserFavorites,
    setFavoritesLoading,
    setNumFavorites,
    setUserEmail,
    setEmailLoading,
    setGlobalCheckIns,
    globalCalendarLoading,
    setGlobalCalendarLoading,
    setSearchWithDates,
    boundingBox,
    setBoundingBox,
    triggerSearch,
    mapAreaSearch,
    setMapAreaSearch,
    boundingBoxMoved,
    setMapDisplayOnLoad,
    setRandomResults,
  } = React.useContext(SearchResultsContext);

  const { user } = useUser(); // Clerk
  const [town, setTown] = React.useState(null);
  const [startDate, endDate] = contextDateRange;
  const [searchMode, setSearchMode] = React.useState(false);
  const [fetchKey, setFetchKey] = React.useState(0);
  const [initialLoad, setInitialLoad] = React.useState(false);
  const [conditionalCalendarProps, setConditionalCalendarProps] =
    React.useState(null);
  const [datesToGrayOut, setDatesToGrayOut] = React.useState([]);

  const storedValue = localStorage.getItem("dm-rebook-reservation");
  const [rebookReservation, setRebookReservation] = React.useState(
    storedValue === "null" ? null : storedValue
  );


  // Mobile-Related Items for Modal Search Menu
  const isMobile = useBreakpointValue({ base: true, md: false }); // for Mobile Search Bar
  const [isModalOpen, setModalOpen] = React.useState(false); // State to control modal
  const openModal = () => setModalOpen(true); // Function to open the modal
  const closeModal = () => setModalOpen(false); // Function to close the modal

  // Mobile Click Handling function
  const closeModalAndSearch = () => {
    closeModal();
    handleSearchClick();
  };

  /* When the user clicks the search button, we are now performing an actual Search (as opposed to Explore that just shows 20 sample listings) 
  That means we onSearch (???), set searchMode to true (to get filtered results), and increment fetchKey just to re-render/re-trigger data fetching */
  const handleSearchClick = () => {
    if ((startDate && endDate) || guests || town || dogs) {
      //console.log("Sufficient Search Data. Searching with:", startDate, endDate, guests, town)
      setSearchMode(true); // Listings Search
      setPriceSearch(true);
    } else {
      //console.log("Insufficient Search Data. Explore search.", startDate, endDate, guests, town)
      setSearchMode(false); // Force Explore Search
      setPriceSearch(false);
    }

    if ((startDate && !endDate) || (!startDate && endDate)) {
      setContextDateRange([null, null]); // Clear out partial date ranges
    }

    if (startDate && endDate) {
      setSearchWithDates(true);
    } else {
      setSearchWithDates(false);
    }

    //console.log('rebookReservation', rebookReservation);

    setFetchKey((prevKey) => prevKey + 1);
    let query = `/?town=${town ?? ''}&startdate=${startDate ? formatDateToYYYYMMDD(startDate) : ''}&enddate=${endDate ? formatDateToYYYYMMDD(endDate) : ''}&guests=${guests ?? ''}&tags=${dogs ? 'Dog%20Friendly' : ''}`;

    if (rebookReservation !== null) {
      query += `&rebook=${rebookReservation}`;
    }

    navigate(query);
  };


  React.useEffect(() => {
    if (
      town in
      [
        "Barnstable",
        "Bourne",
        "Brewster",
        "Chatham",
        "Dennis",
        "Eastham",
        "Falmouth",
        "Harwich",
        "Mashpee",
        "Orleans",
        "Provincetown",
        "Sandwich",
        "Truro",
        "Wellfleet",
        "Yarmouth",
      ]
    ) {
      setMapAreaSearch(false);
      setBoundingBox(null);
    } else if (mapAreaSearch) {
      setTown(null);
    }

    const fetchRebookDataAndSearch = async () => {
      try {
        // Set attribution from URL
        const setAttributionFromUrl = () => {
          const urlParams = new URLSearchParams(window.location.search);
          const attribution = urlParams.get("source");
          const rebookMode = urlParams.get("rebook");

          if (attribution) {
            localStorage.setItem("dm-attribution", attribution);
          }
          if (rebookMode && rebookMode !== 'null') {
            localStorage.setItem("dm-rebook-mode", Date.now().toString());
            localStorage.setItem("dm-rebook-reservation", rebookMode);
          }
        };

        setAttributionFromUrl();

        // Check localStorage for rebook mode
        const rebookValue = localStorage.getItem("dm-rebook-reservation");
        if (rebookValue && rebookValue !== 'null') {
          setRebookReservation(rebookValue);
        }

        fetchData(rebookValue);

      } catch (error) {
        console.error("Error in fetchRebookDataAndSearch:", error);
      }
    };

    fetchRebookDataAndSearch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchKey, triggerSearch, location]);


  // Search Input
  const getSearchInput = () => {
    let tags = [];

    // Add "Dog Friendly" tag if dog toggled to true
    if (dogs) {
      tags.push("Dog Friendly");
    }

    //console.log('boundingbox',boundingBox)

    const returnData = {
      city: town && !mapAreaSearch && town !== "All Towns" ? town : null,
      checkIn: startDate ? format(startDate, "yyyy-MM-dd") : null,
      checkOut: endDate ? format(endDate, "yyyy-MM-dd") : null,
      minOccupancy: guests ? guests : 1,
      tags: tags,
      bbLatitude: mapAreaSearch && boundingBox ? boundingBox.bbLatitude : null,
      bbLongitude:
        mapAreaSearch && boundingBox ? boundingBox.bbLongitude : null,
      bbOppositeLatitude:
        mapAreaSearch && boundingBox ? boundingBox.bbOppositeLatitude : null,
      bbOppositeLongitude:
        mapAreaSearch && boundingBox ? boundingBox.bbOppositeLongitude : null,
      rebookReservation: rebookReservation,
      rebookCode: rebookReservation,
    };

    return returnData;
  };

  function decrementGuestInput() {
    setGuests((prevGuests) => {
      const currentGuests = isNaN(prevGuests) ? MIN_GUESTS : prevGuests;
      return Math.max(MIN_GUESTS, Number(currentGuests) - 1);
    });
  }

  function incrementGuestInput() {
    setGuests((prevGuests) => {
      const currentGuests = isNaN(prevGuests) ? MIN_GUESTS : prevGuests;
      return Math.min(MAX_GUESTS, Number(currentGuests) + 1);
    });
  }

  function renderInputValue(guests, dogs) {
    let value = "";
    if (guests) value += guests + " Guests";

    if (dogs) {
      if (value) value += " +";
      value += " Dogs";
    }
    return value;
  }

  function getMonthShortName(monthNo) {
    const date = new Date();
    date.setMonth(monthNo - 1);
    return date.toLocaleString("en-US", { month: "short" });
  }

  const fetchData = async (rebookCode = null) => {
    try {
      setLoading(true); // Set loading to true before fetching
      let response;
      //console.log("initialLoad", initialLoad)
      //console.log("fetchData window.location.pathname", window.location.pathname)

      // Fetching URL params, if any
      // (Even though it says "!initialLoad", this is what happens on the initial load of the page)
      if (!initialLoad && window.location.pathname === "/") {
        const urlSearchString = window.location.search;
        const params = new URLSearchParams(urlSearchString);

        // Convert all parameter names to lowercase for case-insensitive access
        const normalizedParams = {};
        for (const [key, value] of params) {
          normalizedParams[key.toLowerCase()] = value;
        }

        let filters: any = {};
        let contextDates = [];

        filters.city = normalizedParams["town"]
          ? normalizedParams["town"][0].toUpperCase() +
          normalizedParams["town"].slice(1).toLowerCase()
          : normalizedParams["city"]
            ? normalizedParams["city"][0].toUpperCase() +
            normalizedParams["city"].slice(1).toLowerCase()
            : null;

        if (mapAreaSearch || boundingBoxMoved) {
          filters.city = null;
        }

        //console.log('normalizedParams', normalizedParams)

        if (normalizedParams["quotesearch"] === "true") {
          setSearchWithDates(true);
        } else {
          setSearchWithDates(false);
        }

        if (normalizedParams["map"] === "false") {
          setMapDisplayOnLoad(false);
        } else {
          setMapDisplayOnLoad(true);
        }

        if (normalizedParams["sort"] === "random") {
          setRandomResults(true);
          filters.random = true;
        } else {
          setRandomResults(false);
          filters.random = false;
        }

        filters.checkIn = normalizedParams["startdate"]
          ? normalizedParams["startdate"]
          : normalizedParams["checkin"]
            ? normalizedParams["checkin"]
            : normalizedParams["startdate"]
              ? normalizedParams["startdate"]
              : null;

        filters.checkOut = normalizedParams["enddate"]
          ? normalizedParams["enddate"]
          : normalizedParams["checkout"]
            ? normalizedParams["checkout"]
            : normalizedParams["enddate"]
              ? normalizedParams["enddate"]
              : null;

        filters.minOccupancy = normalizedParams["guests"]
          ? normalizedParams["guests"]
          : normalizedParams["minoccupancy"]
            ? normalizedParams["minoccupancy"]
            : null;

        filters.tags = normalizedParams["tags"]
          ? normalizedParams["tags"].split(",")
          : [];

        // Bounding box functionality
        filters.bbLatitude = boundingBox ? boundingBox.bbLatitude : null;
        filters.bbLongitude = boundingBox ? boundingBox.bbLongitude : null;
        filters.bbOppositeLatitude = boundingBox
          ? boundingBox.bbOppositeLatitude
          : null;
        filters.bbOppositeLongitude = boundingBox
          ? boundingBox.bbOppositeLongitude
          : null;

        let tabTitle = "Del Mar Vacations";

        if (filters.city) {
          tabTitle = tabTitle + " | " + filters.city;
        }

        if (filters.checkIn && filters.checkOut) {
          let dateTitleString =
            getMonthShortName(filters.checkIn.split("-")[1]) +
            " " +
            filters.checkIn.split("-")[2] +
            "-" +
            filters.checkOut.split("-")[2] +
            ", " +
            filters.checkIn.split("-")[0];
          tabTitle = tabTitle + " | " + dateTitleString;
        }

        if (filters.minOccupancy) {
          tabTitle = tabTitle + " | " + filters.minOccupancy + " Guests";
        }

        if (filters.tags.length > 0) {
          if (filters.tags.includes("Dog Friendly")) setDogs(true);
          for (let i = 0; i < filters.tags.length; i++)
            tabTitle = tabTitle + " | " + filters.tags[i];
        }

        document.title = tabTitle;

        filters.minPrice = normalizedParams["minprice"]
          ? normalizedParams["minprice"]
          : null;
        filters.maxPrice = normalizedParams["maxprice"]
          ? normalizedParams["maxprice"]
          : null;

        if (normalizedParams["numberofbathrooms"]) {
          let minBathroomsNumber = Number(
            normalizedParams["numberofbathrooms"]
          );
          filters.selectedBathrooms = [];
          filters.numberOfBathrooms = minBathroomsNumber;

          for (
            minBathroomsNumber;
            minBathroomsNumber <= 7;
            minBathroomsNumber++
          ) {
            if (minBathroomsNumber === 7) {
              filters.selectedBathrooms.push("7+");
            } else {
              filters.selectedBathrooms.push(minBathroomsNumber.toString());
            }
          }
        }

        if (normalizedParams["numberofbedrooms"]) {
          let minBedroomsNumber = Number(normalizedParams["numberofbedrooms"]);
          filters.selectedBedrooms = [];
          filters.numberOfBedrooms = minBedroomsNumber;

          for (minBedroomsNumber; minBedroomsNumber <= 7; minBedroomsNumber++) {
            if (minBedroomsNumber === 7) {
              filters.selectedBedrooms.push("7+");
            } else {
              filters.selectedBedrooms.push(minBedroomsNumber.toString());
            }
          }
        }

        setTown(filters.city);

        contextDates.push(
          filters.checkIn ? new Date(filters.checkIn + "T00:00:00") : null
        );
        contextDates.push(
          filters.checkOut ? new Date(filters.checkOut + "T00:00:00") : null
        );
        setContextDateRange(contextDates);

        setGuests(filters.minOccupancy);

        filters.rebookCode = rebookCode;

        //console.log('461: filters', filters)

        let searchQueryFilters = { ...filters };
        delete searchQueryFilters.selectedBedrooms;
        delete searchQueryFilters.selectedBathrooms;

        response = await fetchSearchResults(searchQueryFilters);

        const results = response.results;
        const flexResults = response.flexResults;

        //console.log('searchFilterOptions', searchFilterOptions)

        setSearchFilterOptions(filters);

        if (
          !Object.values(filters).every(
            (value) =>
              value === null || (Array.isArray(value) && value.length === 0)
          )
        ) {
          setTotalResults(results.length);
          setPathingFilterSettings(filters);

        } else {
          setTotalResults(results.length); // Update context data with total results from backend for pagination purposes
          if (!filters.tags) filters.tags = [];
        }

        setSearchResults(results); // Update context data with search results array of houses
        setFlexSearchResults(flexResults.slice(0, 15));

        if (results.length > 0) {
          const prices = results.map((listing) => listing.price);
          setMaxSearchPriceValue(Math.round(Math.max(...prices)));
          setMinSearchPriceValue(Math.round(Math.min(...prices)));
        }

        setInitialLoad(true);

        // If this is NOT the initial load of the page (even though it says "setInitialLoad(true)" above...
      } else if (
        window.location.pathname === "/" ||
        window.location.pathname === "/search-results/" ||
        window.location.pathname === "/search-results" ||
        window.location.pathname.includes("/search-results") ||
        window.location.pathname === "/properties/" ||
        window.location.pathname === "/properties" ||
        window.location.pathname.includes("/properties") ||
        window.location.pathname === "/listings/" ||
        window.location.pathname === "/listings" ||
        window.location.pathname.includes("/listings")
      ) {
        //console.log("*** boundingBox", boundingBox)

        // If it's searchMode, use filters
        if (searchMode || boundingBox !== null) {
          //console.log("+++ IS SEARCHMODE")
          let urlParamsString = "/?";
          let tabTitle = "Del Mar Vacations";

          if (town && !mapAreaSearch) {
            urlParamsString = urlParamsString + "town=" + town;
            tabTitle = tabTitle + " | " + town;
          }

          if (startDate && endDate) {
            const formattedStartDate = format(startDate, "yyyy-MM-dd"); // Formats as 'YYYY-MM-DD'
            const formattedEndDate = format(endDate, "yyyy-MM-dd"); // Formats as 'YYYY-MM-DD'

            if (urlParamsString === "/?") {
              urlParamsString =
                urlParamsString +
                "startdate=" +
                formattedStartDate +
                "&enddate=" +
                formattedEndDate;
            } else {
              urlParamsString =
                urlParamsString +
                "&startdate=" +
                formattedStartDate +
                "&enddate=" +
                formattedEndDate;
            }
            let dateTitleString =
              getMonthShortName(startDate.getMonth() + 1) +
              " " +
              startDate.getDate() +
              "-" +
              endDate.getDate() +
              ", " +
              startDate.getFullYear();
            tabTitle = tabTitle + " | " + dateTitleString;
          }

          if (guests) {
            if (urlParamsString === "/?") {
              urlParamsString = urlParamsString + "guests=" + guests;
            } else {
              urlParamsString = urlParamsString + "&guests=" + guests;
            }
            tabTitle = tabTitle + " | " + guests + " Guests";
          }

          if (dogs) {
            if (urlParamsString === "/?") {
              urlParamsString = urlParamsString + "tags=Dog Friendly";
            } else {
              urlParamsString = urlParamsString + "&tags=Dog Friendly";
            }
            tabTitle = tabTitle + " | Dog Friendly";
          }

          if (rebookCode && rebookCode !== 'null') {
            if (urlParamsString === "/?") {
              urlParamsString = urlParamsString + "rebook=" + rebookCode;
            } else {
              urlParamsString = urlParamsString + "&rebook=" + rebookCode;
            }
            tabTitle = tabTitle + " | Rebooking";
          }

          if (searchFilterOptions?.numberOfBedrooms) {
            if (urlParamsString === "/?") {
              urlParamsString = urlParamsString + "numberOfBedrooms=" + searchFilterOptions.numberOfBedrooms;
            } else {
              urlParamsString = urlParamsString + "&numberOfBedrooms=" + searchFilterOptions.numberOfBedrooms;
            }
            tabTitle = tabTitle + " | " + searchFilterOptions.numberOfBedrooms + "bd";
          }

          if (searchFilterOptions?.numberOfBathrooms) {
            if (urlParamsString === "/?") {
              urlParamsString = urlParamsString + "numberOfBathrooms=" + searchFilterOptions.numberOfBathrooms;
            } else {
              urlParamsString = urlParamsString + "&numberOfBathrooms=" + searchFilterOptions.numberOfBathrooms;
            }
            tabTitle = tabTitle + " | " + searchFilterOptions.numberOfBathrooms + "bd";
          }
          //console.log('searchFilterOptions', searchFilterOptions)
          if (searchFilterOptions?.tags && searchFilterOptions?.tags.length > 0) {
            // Extract existing tags from the previous block (if they exist)
            const existingTagsMatch = urlParamsString.match(/tags=([^&]*)/);
            const existingTags = existingTagsMatch ? decodeURIComponent(existingTagsMatch[1]).split(",") : [];

            // Merge existing tags with new ones from pathingFilterSettings, ensuring no duplicates
            const allTags = Array.from(new Set([...existingTags, ...searchFilterOptions.tags]));

            // Remove any previous "tags=" entry from the URL
            urlParamsString = urlParamsString.replace(/&?tags=[^&]*/, "");

            // Append the updated tags list
            if (urlParamsString === "/?") {
              urlParamsString += `tags=${encodeURIComponent(allTags.join(","))}`;
            } else {
              urlParamsString += `&tags=${encodeURIComponent(allTags.join(","))}`;
            }

            tabTitle += " | " + allTags.join(" | ");
          }

          window.history.pushState({}, "", urlParamsString);
          document.title = tabTitle;
          setQueryOffset(0);
          //console.log("getSearchInput()", getSearchInput());
          //console.log("616 filters sent to fetchSearchResults", getSearchInput());
          response = await fetchSearchResults(getSearchInput());
        } else {
          // If its NOT searchmode, don't use filters
          //console.log("--- NOT SEARCHMODE")
          //console.log("fetching search without filters");
          response = await fetchSearchResults();
        }

        const results = response.results;
        const flexResults = response.flexResults;

        setTotalResults(results.length)
        setSearchResults(results); // Update context data with search results array of houses
        setFlexSearchResults(flexResults.slice(0, 15));

        if (results.length > 0) {
          const prices = results.map((listing) => listing.price);
          setMaxSearchPriceValue(Math.round(Math.max(...prices)));
          setMinSearchPriceValue(Math.round(Math.min(...prices)));
        }
      }
    } catch (error) {
      console.error("Error fetching results:", error);
    } finally {
      setLoading(false); // Set loading to false after fetching (or if there's an error)
    }
  };

  const fetchAdditionalData = async () => {
    try {
      setBufferLoading(true); // Set loading to true before fetching

      //console.log('fetching additional data search')
      let response = await fetchSearchResults({ offset: queryOffset });

      const results = response.results;
      const flexResults = response.flexResults;

      setTotalResults(results.length); // Update context data with total results from backend for pagination purposes
      setSearchResults(searchResults.concat(results)); // Update context data with search results array of houses
      setFlexSearchResults(flexResults.slice(0, 15));
      if (results.length > 0) {
        const prices = searchResults
          .concat(results)
          .map((listing) => listing.price);
        setMaxSearchPriceValue(Math.round(Math.max(...prices)));
        setMinSearchPriceValue(Math.round(Math.min(...prices)));
      }
    } catch (error) {
      console.error("Error fetchAdditionalData results:", error);
    } finally {
      setBufferLoading(false);
    }
  };


  React.useEffect(() => {
    if (queryOffset > 0) fetchAdditionalData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryOffset]);

  // Fetch Global Calendar Availability to Restrict Date Options in Search Bar Date Picker
  const fetchGlobalCalendarAvailability = async () => {
    setGlobalCalendarLoading(true);

    try {
      let response = await fetchGlobalCalendar();
      const globalCheckInsStrings = await response.json();

      //console.log("globalCheckInsStrings", globalCheckInsStrings)

      const today = format(new Date(), "yyyy-MM-dd");

      // Filter out dates that are today or earlier
      const filteredDateStrings = globalCheckInsStrings.filter(
        (dateString) => dateString > today
      );

      // Sort the filtered date strings directly
      const sortedDateStrings = filteredDateStrings.sort((a, b) =>
        a.localeCompare(b)
      );

      // Update the state with sorted date strings
      setGlobalCheckIns(sortedDateStrings);

      //console.log('sortedDateStrings', sortedDateStrings)

      // Use Global Check Ins in Datepickers if it exists
      const useGlobalCheckIns = sortedDateStrings !== null;

      // Parse the first and last dates
      const firstAvailableDate = new Date();
      const lastAvailableDate = new Date(sortedDateStrings[sortedDateStrings.length - 1]);

      // Generate a complete array of dates between firstAvailableDate and lastAvailableDate
      const allDates = [];
      let currentDate = new Date(firstAvailableDate);
      while (currentDate <= lastAvailableDate) {
        allDates.push(currentDate.toISOString().split('T')[0]); // Format as YYYY-MM-DD
        currentDate.setDate(currentDate.getDate() + 1); // Move to the next day
      }

      // Find the missing dates by filtering out those that are in sortedDateStrings
      const missingDates = allDates.filter(date => !sortedDateStrings.includes(date));

      // Convert missingDates to Date objects using local time zone
      const missingDateObjects = missingDates.map(dateString => {
        const [year, month, day] = dateString.split('-').map(Number);
        return new Date(year, month - 1, day); // Use year, month (0-based), day
      });

      setDatesToGrayOut(missingDateObjects);

      //console.log('missingDates', missingDates)
      //console.log('useGlobalCheckIns', useGlobalCheckIns)
      //console.log('parseISO(sortedDateStrings[0])', parseISO(sortedDateStrings[0]))

      // Conditionally prepare props based on globalCheckIns availability
      const conditionalProps = useGlobalCheckIns
        ? {
          filterDate: (d) =>
            sortedDateStrings.includes(format(d, "yyyy-MM-dd")),
          openToDate: parseISO(sortedDateStrings[0]),
          includeDateIntervals: [
            { start: addDays(new Date(), 1), end: addDays(new Date(lastAvailableDate), 1) },
          ],
          maxDate: addDays(new Date(lastAvailableDate), 1),
        }
        : {
          includeDateIntervals: [
            { start: addDays(new Date(), 1), end: addDays(new Date(MAX_END_DATE), 1) },
          ],
        };

      setConditionalCalendarProps(conditionalProps);
      //console.log("conditionalProps", conditionalProps)
    } catch (error) {
      console.error("Error fetching global calendar availability:", error);
    } finally {
      setGlobalCalendarLoading(false);
    }
  };

  React.useEffect(() => {
    fetchGlobalCalendarAvailability();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Favorites Handling //

  React.useEffect(() => {
    setEmailLoading(true);
    //("emailLoading", emailLoading)
    const emailFromStorage = localStorage.getItem("userEmail");
    //console.log("emailFromStorage", emailFromStorage)

    const fetchUserData = async () => {
      try {
        if (user && user.primaryEmailAddress.emailAddress) {
          const email = user.primaryEmailAddress.emailAddress;
          //console.log("email", email)
          setUserEmail(email);
          await fetchUserFavorites(email);
        } else if (emailFromStorage) {
          //console.log("using emailFromStorage", emailFromStorage)
          setUserEmail(emailFromStorage);
          await fetchUserFavorites(emailFromStorage);
        } else {
          setEmailLoading(false);
          setFavoritesLoading(false);
        }
      } catch (error) {
        console.error("Error in fetchUserFavorites:", error);
      }
    };

    fetchUserData();
    setEmailLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  React.useEffect(() => {
    setNumFavorites(userFavorites.length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userFavorites]);

  React.useEffect(() => {
    if (mapAreaSearch) {
      setTown(null);
    }
  }, [mapAreaSearch]);

  const fetchUserFavorites = async (email) => {
    try {
      setFavoritesLoading(true);
      const response = await getFavoritesWithHouseData(email);
      const data = await response.json();
      //console.log("Favorites:", data);

      setUserFavorites([...data]);
    } catch (error) {
      console.error("Error fetching favorites:", error);
    } finally {
      setFavoritesLoading(false); // End loading
    }
  };

  // END Favorites Handling

  if (isMobile) {
    return (
      <MobileSearch
        datesToGrayOut={datesToGrayOut}
        setTown={setTown}
        town={town}
        startDate={startDate}
        endDate={endDate}
        conditionalCalendarProps={conditionalCalendarProps}
        decrementGuestInput={decrementGuestInput}
        incrementGuestInput={incrementGuestInput}
        isModalOpen={isModalOpen}
        openModal={openModal}
        closeModal={closeModal}
        closeModalAndSearch={closeModalAndSearch}
      />
    );
  }
  // NOT mobile
  return (
    <Flex
      justifyContent={"center"}
      alignItems={"center"}
      maxWidth={"650px"}
      h={"40px"}
      ml={4}
    >
      {/* Town Dropdown */}
      <FormControl w={"30%"}>
        <Select
          value={mapAreaSearch ? "Map Area" : town || ""}
          onChange={(e) => {
            setMapAreaSearch(false);
            setTown(e.target.value || null);
          }}
          bgColor={"white"}
          color={"black"}
          fontSize={13.5}
          borderRightRadius={0}
          border="solid 1px #00000038"
        >
          <option placeholder="All Towns" value="All Towns">
            All Towns
          </option>
          {TOWNS.map((town) => (
            <option key={town} value={town}>
              {town}
            </option>
          ))}
          {mapAreaSearch && <option value="Map Area">Map Area</option>}
        </Select>
      </FormControl>
      {/* Date Picker */}
      {!globalCalendarLoading && (
        <FormControl
          w={"25%"}
          h={"100%"}
          minW={"190px"}
          fontSize={13.5}
          border="solid 1px #00000038"
          borderLeft="0"
        >
          <Flex
            color={"black"}
            height={"40px"}
            w={"100%"}
            h={"100%"}
            textAlign={"center"}
          >
            <DatePicker
              selectsRange={true}
              startDate={startDate}
              endDate={endDate}
              onChange={(update) => {
                setContextDateRange(update);
              }}
              isClearable={true}
              placeholderText="Select Dates"
              h={"100%"}
              dateFormat={"M/d/yyyy"}
              textAlign="center"
              {...conditionalCalendarProps}
              excludeDates={datesToGrayOut}
              filterDate={(date) => {
                // If there is a start date, ensure that the end date is at least 2 days after
                if (startDate) {
                  return date >= addDays(startDate, 2);
                }
                return true; // Allow all dates if no start date is selected
              }}
            />
          </Flex>
        </FormControl>
      )}
      {/* Guests Number Input */}
      <Popover>
        <PopoverTrigger>
          <FormControl w={"35%"}>
            <Input
              readOnly={true}
              bgColor={"white"}
              color={"black"}
              border="solid 1px #00000038"
              borderLeft="0"
              borderRight="0"
              borderRadius={0}
              fontSize={13.5}
              placeholder={"Guests"}
              value={renderInputValue(guests, dogs)}
            />
          </FormControl>
        </PopoverTrigger>
        <PopoverContent maxW="min-content">
          <PopoverBody>
            <Grid m="10px" gap={4} templateColumns="repeat(2, 1fr)">
              <GridItem p="8px" h="10">
                <Text color="black">Guests</Text>
              </GridItem>
              <GridItem h="10">
                <HStack>
                  <Button onClick={decrementGuestInput} variant="ghost">
                    -
                  </Button>
                  <Input
                    textAlign={"center"}
                    p="10px"
                    minW="50px"
                    color="black"
                    type="number"
                    onChange={(e) => {
                      const newValue = parseInt(e.target.value, 10);
                      if (
                        !isNaN(newValue) &&
                        newValue >= MIN_GUESTS &&
                        newValue <= MAX_GUESTS
                      ) {
                        setGuests(newValue);
                      }
                    }}
                    value={guests || ""}
                  />
                  <Button onClick={incrementGuestInput} variant="ghost">
                    +
                  </Button>
                </HStack>
              </GridItem>

              <GridItem p="8px" h="10">
                <Text color="black">Dogs</Text>
              </GridItem>
              <GridItem h="10">
                <Flex
                  flexDirection="column"
                  alignItems="center"
                  alignContent="center"
                  justifyContent="center"
                  w={"100%"}
                  h={"100%"}
                  margin={"auto 0"}
                >
                  <Switch
                    id="dogs-switch"
                    colorScheme="dmNavy"
                    isChecked={dogs}
                    onChange={(e) => setDogs(e.target.checked)}
                    size="lg"
                  />
                  <Text
                    fontSize="xs"
                    mt={2}
                    lineHeight={0}
                    color={"dmNavy.500"}
                  >
                    {dogs ? "Yes" : "No"}
                  </Text>
                </Flex>
              </GridItem>
            </Grid>
          </PopoverBody>
        </PopoverContent>
      </Popover>
      <Button
        onClick={handleSearchClick}
        w={"10%"}
        borderLeftRadius={0}
        bgColor={"dmOrange.500"}
        color={"white"}
        _hover={{ bg: "dmOrange.600" }}
      >
        <FiSearch />
      </Button>
    </Flex>
  );
}

export default SearchWidget;
